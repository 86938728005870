import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import {type AuthSession, AuthSessionRecord} from '~/records/AuthSessionRecord';

type FetchStatus = 'idle' | 'pending' | 'success' | 'error';

type State = Readonly<{
	authSessionIdHash: string | null;
	authSessions: ReadonlyArray<AuthSessionRecord>;
	fetchStatus: FetchStatus;
	isDeleteError: boolean;
}>;

const initialState: State = {
	authSessionIdHash: null,
	authSessions: [],
	fetchStatus: 'idle',
	isDeleteError: false,
};

class AuthSessionStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen(action);
			case 'AUTH_SESSION_CHANGE':
				return this.handleAuthSessionChange(action);

			case 'AUTH_SESSIONS_FETCH_PENDING':
				return this.handleAuthSessionsFetchPending();
			case 'AUTH_SESSIONS_FETCH_SUCCESS':
				return this.handleAuthSessionsFetchSuccess(action);
			case 'AUTH_SESSIONS_FETCH_ERROR':
				return this.handleAuthSessionsFetchError();

			case 'AUTH_SESSIONS_LOGOUT_PENDING':
				return this.handleAuthSessionsLogoutPending();
			case 'AUTH_SESSIONS_LOGOUT_SUCCESS':
				return this.handleAuthSessionsLogoutSuccess(action);
			case 'AUTH_SESSIONS_LOGOUT_ERROR':
				return this.handleAuthSessionsLogoutError();

			default:
				return false;
		}
	}

	getAuthSessionIdHash(): string | null {
		return this.state.authSessionIdHash;
	}

	getAuthSessions(): ReadonlyArray<AuthSessionRecord> {
		return this.state.authSessions;
	}

	getFetchStatus(): FetchStatus {
		return this.state.fetchStatus;
	}

	isError(): boolean {
		return this.state.isDeleteError;
	}

	useAuthSessions(): ReadonlyArray<AuthSessionRecord> {
		const {authSessions} = this.useStore();
		return authSessions;
	}

	useFetchStatus(): FetchStatus {
		const {fetchStatus} = this.useStore();
		return fetchStatus;
	}

	private handleConnectionOpen({authSessionIdHash}: {authSessionIdHash: string}): boolean {
		this.setState((prevState) => ({
			...prevState,
			authSessionIdHash,
		}));
		return true;
	}

	private handleAuthSessionChange({authSessionIdHash}: {authSessionIdHash: string}): boolean {
		this.setState((prevState) => ({
			...prevState,
			authSessionIdHash,
		}));
		return true;
	}

	private handleAuthSessionsFetchPending(): boolean {
		this.setState((prevState) => ({
			...prevState,
			fetchStatus: 'pending',
		}));
		return true;
	}

	private handleAuthSessionsFetchSuccess({authSessions}: {authSessions: ReadonlyArray<AuthSession>}): boolean {
		this.setState((prevState) => ({
			...prevState,
			authSessions: authSessions.map((session) => new AuthSessionRecord(session)),
			fetchStatus: 'success',
		}));
		return true;
	}

	private handleAuthSessionsFetchError(): boolean {
		this.setState((prevState) => ({
			...prevState,
			fetchStatus: 'error',
		}));
		return true;
	}

	private handleAuthSessionsLogoutPending(): boolean {
		this.setState((prevState) => ({
			...prevState,
			isDeleteError: false,
		}));
		return true;
	}

	private handleAuthSessionsLogoutSuccess({sessionIdHashes}: {sessionIdHashes: ReadonlyArray<string>}): boolean {
		this.setState((prevState) => ({
			...prevState,
			authSessions: prevState.authSessions.filter((session) => !sessionIdHashes.includes(session.id)),
		}));
		return true;
	}

	private handleAuthSessionsLogoutError(): boolean {
		this.setState((prevState) => ({
			...prevState,
			isDeleteError: true,
		}));
		return true;
	}
}

export default new AuthSessionStore();
