export class LruCache<K, V> implements Iterable<[K, V]> {
	private maxSize: number;
	private cache: Map<K, V>;

	constructor(maxSize: number) {
		this.maxSize = maxSize;
		this.cache = new Map();
	}

	get(key: K): V | undefined {
		if (!this.cache.has(key)) {
			return undefined;
		}
		const value = this.cache.get(key) as V;
		this.cache.delete(key);
		this.cache.set(key, value);
		return value;
	}

	set(key: K, value: V): this {
		if (this.cache.has(key)) {
			this.cache.delete(key);
		} else if (this.cache.size === this.maxSize) {
			const firstKey = this.cache.keys().next().value as K;
			this.cache.delete(firstKey);
		}
		this.cache.set(key, value);
		return this;
	}

	has(key: K): boolean {
		return this.cache.has(key);
	}

	delete(key: K): boolean {
		return this.cache.delete(key);
	}

	clear(): void {
		this.cache.clear();
	}

	get size(): number {
		return this.cache.size;
	}

	get capacity(): number {
		return this.maxSize;
	}

	keys(): IterableIterator<K> {
		return this.cache.keys();
	}

	values(): IterableIterator<V> {
		return this.cache.values();
	}

	entries(): IterableIterator<[K, V]> {
		return this.cache.entries();
	}

	[Symbol.iterator](): IterableIterator<[K, V]> {
		return this.entries();
	}

	toJSON(): Record<string, unknown> {
		return {
			maxSize: this.maxSize,
			size: this.size,
			entries: Array.from(this.entries()),
		};
	}

	forEach(callback: (value: V, key: K, cache: this) => void): void {
		for (const [key, value] of this) {
			callback(value, key, this);
		}
	}
}
