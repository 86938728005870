import {useForm} from 'react-hook-form';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import * as ToastActionCreators from '~/actions/ToastActionCreators';
import * as UserActionCreators from '~/actions/UserActionCreators';
import {Form} from '~/components/form/Form';
import {Input} from '~/components/form/Input';
import styles from '~/components/modals/ConfirmModal.module.css';
import * as Modal from '~/components/modals/Modal';
import {Button} from '~/components/uikit/Button/Button';
import {i18n} from '~/i18n';
import type {HttpResponse} from '~/lib/HttpClient';
import UserStore from '~/stores/UserStore';
import * as FormUtils from '~/utils/FormUtils';

type FormInputs = {
	email: string;
	password: string;
};

export const EmailChangeModal = () => {
	const user = UserStore.getCurrentUser()!;
	const form = useForm<FormInputs>({defaultValues: {email: user.email, password: ''}});

	const onSubmit = async (data: FormInputs) => {
		try {
			await UserActionCreators.update({
				email: data.email,
				password: data.password,
			});
			ModalActionCreators.pop();
			ToastActionCreators.createToast({type: 'success', children: i18n.Messages.EMAIL_CHANGED});
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'email');
		}
	};

	return (
		<Form form={form} onSubmit={onSubmit}>
			<Modal.Root label={i18n.Messages.CHANGE_EMAIL_MODAL_TITLE} size="small">
				<Modal.Header title={i18n.Messages.CHANGE_EMAIL_MODAL_TITLE} />
				<Modal.Content className={styles.content}>
					{i18n.Messages.CHANGE_EMAIL_MODAL_DESCRIPTION}
					<div className="flex flex-col">
						<Input
							{...form.register('email')}
							autoComplete="email"
							autoFocus={true}
							error={form.formState.errors.email?.message}
							label={i18n.Messages.EMAIL}
							maxLength={256}
							minLength={1}
							placeholder={i18n.Messages.EMAIL_PLACEHOLDER}
							required={true}
							type="email"
						/>
					</div>
					<Input
						{...form.register('password')}
						autoComplete="current-password"
						error={form.formState.errors.password?.message}
						label={i18n.Messages.PASSWORD}
						maxLength={128}
						minLength={8}
						placeholder={'•'.repeat(32)}
						required={true}
						type="password"
					/>
				</Modal.Content>
				<Modal.Footer>
					<div className={styles.footer}>
						<Button onClick={ModalActionCreators.pop} variant="ghost">
							{i18n.Messages.CANCEL}
						</Button>
						<Button type="submit" submitting={form.formState.isSubmitting} variant="brand">
							{i18n.Messages.DONE}
						</Button>
					</div>
				</Modal.Footer>
			</Modal.Root>
		</Form>
	);
};
