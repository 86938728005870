import type {TooltipItemProps} from '~/components/uikit/Tooltip';
import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';

type State = Readonly<{
	tooltips: Readonly<Record<string, TooltipItemProps>>;
}>;

const initialState: State = {
	tooltips: {},
};

class TooltipStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'TOOLTIP_SHOW':
				return this.handleShowTooltip(action);
			case 'TOOLTIP_HIDE':
				return this.handleHideTooltip(action);
			default:
				return false;
		}
	}

	getTooltips(): Readonly<Record<string, TooltipItemProps>> {
		return this.state.tooltips;
	}

	isOpen(id: string): boolean {
		return id in this.state.tooltips;
	}

	private handleShowTooltip({id, tooltip}: {id: string; tooltip: TooltipItemProps}): boolean {
		this.setState((prevState) => ({
			tooltips: {
				...prevState.tooltips,
				[id]: tooltip,
			},
		}));
		return true;
	}

	private handleHideTooltip({id}: {id: string}): boolean {
		this.setState((prevState) => {
			if (!(id in prevState.tooltips)) {
				return prevState;
			}

			const {[id]: _, ...remainingTooltips} = prevState.tooltips;
			return {tooltips: remainingTooltips};
		});
		return true;
	}

	useTooltip(id: string): TooltipItemProps | undefined {
		const {tooltips} = this.useStore();
		return tooltips[id];
	}

	useTooltips(): Readonly<Record<string, TooltipItemProps>> {
		return this.useStore().tooltips;
	}
}

export default new TooltipStore();
