import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import {type Guild, type GuildReadyData, GuildRecord} from '~/records/GuildRecord';
import GuildStore from '~/stores/GuildStore';
import UserSettingsStore from '~/stores/UserSettingsStore';

type State = Readonly<{
	guilds: ReadonlyArray<GuildRecord>;
}>;

const initialState: State = {
	guilds: [],
};

class GuildListStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen(action);
			case 'GUILD_CREATE':
			case 'GUILD_UPDATE':
				return this.handleGuild(action.guild);
			case 'GUILD_DELETE':
				return this.handleGuildDelete(action);
			case 'USER_SETTINGS_UPDATE':
				return this.sortGuilds();
			default:
				return false;
		}
	}

	getGuilds(): ReadonlyArray<GuildRecord> {
		return this.state.guilds;
	}

	private handleConnectionOpen({guilds}: {guilds: ReadonlyArray<GuildReadyData>}): boolean {
		this.setState(initialState);

		const availableGuilds = guilds
			.filter((guild) => !guild.unavailable)
			.map((guild) => GuildStore.getGuild(guild.id))
			.filter((guild): guild is GuildRecord => guild !== undefined);

		if (availableGuilds.length > 0) {
			this.setState(() => ({
				guilds: this.sortGuildArray(availableGuilds),
			}));
		}

		return true;
	}

	private handleGuild(guild: Guild | GuildReadyData): boolean {
		if (guild.unavailable) {
			return false;
		}

		const guildRecord = GuildStore.getGuild(guild.id);
		if (!guildRecord) {
			return false;
		}

		this.setState((prevState) => {
			const index = prevState.guilds.findIndex((s) => s.id === guild.id);

			if (index === -1) {
				return {
					guilds: this.sortGuildArray([...prevState.guilds, guildRecord]),
				};
			}

			return {
				guilds: this.sortGuildArray([
					...prevState.guilds.slice(0, index),
					guildRecord,
					...prevState.guilds.slice(index + 1),
				]),
			};
		});

		return true;
	}

	private handleGuildDelete({guildId, unavailable}: {guildId: string; unavailable?: boolean}): boolean {
		this.setState((prevState) => {
			const index = prevState.guilds.findIndex((s) => s.id === guildId);
			if (index === -1) {
				return prevState;
			}

			if (unavailable) {
				const existingGuild = prevState.guilds[index];
				const updatedGuild = new GuildRecord({
					...existingGuild.toJSON(),
					unavailable: true,
				});

				return {
					guilds: [...prevState.guilds.slice(0, index), updatedGuild, ...prevState.guilds.slice(index + 1)],
				};
			}

			return {
				guilds: [...prevState.guilds.slice(0, index), ...prevState.guilds.slice(index + 1)],
			};
		});

		return true;
	}

	private sortGuilds(): boolean {
		this.setState((prevState) => ({
			guilds: this.sortGuildArray([...prevState.guilds]),
		}));
		return true;
	}

	private sortGuildArray(guilds: ReadonlyArray<GuildRecord>): ReadonlyArray<GuildRecord> {
		const settings = UserSettingsStore.getState();

		return [...guilds].sort((a, b) => {
			const aIndex = settings.guild_positions.indexOf(a.id);
			const bIndex = settings.guild_positions.indexOf(b.id);

			if (aIndex === -1 && bIndex === -1) {
				return a.name.localeCompare(b.name);
			}

			if (aIndex === -1) return 1;
			if (bIndex === -1) return -1;

			return aIndex - bIndex;
		});
	}
}

export default new GuildListStore();
