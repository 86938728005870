import clsx from 'clsx';
import type {FC} from 'react';
import {MessageEmbedMediaFlags, MessageEmbedTypes} from '~/Constants';
import {
	EmbedAuthor,
	EmbedDescription,
	EmbedFields,
	EmbedFooter,
	EmbedProvider,
	EmbedTitle,
} from '~/components/channel/embeds/fields/EmbedFields';
import styles from '~/components/channel/embeds/fields/EmbedFields.module.css';
import EmbedAudio from '~/components/channel/embeds/media/EmbedAudio';
import {EmbedGif, EmbedGifv} from '~/components/channel/embeds/media/EmbedGifv';
import {EmbedImage} from '~/components/channel/embeds/media/EmbedImage';
import EmbedVideo from '~/components/channel/embeds/media/EmbedVideo';
import {EmbedYouTube} from '~/components/channel/embeds/media/EmbedYouTube';
import type {EmbedMedia, MessageEmbed, MessageRecord} from '~/records/MessageRecord';
import markupStyles from '~/styles/Markup.module.css';
import * as ColorUtils from '~/utils/ColorUtils';
import {createCalculator} from '~/utils/DimensionUtils';

const MEDIA_MAX_WIDTH = 400;
const MEDIA_MAX_HEIGHT = 300;
const THUMBNAIL_SIZE = 80;

type EmbedProps = {
	embed: MessageEmbed;
	message: MessageRecord;
};

type MediaDimensions = {
	width: number;
	height: number;
};

const mediaCalculator = createCalculator({
	maxWidth: MEDIA_MAX_WIDTH,
	maxHeight: MEDIA_MAX_HEIGHT,
});

const thumbnailCalculator = createCalculator({
	maxWidth: THUMBNAIL_SIZE,
	maxHeight: THUMBNAIL_SIZE,
	forceScale: true,
});

const isValidMedia = (media?: Partial<EmbedMedia>): media is Required<EmbedMedia> => {
	return !!(
		media &&
		typeof media.proxy_url === 'string' &&
		typeof media.url === 'string' &&
		typeof media.width === 'number' &&
		typeof media.height === 'number'
	);
};

const calculateMediaDimensions = (media: Required<EmbedMedia>, calculator = mediaCalculator): MediaDimensions => {
	const {dimensions} = calculator.calculate({
		width: media.width,
		height: media.height,
	});
	return dimensions;
};

const shouldRenderAsInlineThumbnail = (media?: EmbedMedia): boolean => {
	if (!isValidMedia(media)) return false;

	const {width: thumbnailWidth} = calculateMediaDimensions(media, thumbnailCalculator);
	const {width: normalWidth} = calculateMediaDimensions(media);

	return normalWidth < 300 && thumbnailWidth >= 40;
};

const hasRichContent = (embed: MessageEmbed): boolean => {
	// Only count provider if it's not a GIFV from a known provider
	const hasSignificantProvider =
		embed.provider &&
		!(
			embed.type === MessageEmbedTypes.GIFV &&
			embed.provider.url &&
			new URL(embed.provider.url).hostname === 'tenor.com'
		);

	return !!(
		embed.title ||
		embed.description ||
		embed.author ||
		embed.footer ||
		embed.fields?.length ||
		hasSignificantProvider
	);
};

const EmbedMediaRenderer: FC<{embed: MessageEmbed}> = ({embed}) => {
	const {video, image, thumbnail} = embed;

	if (!isValidMedia(video) && !isValidMedia(image) && !isValidMedia(thumbnail)) {
		return null;
	}

	if (isValidMedia(video) && embed.provider?.url && new URL(embed.provider.url).hostname === 'www.youtube.com') {
		return <EmbedYouTube embed={embed} />;
	}

	if (isValidMedia(video)) {
		const {width, height} = calculateMediaDimensions(video);
		return <EmbedVideo src={video.proxy_url} width={width} height={height} placeholder={video.placeholder} />;
	}

	if (isValidMedia(image)) {
		const {width, height} = calculateMediaDimensions(image);
		return (
			<EmbedImage
				src={image.proxy_url}
				originalSrc={image.url}
				naturalWidth={image.width}
				naturalHeight={image.height}
				width={width}
				height={height}
				placeholder={image.placeholder}
				constrain={true}
			/>
		);
	}

	if (isValidMedia(thumbnail)) {
		const {width, height} = calculateMediaDimensions(thumbnail);
		return (
			<EmbedImage
				src={thumbnail.proxy_url}
				originalSrc={thumbnail.url}
				naturalWidth={thumbnail.width}
				naturalHeight={thumbnail.height}
				width={width}
				height={height}
				placeholder={thumbnail.placeholder}
				constrain={true}
			/>
		);
	}

	return null;
};

const InlineThumbnail: FC<{thumbnail: Required<EmbedMedia>}> = ({thumbnail}) => {
	const {width} = calculateMediaDimensions(thumbnail, thumbnailCalculator);

	return (
		<div className="overflow-hidden rounded" style={{height: THUMBNAIL_SIZE}}>
			<EmbedImage
				src={thumbnail.proxy_url}
				originalSrc={thumbnail.url}
				naturalWidth={thumbnail.width}
				naturalHeight={thumbnail.height}
				width={width}
				height={THUMBNAIL_SIZE}
				placeholder={thumbnail.placeholder}
				constrain={true}
				isInline={true}
			/>
		</div>
	);
};

const RichEmbed: FC<EmbedProps> = ({embed, message}) => {
	const hasVideo = isValidMedia(embed.video);
	const hasImage = isValidMedia(embed.image);
	const hasThumbnail = isValidMedia(embed.thumbnail);
	const hasAnyMedia = hasVideo || hasImage || hasThumbnail;
	const isInlineThumbnail = !hasVideo && hasThumbnail && shouldRenderAsInlineThumbnail(embed.thumbnail);
	const isYouTubeEmbed = hasVideo && embed.provider?.url && new URL(embed.provider.url).hostname === 'www.youtube.com';

	if (isYouTubeEmbed) {
		return (
			<article
				className={clsx(
					'relative box-border max-w-[432px] rounded',
					'bg-background-primary transition-colors duration-200',
					markupStyles.markup,
				)}
				style={{
					borderLeft: embed.color ? `4px solid ${ColorUtils.int2rgb(embed.color)}` : undefined,
				}}
			>
				<div className="max-w-[432px]">
					<div className="grid overflow-hidden p-2 px-4 pb-4 pl-3">
						<EmbedProvider provider={embed.provider} />
						<EmbedAuthor author={embed.author} />
						<EmbedTitle title={embed.title} url={embed.url} />
						<EmbedYouTube embed={embed} />
					</div>
				</div>
			</article>
		);
	}

	return (
		<article
			className={clsx(
				'relative box-border max-w-[432px] rounded',
				'bg-background-primary transition-colors duration-200',
				hasAnyMedia && 'justify-self-auto',
				markupStyles.markup,
			)}
			style={{
				borderLeft: embed.color ? `4px solid ${ColorUtils.int2rgb(embed.color)}` : undefined,
			}}
		>
			<div className={clsx('p-3', isInlineThumbnail && 'grid grid-cols-[1fr_80px]')}>
				<div className={styles.wrapper}>
					<EmbedProvider provider={embed.provider} />
					<EmbedAuthor author={embed.author} />
					<EmbedTitle title={embed.title} url={embed.url} />
					<EmbedDescription description={embed.description} messageId={message.id} channelId={message.channelId} />
					<EmbedFields fields={embed.fields?.slice() ?? []} />
				</div>

				{isInlineThumbnail && embed.thumbnail && isValidMedia(embed.thumbnail) ? (
					<InlineThumbnail thumbnail={embed.thumbnail} />
				) : (
					hasAnyMedia && (
						<div className="mt-4 w-full overflow-hidden rounded">
							<EmbedMediaRenderer embed={embed} />
						</div>
					)
				)}

				{embed.footer && <EmbedFooter footer={embed.footer} timestamp={embed.timestamp} />}
			</div>
		</article>
	);
};

export const Embed: FC<EmbedProps> = ({embed, message}) => {
	if (!hasRichContent(embed)) {
		if (embed.type === MessageEmbedTypes.AUDIO && embed.audio?.proxy_url) {
			return <EmbedAudio src={embed.audio.proxy_url} title={embed.title} />;
		}

		if (embed.type === MessageEmbedTypes.VIDEO && isValidMedia(embed.video)) {
			if (embed.provider?.url && new URL(embed.provider.url).hostname === 'www.youtube.com') {
				return <EmbedYouTube embed={embed} />;
			}

			const {width, height} = calculateMediaDimensions(embed.video);
			return (
				<EmbedVideo src={embed.video.proxy_url} width={width} height={height} placeholder={embed.video.placeholder} />
			);
		}

		const {thumbnail} = embed;
		if (
			embed.type === MessageEmbedTypes.IMAGE &&
			isValidMedia(thumbnail) &&
			(thumbnail.flags & MessageEmbedMediaFlags.ANIMATED) === MessageEmbedMediaFlags.ANIMATED
		) {
			return (
				<EmbedGif
					embedURL={thumbnail.url}
					proxyURL={thumbnail.proxy_url}
					naturalWidth={thumbnail.width}
					naturalHeight={thumbnail.height}
					placeholder={thumbnail.placeholder}
				/>
			);
		}

		if (embed.type === MessageEmbedTypes.GIFV && isValidMedia(embed.video) && isValidMedia(thumbnail) && embed.url) {
			return (
				<EmbedGifv
					embedURL={embed.url}
					videoProxyURL={embed.video.proxy_url}
					videoURL={embed.video.url}
					naturalWidth={thumbnail.width}
					naturalHeight={thumbnail.height}
					placeholder={thumbnail.placeholder}
				/>
			);
		}

		if (isValidMedia(thumbnail)) {
			const {width, height} = calculateMediaDimensions(thumbnail);
			return (
				<EmbedImage
					src={thumbnail.proxy_url}
					originalSrc={thumbnail.url}
					naturalWidth={thumbnail.width}
					naturalHeight={thumbnail.height}
					width={width}
					height={height}
					placeholder={thumbnail.placeholder}
					constrain={true}
				/>
			);
		}

		return null;
	}

	return <RichEmbed embed={embed} message={message} />;
};
