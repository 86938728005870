import clsx from 'clsx';
import React from 'react';
import {i18n} from '~/i18n';

type UserTagProps = {
	className?: string;
};

export const UserTag = React.forwardRef<HTMLSpanElement, UserTagProps>(({className, ...props}, ref) => (
	<span
		className={clsx(
			'relative top-[.1rem] ml-[.25rem] inline-flex h-[.9375rem] items-center rounded-[4px] bg-brand-primary px-[.275rem] indent-0 align-top text-[.625rem] text-brand-primary-fill uppercase',
			className,
		)}
		ref={ref}
		{...props}
	>
		<span className="relative align-top font-semibold text-[.75rem] leading-[.9375rem]">{i18n.Messages.APP}</span>
	</span>
));

UserTag.displayName = 'UserTag';
