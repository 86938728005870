import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import * as HttpClient from '~/lib/HttpClient';
import {type Profile, ProfileRecord} from '~/records/ProfileRecord';
import UserProfileStore from '~/stores/UserProfileStore';

export const fetch = async (userId: string, guildId?: string) => {
	const existingProfile = UserProfileStore.getProfile(userId, guildId);
	if (existingProfile) {
		return existingProfile;
	}
	const {body: profile} = await HttpClient.get<Profile>({
		url: Endpoints.USER_PROFILE(userId),
		query: guildId ? {guild_id: guildId} : undefined,
	});
	const profileRecord = new ProfileRecord(profile, guildId);
	Dispatcher.dispatch({
		type: 'USER_PROFILE_CREATE',
		profile: profileRecord,
	});
	return profileRecord;
};
