import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import {type Message, MessageRecord} from '~/records/MessageRecord';

type CachedMessageState = Readonly<
	| {
			status: 'loaded';
			message: MessageRecord;
	  }
	| {
			status: 'deleted' | 'not_loaded';
	  }
>;

type State = Readonly<{
	cachedMessages: ReadonlyMap<string, MessageRecord>;
	cachedMessageIds: ReadonlySet<string>;
}>;

const initialState: State = {
	cachedMessages: new Map(),
	cachedMessageIds: new Set(),
};

class ReferencedMessageStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen();
			case 'MESSAGE_CREATE':
				return this.handleMessageCreate(action);
			case 'MESSAGE_DELETE':
				return this.handleMessageDelete(action);
			case 'MESSAGE_DELETE_BULK':
				return this.handleMessageDeleteBulk(action);
			case 'REPLY_DELETE_MESSAGE_IDS':
				return this.handleReplyDeleteMessageIds(action);
			case 'MESSAGES_FETCH_SUCCESS':
			case 'CHANNEL_PINS_FETCH_SUCCESS':
			case 'SAVED_MESSAGES_FETCH_SUCCESS':
			case 'RECENT_MENTIONS_FETCH_SUCCESS':
				return this.handleMessagesFetchSuccess(action);
			default:
				return false;
		}
	}

	useCachedMessage(messageId: string): CachedMessageState {
		const {cachedMessages, cachedMessageIds} = this.useStore();

		const message = cachedMessages.get(messageId);
		if (message) {
			return {status: 'loaded', message};
		}

		return {
			status: cachedMessageIds.has(messageId) ? 'deleted' : 'not_loaded',
		};
	}

	private handleConnectionOpen(): boolean {
		this.setState(initialState);
		return true;
	}

	private handleMessageCreate({message}: {message: Message}): boolean {
		if (message.referenced_message === undefined) {
			return false;
		}

		if (!message.message_reference?.message_id) {
			console.warn('Message reference is missing or invalid');
			return false;
		}

		const messageReferenceId = message.message_reference.message_id;

		if (message.referenced_message === null) {
			this.setState((prevState) =>
				prevState.cachedMessageIds.has(messageReferenceId)
					? prevState
					: {
							...prevState,
							cachedMessageIds: new Set([...prevState.cachedMessageIds, messageReferenceId]),
						},
			);
			return true;
		}

		this.setState((prevState) => ({
			cachedMessages: new Map([
				...prevState.cachedMessages,
				[messageReferenceId, new MessageRecord(message.referenced_message!)],
			]),
			cachedMessageIds: new Set([...prevState.cachedMessageIds, messageReferenceId]),
		}));

		return true;
	}

	private handleMessageDelete({messageId}: {messageId: string}): boolean {
		this.setState((prevState) => {
			const newMessages = new Map(prevState.cachedMessages);
			newMessages.delete(messageId);

			return {
				cachedMessages: newMessages,
				cachedMessageIds: new Set([...prevState.cachedMessageIds, messageId]),
			};
		});
		return true;
	}

	private handleMessageDeleteBulk({messageIds}: {messageIds: ReadonlyArray<string>}): boolean {
		if (messageIds.length === 0) {
			return false;
		}

		this.setState((prevState) => {
			const newMessages = new Map(prevState.cachedMessages);
			const messagesToDelete = new Set(messageIds);

			for (const id of messagesToDelete) {
				newMessages.delete(id);
			}

			return {
				cachedMessages: newMessages,
				cachedMessageIds: new Set([...prevState.cachedMessageIds, ...messageIds]),
			};
		});

		return true;
	}

	private handleMessagesFetchSuccess({messages}: {messages: ReadonlyArray<Message>}): boolean {
		const referencedMessages = messages.filter(
			(message): message is Message & {referenced_message: NonNullable<Message['referenced_message']>} =>
				message.referenced_message !== undefined &&
				message.referenced_message !== null &&
				message.message_reference?.message_id !== undefined,
		);

		if (referencedMessages.length === 0) {
			return false;
		}

		this.setState((prevState) => {
			const newMessages = new Map(prevState.cachedMessages);
			const newMessageIds = new Set(prevState.cachedMessageIds);

			for (const message of referencedMessages) {
				const referenceId = message.message_reference!.message_id;
				newMessages.set(referenceId, new MessageRecord(message.referenced_message));
				newMessageIds.add(referenceId);
			}

			return {
				cachedMessages: newMessages,
				cachedMessageIds: newMessageIds,
			};
		});

		return true;
	}

	private handleReplyDeleteMessageIds({messageIds}: {messageIds: ReadonlyArray<string>}): boolean {
		return this.handleMessageDeleteBulk({messageIds});
	}
}

export default new ReferencedMessageStore();
