import type {Action} from '~/flux/ActionTypes';
import {PersistedStore} from '~/flux/PersistedStore';

export type DeveloperOptionsState = Readonly<{
	bypassSplashScreen: boolean;
	forceFailUploads: boolean;
	forceRenderPlaceholders: boolean;
	forceUpdateReady: boolean;
	showMyselfTyping: boolean;
	slowAttachmentUpload: boolean;
	slowMessageLoad: boolean;
	slowMessageSend: boolean;
	slowProfileLoad: boolean;
	jsonGatewayCodec: boolean;
}>;

const initialState: DeveloperOptionsState = Object.freeze({
	bypassSplashScreen: false,
	forceFailUploads: false,
	forceRenderPlaceholders: false,
	forceUpdateReady: false,
	showMyselfTyping: false,
	slowAttachmentUpload: false,
	slowMessageLoad: false,
	slowMessageSend: false,
	slowProfileLoad: false,
	jsonGatewayCodec: false,
});

const createGetter = <K extends keyof DeveloperOptionsState>(key: K) =>
	function (this: DeveloperOptionsStore): DeveloperOptionsState[K] {
		return this.state[key];
	};

class DeveloperOptionsStore extends PersistedStore<DeveloperOptionsState> {
	constructor() {
		super(initialState, 'DeveloperOptionsStore', 1);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'DEVELOPER_OPTIONS_UPDATE':
				return this.handleDeveloperOptionsUpdate(action);
			default:
				return false;
		}
	}

	getBypassSplashScreen = createGetter('bypassSplashScreen');
	getForceFailUploads = createGetter('forceFailUploads');
	getForceRenderPlaceholders = createGetter('forceRenderPlaceholders');
	getForceUpdateReady = createGetter('forceUpdateReady');
	getShowMyselfTyping = createGetter('showMyselfTyping');
	getSlowAttachmentUpload = createGetter('slowAttachmentUpload');
	getSlowMessageLoad = createGetter('slowMessageLoad');
	getSlowMessageSend = createGetter('slowMessageSend');
	getSlowProfileLoad = createGetter('slowProfileLoad');
	getJsonGatewayCodec = createGetter('jsonGatewayCodec');

	useOption<K extends keyof DeveloperOptionsState>(key: K): DeveloperOptionsState[K] {
		const state = this.useStore();
		return state[key];
	}

	private handleDeveloperOptionsUpdate({
		key,
		value,
	}: {
		key: keyof DeveloperOptionsState;
		value: boolean;
	}): boolean {
		this.setState((prevState) => ({
			...prevState,
			[key]: value,
		}));
		if (key === 'jsonGatewayCodec') {
			window.location.reload();
		}
		return true;
	}
}

export default new DeveloperOptionsStore();
