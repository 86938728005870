import type React from 'react';
import {
	ChannelTypes,
	type MessageEmbedType,
	MessageFlags,
	type MessageState,
	MessageStates,
	type MessageType,
	MessageTypes,
} from '~/Constants';
import type {UserPartial} from '~/records/UserRecord';
import {UserRecord} from '~/records/UserRecord';
import AuthenticationStore from '~/stores/AuthenticationStore';
import ChannelStore from '~/stores/ChannelStore';
import GuildMemberStore from '~/stores/GuildMemberStore';
import GuildStore from '~/stores/GuildStore';
import UserStore from '~/stores/UserStore';
import * as InviteUtils from '~/utils/InviteUtils';
import * as MarkupUtils from '~/utils/MarkupUtils';
import {type ReactionEmoji, emojiEquals} from '~/utils/ReactionUtils';

export const messageMentionsCurrentUser = (message: Message): boolean => {
	const channel = ChannelStore.getChannel(message.channel_id);
	if (!channel || channel.type === ChannelTypes.GUILD_DOCUMENT) {
		return false;
	}
	if (message.flags & MessageFlags.MENTION_EVERYONE) {
		return true;
	}
	if (message.mentions?.some((user) => user.id === AuthenticationStore.getId())) {
		return true;
	}
	if (!channel.guildId) {
		return false;
	}
	const guild = GuildStore.getGuild(channel.guildId);
	if (!guild) {
		return false;
	}
	const guildMember = GuildMemberStore.getMember(guild.id, AuthenticationStore.getId());
	if (!guildMember) {
		return false;
	}
	return message.mention_roles?.some((roleId) => guildMember.roles.has(roleId)) ?? false;
};

let embedIdCounter = 0;

const generateEmbedId = (): string => {
	return `embed_${embedIdCounter++}`;
};

const areEmbedsEqual = (embed1: MessageEmbed, embed2: MessageEmbed): boolean => {
	const {id: id1, ...embed1WithoutId} = embed1;
	const {id: id2, ...embed2WithoutId} = embed2;
	return JSON.stringify(embed1WithoutId) === JSON.stringify(embed2WithoutId);
};

const embedCache: Array<{embed: MessageEmbed; id: string}> = [];

const getOrCreateEmbedId = (embed: Omit<MessageEmbed, 'id'>): string => {
	const existingEmbed = embedCache.find((cached) => areEmbedsEqual(cached.embed, embed as MessageEmbed));

	if (existingEmbed) {
		return existingEmbed.id;
	}

	const newId = generateEmbedId();
	embedCache.push({
		embed: {...embed, id: newId} as MessageEmbed,
		id: newId,
	});

	return newId;
};

export type EmbedAuthor = Readonly<{
	name: string;
	url?: string;
	icon_url?: string;
	proxy_icon_url?: string;
}>;

export type EmbedFooter = Readonly<{
	text: string;
	icon_url?: string;
	proxy_icon_url?: string;
}>;

export type EmbedMedia = Readonly<{
	url: string;
	proxy_url?: string;
	width?: number;
	height?: number;
	placeholder?: string;
	flags: number;
}>;

export type EmbedField = Readonly<{
	name: string;
	value: string;
	inline: boolean;
}>;

export type MessageEmbed = Readonly<{
	id: string;
	type: MessageEmbedType;
	url?: string;
	title?: string;
	color?: number;
	timestamp?: number;
	description?: string;
	author?: EmbedAuthor;
	image?: EmbedMedia;
	thumbnail?: EmbedMedia;
	footer?: EmbedFooter;
	fields?: ReadonlyArray<EmbedField>;
	provider?: EmbedAuthor;
	video?: EmbedMedia;
	audio?: EmbedMedia;
	flags?: number;
}>;

export type MessageReference = Readonly<{
	message_id: string;
	channel_id: string;
	guild_id?: string;
}>;

export type MessageReaction = Readonly<{
	emoji: ReactionEmoji;
	count: number;
	me?: true;
}>;

export type MessageAttachment = Readonly<{
	id: string;
	filename: string;
	title?: string;
	description?: string;
	content_type?: string;
	size: number;
	url: string;
	proxy_url: string;
	width?: number;
	height?: number;
	duration?: number;
	placeholder?: string;
	flags: number;
}>;

export type Message = Readonly<{
	id: string;
	channel_id: string;
	author: UserPartial;
	webhook_id?: string;
	type: MessageType;
	flags: number;
	content: string;
	created_at: number;
	edited_at?: number;
	mentions?: ReadonlyArray<UserPartial>;
	mention_roles?: ReadonlyArray<string>;
	embeds?: ReadonlyArray<MessageEmbed>;
	attachments?: ReadonlyArray<MessageAttachment>;
	reactions?: ReadonlyArray<MessageReaction>;
	message_reference?: MessageReference;
	referenced_message?: Message | null;
	state?: MessageState;
	nonce?: string;
	_allowedMentions?: any;
}>;

export type MessagePartial = Pick<Message, 'id' | 'channel_id' | 'embeds' | 'attachments'>;

const MAX_EMOJI_TO_BE_JUMBO = 27;

export class MessageRecord {
	readonly id: string;
	readonly channelId: string;
	readonly author: UserRecord;
	readonly webhookId?: string;
	readonly type: MessageType;
	readonly flags: number;
	readonly content: string;
	readonly contentParsed: React.ReactNode;
	readonly invites: ReadonlyArray<string>;
	readonly createdAt: number;
	readonly editedAt: number | null;
	readonly mentions: ReadonlyArray<UserRecord>;
	readonly mentionRoles: ReadonlyArray<string>;
	readonly embeds: ReadonlyArray<MessageEmbed>;
	readonly attachments: ReadonlyArray<MessageAttachment>;
	readonly reactions: ReadonlyArray<MessageReaction>;
	readonly messageReference?: MessageReference;
	readonly state: MessageState;
	readonly nonce?: string;
	readonly _allowedMentions?: any;

	constructor(message: Message) {
		this.id = message.id;
		this.channelId = message.channel_id;
		this.author = new UserRecord(message.author);
		this.webhookId = message.webhook_id;
		this.type = message.type;
		this.flags = message.flags;
		this.content = message.content;
		this.contentParsed = MarkupUtils.safeParse(message.content, {
			messageId: message.id,
			channelId: message.channel_id,
			jumboable: true,
		});
		this.invites = Object.freeze(InviteUtils.findInvites(message.content));
		this.createdAt = message.created_at;
		this.editedAt = message.edited_at ?? null;
		this.mentions = Object.freeze((message.mentions ?? []).map((user) => new UserRecord(user)));
		this.mentionRoles = Object.freeze(message.mention_roles ?? []);
		this.embeds = Object.freeze(
			(message.embeds ?? []).map((embed) => ({
				...embed,
				id: getOrCreateEmbedId(embed),
			})),
		);
		this.attachments = Object.freeze(message.attachments ?? []);
		this.reactions = Object.freeze(message.reactions ?? []);
		this.messageReference = message.message_reference;
		this.state = message.state ?? MessageStates.SENT;
		this.nonce = message.nonce;
		this._allowedMentions = message._allowedMentions;

		UserStore.cacheUsers([this.author, ...this.mentions]);
	}

	private static checkForJumboEmojiWithInline(ast: any, inline: boolean): any {
		if (inline) {
			return MessageRecord.checkForJumboEmoji(ast);
		}
		if (ast[0]?.type !== 'paragraph') {
			return ast;
		}
		if (Array.isArray(ast[0].content)) {
			ast[0].content = MessageRecord.checkForJumboEmoji(ast[0].content);
		}
		return ast;
	}

	private static checkForJumboEmoji(ast: any): any {
		const hasAnyNonEmoji = ast.some((e: any) => {
			if (e.type === 'emoji' || e.type === 'customEmoji') {
				return false;
			}
			if (typeof e.content === 'string' && e.content.trim() === '') {
				return false;
			}
			return true;
		});

		if (hasAnyNonEmoji || ast.length > MAX_EMOJI_TO_BE_JUMBO) {
			return ast;
		}

		return ast.map((e: any) => ({
			...e,
			jumboable: true,
		}));
	}

	withUpdates(updates: Partial<Message>): MessageRecord {
		return new MessageRecord({
			id: this.id,
			channel_id: this.channelId,
			author: updates.author ?? this.author.toJSON(),
			webhook_id: updates.webhook_id ?? this.webhookId,
			type: updates.type ?? this.type,
			flags: updates.flags ?? this.flags,
			content: updates.content ?? this.content,
			created_at: this.createdAt,
			edited_at: updates.edited_at ?? this.editedAt ?? undefined,
			mentions: updates.mentions ?? this.mentions.map((m) => m.toJSON()),
			mention_roles: updates.mention_roles ?? this.mentionRoles,
			embeds: updates.embeds ?? this.embeds,
			attachments: updates.attachments ?? this.attachments,
			reactions: updates.reactions ?? this.reactions,
			message_reference: updates.message_reference ?? this.messageReference,
			state: updates.state ?? this.state,
			nonce: updates.nonce ?? this.nonce,
		});
	}

	withReaction(emoji: ReactionEmoji, add = true, me = false): MessageRecord {
		const existingReaction = this.getReaction(emoji);

		if (!existingReaction && !add) {
			return this;
		}

		let newReactions: Array<MessageReaction>;
		if (existingReaction) {
			if (add) {
				newReactions = this.reactions.map((reaction) =>
					emojiEquals(reaction.emoji, emoji)
						? {
								...reaction,
								count: me && reaction.me ? reaction.count : reaction.count + 1,
								me: me || reaction.me ? true : undefined,
							}
						: reaction,
				);
			} else {
				const updatedCount = existingReaction.count - (me && !existingReaction.me ? 0 : 1);
				if (updatedCount <= 0) {
					newReactions = this.reactions.filter((reaction) => !emojiEquals(reaction.emoji, emoji));
				} else {
					newReactions = this.reactions.map((reaction) =>
						emojiEquals(reaction.emoji, emoji)
							? {
									...reaction,
									count: updatedCount,
									me: me ? undefined : reaction.me,
								}
							: reaction,
					);
				}
			}
		} else {
			newReactions = [
				...this.reactions,
				{
					emoji,
					count: 1,
					me: me ? true : undefined,
				},
			];
		}

		return this.withUpdates({reactions: newReactions});
	}

	withoutReactionEmoji(emoji: ReactionEmoji): MessageRecord {
		return this.withUpdates({
			reactions: this.reactions.filter((reaction) => !emojiEquals(reaction.emoji, emoji)),
		});
	}

	getReaction(emoji: ReactionEmoji): MessageReaction | undefined {
		return this.reactions.find((r) => emojiEquals(r.emoji, emoji));
	}

	equals(other: MessageRecord): boolean {
		return JSON.stringify(this) === JSON.stringify(other);
	}

	toJSON(): Message {
		return {
			id: this.id,
			channel_id: this.channelId,
			author: this.author.toJSON(),
			webhook_id: this.webhookId,
			type: this.type,
			flags: this.flags,
			content: this.content,
			created_at: this.createdAt,
			edited_at: this.editedAt ?? undefined,
			mentions: this.mentions.map((user) => user.toJSON()),
			mention_roles: this.mentionRoles,
			embeds: this.embeds,
			attachments: this.attachments,
			reactions: this.reactions,
			message_reference: this.messageReference,
			state: this.state,
			nonce: this.nonce,
		};
	}

	isUserMessage(): boolean {
		return this.type === MessageTypes.DEFAULT || this.type === MessageTypes.REPLY;
	}

	isAuthor(userId: string): boolean {
		return this.author.id === userId;
	}

	isCurrentUserAuthor(): boolean {
		return this.isAuthor(AuthenticationStore.getId());
	}

	isMentioned(): boolean {
		return messageMentionsCurrentUser(this.toJSON());
	}

	get shouldSuppressEmbeds(): boolean {
		return (this.flags & MessageFlags.SUPPRESS_EMBEDS) !== 0;
	}
}
