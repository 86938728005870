import type {Action} from '~/flux/ActionTypes';
import {PersistedStore} from '~/flux/PersistedStore';

export type UserSettingsTabType =
	| 'my_account'
	| 'edit_profile'
	| 'beta_codes'
	| 'privacy_safety'
	| 'devices'
	| 'connections'
	| 'friend_requests'
	| 'appearance'
	| 'accessibility'
	| 'chat_settings'
	| 'notifications'
	| 'developer_options'
	| 'component_gallery';

type State = Readonly<{
	selectedTab: UserSettingsTabType;
}>;

const STORE_NAME = 'UserSettingsModalStore' as const;
const STORE_VERSION = 1 as const;

const initialState: State = {
	selectedTab: 'my_account',
};

class UserSettingsModalStore extends PersistedStore<State> {
	constructor() {
		super(initialState, STORE_NAME, STORE_VERSION);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'USER_SETTINGS_MODAL_TAB_SELECT':
				return this.handleTabSelect(action);
			default:
				return false;
		}
	}

	getSelectedTab(): UserSettingsTabType {
		return this.state.selectedTab;
	}

	private handleTabSelect({tab}: {tab: UserSettingsTabType}): boolean {
		this.setState((prevState) => ({
			...prevState,
			selectedTab: tab,
		}));
		return true;
	}
}

export default new UserSettingsModalStore();
