import clsx from 'clsx';
import highlight from 'highlight.js';
import React from 'react';
import {type ChannelType, GUILD_TEXT_CHANNEL_TYPES} from '~/Constants';
import * as InviteActionCreators from '~/actions/InviteActionCreators';
import * as TextCopyActionCreators from '~/actions/TextCopyActionCreators';
import {PreloadableUserPopout} from '~/components/channel/PreloadableUserPopout';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import {i18n} from '~/i18n';
import {CopyIcon} from '~/icons/CopyIcon';
import {
	type EmojiType,
	type ListItem,
	type MentionType,
	type Node,
	ParserFlags,
	TimestampStyle,
	parseFluxerMarkdown,
} from '~/lib/FluxerMarkdown';
import * as UnicodeEmojis from '~/lib/UnicodeEmojis';
import ChannelStore from '~/stores/ChannelStore';
import EmojiStore from '~/stores/EmojiStore';
import GuildStore from '~/stores/GuildStore';
import UserStore from '~/stores/UserStore';
import markupStyles from '~/styles/Markup.module.css';
import * as AvatarUtils from '~/utils/AvatarUtils';
import * as ChannelUtils from '~/utils/ChannelUtils';
import * as ColorUtils from '~/utils/ColorUtils';
import * as EmojiUtils from '~/utils/EmojiUtils';
import * as InviteUtils from '~/utils/InviteUtils';
import * as NicknameUtils from '~/utils/NicknameUtils';
import * as RouterUtils from '~/utils/RouterUtils';

type ParseOptions = {
	channelId?: string;
	messageId?: string;
	guildId?: string;
	disableAnimatedEmoji?: boolean;
	jumboable?: boolean;
	type?: 'channel-topic' | 'embed-title' | 'inline-reply' | 'profile-bio';
	shouldJumbo?: boolean;
};

const isTextContent = (content: Node['content']): content is string => {
	return typeof content === 'string';
};

const isCodeBlockContent = (
	content: Node['content'],
): content is {
	language: string | null;
	content: string;
} => {
	return typeof content === 'object' && content !== null && 'language' in content && 'content' in content;
};

const isListContent = (
	content: Node['content'],
): content is {
	ordered: boolean;
	items: Array<ListItem>;
} => {
	return typeof content === 'object' && content !== null && 'ordered' in content && 'items' in content;
};

const isHeadingContent = (
	content: Node['content'],
): content is {
	level: number;
	children: Array<Node>;
} => {
	return typeof content === 'object' && content !== null && 'level' in content && 'children' in content;
};

const isLinkContent = (
	content: Node['content'],
): content is {
	text: Node | null;
	url: string;
	escaped: boolean;
} => {
	return typeof content === 'object' && content !== null && 'text' in content && 'url' in content;
};

const getUserInfo = (userId: string, channelId: string) => {
	if (!userId) {
		return {userId: null, name: null};
	}

	const user = UserStore.getUser(userId);
	if (!user) {
		return {userId, name: userId};
	}

	let name = user.displayName;
	const channel = ChannelStore.getChannel(channelId);
	if (channel) {
		name = NicknameUtils.getNickname(user, channel.guildId) || name;
	}

	return {userId: user.id, name};
};

const isToday = (date: Date): boolean => {
	const today = new Date();
	return (
		date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
	);
};

const isYesterday = (date: Date): boolean => {
	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1);
	return (
		date.getDate() === yesterday.getDate() &&
		date.getMonth() === yesterday.getMonth() &&
		date.getFullYear() === yesterday.getFullYear()
	);
};

const formatTime = (date: Date, style: 'short' | 'long'): string => {
	if (style === 'short') {
		return date.toLocaleString('en-US', {
			hour: 'numeric',
			minute: '2-digit',
			hour12: true,
		});
	}
	return date.toLocaleString('en-US', {
		hour: 'numeric',
		minute: '2-digit',
		second: '2-digit',
		hour12: true,
	});
};

const formatDate = (date: Date, style: 'short' | 'long'): string => {
	if (style === 'short') {
		return date.toLocaleDateString('en-US', {
			month: '2-digit',
			day: '2-digit',
			year: 'numeric',
		});
	}
	return date.toLocaleDateString('en-US', {
		month: 'long',
		day: 'numeric',
		year: 'numeric',
	});
};

const formatRelativeTime = (timestamp: number): string => {
	const now = Date.now();
	const diff = Math.abs(now - timestamp * 1000);
	const future = timestamp * 1000 > now;

	const seconds = Math.floor(diff / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);
	const weeks = Math.floor(days / 7);
	const months = Math.floor(days / 30);
	const years = Math.floor(days / 365);

	const date = new Date(timestamp * 1000);
	if (isToday(date)) {
		return i18n.format('TIME_TODAY', {
			time: formatTime(date, 'short'),
		});
	}

	if (isYesterday(date)) {
		return i18n.format('TIME_YESTERDAY', {
			time: formatTime(date, 'short'),
		});
	}

	let key: string;
	let value: number;

	if (years > 0) {
		key = future ? 'TIME_RELATIVE_YEARS_FROM_NOW' : 'TIME_RELATIVE_YEARS_AGO';
		value = years;
	} else if (months > 0) {
		key = future ? 'TIME_RELATIVE_MONTHS_FROM_NOW' : 'TIME_RELATIVE_MONTHS_AGO';
		value = months;
	} else if (weeks > 0) {
		key = future ? 'TIME_RELATIVE_WEEKS_FROM_NOW' : 'TIME_RELATIVE_WEEKS_AGO';
		value = weeks;
	} else if (days > 0) {
		key = future ? 'TIME_RELATIVE_DAYS_FROM_NOW' : 'TIME_RELATIVE_DAYS_AGO';
		value = days;
	} else if (hours > 0) {
		key = future ? 'TIME_RELATIVE_HOURS_FROM_NOW' : 'TIME_RELATIVE_HOURS_AGO';
		value = hours;
	} else if (minutes > 0) {
		key = future ? 'TIME_RELATIVE_MINUTES_FROM_NOW' : 'TIME_RELATIVE_MINUTES_AGO';
		value = minutes;
	} else {
		key = future ? 'TIME_RELATIVE_SECONDS_FROM_NOW' : 'TIME_RELATIVE_SECONDS_AGO';
		value = Math.max(seconds, 1);
	}

	return i18n.format(key, {time: value});
};

const formatTimestamp = (timestamp: number, style: TimestampStyle): string => {
	const date = new Date(timestamp * 1000);

	switch (style) {
		case TimestampStyle.ShortTime:
			return formatTime(date, 'short');

		case TimestampStyle.LongTime:
			return formatTime(date, 'long');

		case TimestampStyle.ShortDate:
			return formatDate(date, 'short');

		case TimestampStyle.LongDate:
			return formatDate(date, 'long');

		case TimestampStyle.ShortDateTime:
			return `${formatDate(date, 'long')} ${formatTime(date, 'short')}`;

		case TimestampStyle.LongDateTime:
			return date.toLocaleString('en-US', {
				weekday: 'long',
				month: 'long',
				day: 'numeric',
				year: 'numeric',
				hour: 'numeric',
				minute: '2-digit',
				hour12: true,
			});

		case TimestampStyle.RelativeTime:
			return formatRelativeTime(timestamp);

		default:
			return `${formatDate(date, 'short')} ${formatTime(date, 'short')}`;
	}
};

const shouldJumboEmojis = (nodes: Array<Node>): boolean => {
	const emojiCount = nodes.filter((node) => {
		return node.type === 'Emoji' || (node.type === 'Text' && UnicodeEmojis.EMOJI_NAME_RE.test(node.content as string));
	}).length;

	return (
		emojiCount > 0 &&
		emojiCount <= 6 &&
		nodes.every((node) => {
			return (
				node.type === 'Emoji' ||
				(node.type === 'Text' &&
					(UnicodeEmojis.EMOJI_NAME_RE.test(node.content as string) || /^\s+$/.test(node.content as string)))
			);
		})
	);
};

type RendererProps = {
	node: Node;
	key: string;
	renderChildren: (nodes: Array<Node>) => React.ReactNode;
} & Pick<ParseOptions, 'channelId' | 'messageId' | 'guildId' | 'disableAnimatedEmoji' | 'shouldJumbo'>;

const renderers = {
	Text: ({node, key}: RendererProps): React.ReactElement => {
		if (!isTextContent(node.content)) {
			throw new Error('Invalid text node content');
		}
		return <span key={key}>{node.content}</span>;
	},

	Strong: ({node, key, renderChildren}: RendererProps): React.ReactElement => {
		if (!Array.isArray(node.content)) {
			throw new Error('Invalid strong content');
		}
		return <strong key={key}>{renderChildren(node.content)}</strong>;
	},

	Emphasis: ({node, key, renderChildren}: RendererProps): React.ReactElement => {
		if (!Array.isArray(node.content)) {
			throw new Error('Invalid emphasis content');
		}
		return <em key={key}>{renderChildren(node.content)}</em>;
	},

	Underline: ({node, key, renderChildren}: RendererProps): React.ReactElement => {
		if (!Array.isArray(node.content)) {
			throw new Error('Invalid underline content');
		}
		return <u key={key}>{renderChildren(node.content)}</u>;
	},

	Strikethrough: ({node, key, renderChildren}: RendererProps): React.ReactElement => {
		if (!Array.isArray(node.content)) {
			throw new Error('Invalid strikethrough content');
		}
		return <s key={key}>{renderChildren(node.content)}</s>;
	},

	Spoiler: ({node, key, renderChildren}: RendererProps): React.ReactElement => {
		if (!Array.isArray(node.content)) {
			throw new Error('Invalid spoiler content');
		}
		return (
			<span key={key} className={markupStyles.spoiler}>
				{renderChildren(node.content)}
			</span>
		);
	},

	Timestamp: ({node, key}: RendererProps): React.ReactElement => {
		const {timestamp, style} = node.content as {timestamp: number; style: TimestampStyle};

		const displayTime = formatTimestamp(timestamp, style);
		const machineReadable = new Date(timestamp * 1000).toISOString();

		return (
			<time key={key} dateTime={machineReadable}>
				{displayTime}
			</time>
		);
	},

	BlockQuote: ({node, key, renderChildren}: RendererProps): React.ReactElement => {
		if (!Array.isArray(node.content)) {
			throw new Error('Invalid blockquote content');
		}

		return (
			<div key={key} className={markupStyles.blockquoteContainer}>
				<div className={markupStyles.blockquoteDivider} />
				<blockquote className={markupStyles.blockquoteContent}>{renderChildren(node.content)}</blockquote>
			</div>
		);
	},

	CodeBlock: ({node, key}: RendererProps): React.ReactElement => {
		if (!isCodeBlockContent(node.content)) {
			throw new Error('Invalid code block content');
		}

		const {language, content} = node.content;
		let codeContent: React.ReactNode;

		if (language && highlight.getLanguage(language)) {
			const highlighted = highlight.highlight(content, {
				language,
				ignoreIllegals: true,
			});
			codeContent = (
				<code
					className={clsx(markupStyles.hljs, language)}
					// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
					dangerouslySetInnerHTML={{__html: highlighted.value}}
				/>
			);
		} else {
			codeContent = <code className={markupStyles.hljs}>{content}</code>;
		}

		return (
			<pre key={key}>
				<div className={markupStyles.codeContainer}>
					<div className={markupStyles.codeActions}>
						<button type="button" onClick={() => TextCopyActionCreators.copy(content)}>
							<CopyIcon className="h-4 w-4" />
						</button>
					</div>
					{codeContent}
				</div>
			</pre>
		);
	},

	InlineCode: ({node, key}: RendererProps): React.ReactElement => {
		if (!isTextContent(node.content)) {
			throw new Error('Invalid inline code content');
		}
		return (
			<code key={key} className={markupStyles.inline}>
				{node.content.replace(/\s+/g, ' ')}
			</code>
		);
	},

	Link: ({node, key, renderChildren}: RendererProps): React.ReactElement => {
		if (!isLinkContent(node.content)) {
			throw new Error('Invalid link content');
		}

		const {text, url} = node.content;
		const code = InviteUtils.findInvite(url);
		let onClick: ((e: React.MouseEvent) => void) | undefined;

		if (code) {
			onClick = (e) => {
				e.preventDefault();
				InviteActionCreators.acceptAndTransitionToChannel(code);
			};
		} else {
			try {
				const parsed = new URL(url);
				if (parsed.host === location.host) {
					onClick = (e) => {
						e.preventDefault();
						RouterUtils.transitionTo(parsed.pathname);
					};
				}
			} catch {}
		}

		const content = text ? renderChildren([text]) : url;
		return (
			<a key={key} href={url} target="_blank" rel="noopener noreferrer" onClick={onClick}>
				{content}
			</a>
		);
	},

	Mention: ({node, key, channelId}: RendererProps): React.ReactElement => {
		const mention = node.content as MentionType;

		switch (mention.kind) {
			case 'User': {
				const {userId, name} = getUserInfo(mention.data, channelId || '');
				if (!userId) {
					return (
						<span key={key} className={markupStyles.mention}>
							@{name || mention.data}
						</span>
					);
				}

				const user = UserStore.getUser(userId);
				if (!user) {
					return (
						<span key={key} className={markupStyles.mention}>
							@{name || mention.data}
						</span>
					);
				}

				const channel = ChannelStore.getChannel(channelId || '');
				const guildId = channel?.guildId || '';

				return (
					<span key={key} className="inline-flex items-center">
						<PreloadableUserPopout user={user} isWebhook={false} guildId={guildId} position="right-start">
							<span className={clsx(markupStyles.mention, markupStyles.interactive)}>
								<span className="-mt-[.2em] inline-flex items-center align-middle">@{name || user.displayName}</span>
							</span>
						</PreloadableUserPopout>
					</span>
				);
			}
			case 'Role': {
				const guild = GuildStore.getGuild(mention.data);
				const role = guild?.roles[mention.data];
				if (!role) {
					return (
						<span key={key} className={markupStyles.mention}>
							{i18n.Messages.UNKNOWN_ROLE_PLACEHOLDER}
						</span>
					);
				}

				return (
					<span
						key={key}
						className={markupStyles.mention}
						style={{
							color: ColorUtils.int2rgb(role.color),
							backgroundColor: ColorUtils.int2rgba(role.color, 0.1),
						}}
					>
						@{role.name}
					</span>
				);
			}

			case 'Channel': {
				const channel = ChannelStore.getChannel(mention.data);
				if (!channel || !GUILD_TEXT_CHANNEL_TYPES.has(channel.type as any)) {
					return (
						<span key={key} className={markupStyles.mention}>
							{i18n.Messages.UNKNOWN_CHANNEL_PLACEHOLDER}
						</span>
					);
				}

				const Icon = ChannelUtils.getIcon(channel.type as ChannelType);
				return (
					<span
						key={key}
						className={markupStyles.mention}
						onClick={() => RouterUtils.transitionTo(`/channels/${channel.guildId}/${channel.id}`)}
						onKeyDown={(e) => {
							if (e.key === 'Enter' || e.key === ' ') {
								RouterUtils.transitionTo(`/channels/${channel.guildId}/${channel.id}`);
							}
						}}
						role="button"
						tabIndex={0}
					>
						<div className="-mt-[.2em] inline-flex items-center align-middle">
							<Icon className="mr-px h-4 w-4" />
							{channel.name}
						</div>
					</span>
				);
			}

			case 'Everyone': {
				return (
					<span key={key} className={markupStyles.mention}>
						@everyone
					</span>
				);
			}

			case 'Here': {
				return (
					<span key={key} className={markupStyles.mention}>
						@here
					</span>
				);
			}

			default:
				throw new Error('Invalid mention kind');
		}
	},

	Emoji: ({node, key, messageId, guildId, disableAnimatedEmoji, shouldJumbo}: RendererProps): React.ReactElement => {
		const emoji = node.content as EmojiType;

		if (emoji.type === 'Standard') {
			const emojiName = UnicodeEmojis.convertSurrogateToName(emoji.data.raw);
			const emojiUrl = EmojiUtils.getTwemojiURL(emoji.data.codepoints);
			return (
				<Tooltip key={key} text={emojiName} delay={750}>
					<img
						draggable={false}
						className={clsx(markupStyles.emoji, shouldJumbo && markupStyles.jumboable)}
						alt={emojiName}
						src={emojiUrl}
					/>
				</Tooltip>
			);
		}

		const {name, id, animated} = emoji.data;

		const shouldAnimate = !disableAnimatedEmoji && animated;

		const channel = ChannelStore.getChannel(guildId || '');
		const disambiguatedEmoji = EmojiStore.getDisambiguatedEmojiContext(channel?.guildId).getById(id);

		const emojiName = disambiguatedEmoji ? disambiguatedEmoji.name : name;

		const baseUrl = AvatarUtils.getEmojiURL({
			id,
			animated: shouldAnimate,
		});

		const size = shouldJumbo ? 240 : 96;

		return (
			<Tooltip key={key} text={`:${emojiName}:`} delay={750} position="top">
				<img
					draggable={false}
					className={clsx(markupStyles.emoji, shouldJumbo && markupStyles.jumboable)}
					alt={`:${emojiName}:`}
					src={`${baseUrl}?size=${size}&quality=lossless`}
					data-message-id={messageId}
					data-emoji-id={id}
					data-animated={shouldAnimate}
				/>
			</Tooltip>
		);
	},

	List: ({node, key, renderChildren}: RendererProps): React.ReactElement => {
		if (!isListContent(node.content)) {
			throw new Error('Invalid list content');
		}

		const {ordered, items} = node.content;
		const Tag = ordered ? 'ol' : 'ul';

		return (
			<Tag key={key}>
				{items.map((item, i) => (
					// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
					<li key={`${key}-item-${i}`}>{renderChildren(item.children)}</li>
				))}
			</Tag>
		);
	},

	Heading: ({node, key, renderChildren}: RendererProps): React.ReactElement => {
		if (!isHeadingContent(node.content)) {
			throw new Error('Invalid heading content');
		}

		const {level, children} = node.content;
		const Tag = `h${level}` as keyof React.JSX.IntrinsicElements;

		return <Tag key={key}>{renderChildren(children)}</Tag>;
	},
};

const renderNode = (node: Node, key: string, context: ParseOptions = {}): React.ReactNode => {
	const renderer = renderers[node.type as keyof typeof renderers];
	if (!renderer) return null;

	const renderChildren = (children: Array<Node>) =>
		children.map((child, i) => renderNode(child, `${key}-${i}`, context));

	return renderer({
		node,
		key,
		renderChildren,
		...context,
	});
};

export const DEFAULT_FLAGS =
	ParserFlags.ALLOW_SPOILERS |
	ParserFlags.ALLOW_HEADINGS |
	ParserFlags.ALLOW_LISTS |
	ParserFlags.ALLOW_CODE_BLOCKS |
	ParserFlags.ALLOW_MASKED_LINKS |
	ParserFlags.ALLOW_COMMAND_MENTIONS |
	ParserFlags.ALLOW_USER_MENTIONS |
	ParserFlags.ALLOW_ROLE_MENTIONS |
	ParserFlags.ALLOW_CHANNEL_MENTIONS |
	ParserFlags.ALLOW_EVERYONE_MENTIONS |
	ParserFlags.ALLOW_HERE_MENTIONS;

export const CHANNEL_TOPIC_FLAGS =
	ParserFlags.ALLOW_MASKED_LINKS |
	ParserFlags.ALLOW_USER_MENTIONS |
	ParserFlags.ALLOW_ROLE_MENTIONS |
	ParserFlags.ALLOW_CHANNEL_MENTIONS;

export const EMBED_TITLE_FLAGS = ParserFlags.ALLOW_SPOILERS | ParserFlags.ALLOW_HEADINGS;

export const INLINE_REPLY_FLAGS =
	ParserFlags.ALLOW_SPOILERS |
	ParserFlags.ALLOW_MASKED_LINKS |
	ParserFlags.ALLOW_USER_MENTIONS |
	ParserFlags.ALLOW_ROLE_MENTIONS |
	ParserFlags.ALLOW_CHANNEL_MENTIONS |
	ParserFlags.ALLOW_EVERYONE_MENTIONS |
	ParserFlags.ALLOW_HERE_MENTIONS;

export const PROFILE_BIO_FLAGS =
	ParserFlags.ALLOW_SPOILERS | ParserFlags.ALLOW_HEADINGS | ParserFlags.ALLOW_LISTS | ParserFlags.ALLOW_MASKED_LINKS;

const parse = (content: string, options?: ParseOptions): React.ReactNode => {
	const nodes = parseFluxerMarkdown(content, DEFAULT_FLAGS);
	const shouldJumbo = options?.jumboable ? shouldJumboEmojis(nodes) : false;
	const context = {...options, shouldJumbo};
	return nodes.map((node, i) => renderNode(node, `node-${i}`, context));
};

const parseChannelTopic = (content: string, options?: ParseOptions): React.ReactNode => {
	try {
		const nodes = parseFluxerMarkdown(content, CHANNEL_TOPIC_FLAGS);
		const shouldJumbo = options?.jumboable ? shouldJumboEmojis(nodes) : false;
		const context = {...options, shouldJumbo};
		return nodes.map((node, i) => renderNode(node, `topic-${i}`, context));
	} catch (error) {
		console.error('Error parsing channel topic:', error);
		return content;
	}
};

const parseEmbedTitle = (content: string, options?: ParseOptions): React.ReactNode => {
	try {
		const nodes = parseFluxerMarkdown(content, EMBED_TITLE_FLAGS);
		const shouldJumbo = options?.jumboable ? shouldJumboEmojis(nodes) : false;
		const context = {...options, shouldJumbo};
		return nodes.map((node, i) => renderNode(node, `embed-${i}`, context));
	} catch (error) {
		console.error('Error parsing embed title:', error);
		return content;
	}
};

const parseInlineReply = (content: string, options?: ParseOptions): React.ReactNode => {
	try {
		const normalizedContent = content.replace(/\s+/g, ' ').trim();
		const nodes = parseFluxerMarkdown(normalizedContent, INLINE_REPLY_FLAGS);
		const shouldJumbo = options?.jumboable ? shouldJumboEmojis(nodes) : false;
		const context = {...options, shouldJumbo};
		return nodes.map((node, i) => renderNode(node, `reply-${i}`, context));
	} catch (error) {
		console.error('Error parsing inline reply:', error);
		return content;
	}
};

const parseProfileBio = (content: string, options?: ParseOptions): React.ReactNode => {
	try {
		const nodes = parseFluxerMarkdown(content, PROFILE_BIO_FLAGS);
		const shouldJumbo = options?.jumboable ? shouldJumboEmojis(nodes) : false;
		const context = {...options, shouldJumbo};
		return nodes.map((node, i) => renderNode(node, `bio-${i}`, context));
	} catch (error) {
		console.error('Error parsing profile bio:', error);
		return content;
	}
};

class MarkdownErrorBoundary extends React.Component<
	{children: React.ReactNode},
	{hasError: boolean; error: Error | null}
> {
	constructor(props: {children: React.ReactNode}) {
		super(props);
		this.state = {hasError: false, error: null};
	}

	static getDerivedStateFromError(error: Error) {
		return {hasError: true, error};
	}

	componentDidCatch(error: Error, info: React.ErrorInfo) {
		console.error('Error rendering markdown:', error, info);
	}

	render() {
		if (this.state.hasError) {
			return <span className={markupStyles.error}>Error rendering content</span>;
		}

		return this.props.children;
	}
}

export const safeParse = (content: string, options?: ParseOptions): React.ReactElement => {
	let parsed: React.ReactNode;

	switch (options?.type) {
		case 'channel-topic':
			parsed = parseChannelTopic(content, options);
			break;
		case 'embed-title':
			parsed = parseEmbedTitle(content, options);
			break;
		case 'inline-reply':
			parsed = parseInlineReply(content, options);
			break;
		case 'profile-bio':
			parsed = parseProfileBio(content, options);
			break;
		default:
			parsed = parse(content, options);
	}

	return <MarkdownErrorBoundary>{typeof parsed === 'string' ? <span>{parsed}</span> : parsed}</MarkdownErrorBoundary>;
};

declare module 'react' {
	interface CSSProperties {
		'--totalCharacters'?: number;
	}
}
