import {UAParser} from 'my-ua-parser';
import type {StatusType} from '~/Constants';
import type {Action} from '~/flux/ActionTypes';
import Dispatcher from '~/flux/Dispatcher';
import {Store} from '~/flux/Store';
import {GatewaySocket, GatewayState} from '~/lib/GatewaySocket';
import {Logger} from '~/lib/Logger';
import DeveloperOptionsStore from '~/stores/DeveloperOptionsStore';
import LocalPresenceStore from '~/stores/LocalPresenceStore';

type State = Readonly<{
	socket: GatewaySocket | null;
	isConnected: boolean;
	sessionId: string | null;
}>;

const initialState: State = {
	socket: null,
	isConnected: false,
	sessionId: null,
};

const logger = new Logger('ConnectionStore');

class ConnectionStore extends Store<State> {
	private previousStatus: StatusType | null = null;

	constructor() {
		super(initialState);
		this.setupPresenceSync();
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'SESSION_START':
				return this.handleSessionStart(action);
			case 'LOGOUT':
				return this.handleLogout();
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen(action);
			case 'CONNECTION_CLOSED':
				return this.handleConnectionClosed();
			default:
				return false;
		}
	}

	useConnectionStatus(): boolean {
		return this.useStore().isConnected;
	}

	getSessionId(): string | null {
		return this.state.sessionId;
	}

	private setupPresenceSync(): void {
		this.syncWith([LocalPresenceStore], () => {
			const {status} = LocalPresenceStore.getState();
			if (status !== this.previousStatus) {
				this.previousStatus = status;
				this.state.socket?.updatePresence(status);
			}
		});
	}

	private createGatewaySocket(token: string): GatewaySocket {
		const userAgent = new UAParser(navigator.userAgent).getResult();
		const socket = new GatewaySocket(window.GLOBAL_ENV.GATEWAY_ENDPOINT, {
			apiVersion: window.GLOBAL_ENV.API_VERSION,
			token,
			codec: DeveloperOptionsStore.getJsonGatewayCodec() ? 'json' : 'cbor',
			properties: {
				os: userAgent.os.name ?? '',
				browser: userAgent.browser.name ?? '',
				device: '',
				locale: navigator.language,
				user_agent: navigator.userAgent,
				browser_version: userAgent.browser.version ?? '',
				os_version: userAgent.os.version ?? '',
				ts: window.GLOBAL_ENV.TS ?? '',
			},
		});

		socket.on('dispatch', this.handleGatewayDispatch.bind(this));
		socket.on('disconnect', (event) => {
			Dispatcher.dispatch({
				type: 'CONNECTION_CLOSED',
				code: event.code,
				reason: event.reason,
			});
		});

		window.addEventListener('online', () => {
			socket.handleNetworkStatusChange(true);
		});
		window.addEventListener('offline', () => {
			socket.handleNetworkStatusChange(false);
		});

		socket.on('stateChange', (newState) => {
			const isConnected = newState === GatewayState.Online;
			if (this.state.isConnected !== isConnected) {
				this.setState((prevState) => ({...prevState, isConnected}));
			}
		});

		return socket;
	}

	private handleSessionStart({token}: {token: string}): boolean {
		if (this.state.socket) {
			logger.warn('Session already started');
			return false;
		}

		const socket = this.createGatewaySocket(token);
		socket.connect();

		this.setState((prevState) => ({
			...prevState,
			socket: socket,
		}));

		return true;
	}

	private handleLogout(): boolean {
		const {socket} = this.state;
		socket?.disconnect();

		this.setState({
			socket,
			isConnected: false,
			sessionId: null,
		});

		return true;
	}

	private handleConnectionOpen({sessionId}: {sessionId: string}): boolean {
		this.setState((prevState) => ({
			...prevState,
			isConnected: true,
			sessionId,
		}));
		return true;
	}

	private handleConnectionClosed(): boolean {
		this.setState((prevState) => ({
			...prevState,
			isConnected: false,
		}));
		return true;
	}

	private handleGatewayDispatch(eventType: string, data: any): void {
		switch (eventType) {
			case 'READY':
				Dispatcher.dispatch({
					type: 'CONNECTION_OPEN',
					sessionId: data.session_id,
					authSessionIdHash: data.auth_session_id_hash,
					user: data.user,
					userSettings: data.user_settings,
					notes: data.notes,
					readStates: data.read_states,
					guilds: data.guilds,
				});
				break;
			case 'RESUMED':
				if (!this.state.isConnected) {
					this.setState((prevState) => ({...prevState, isConnected: true}));
				}
				break;
			case 'AUTH_SESSION_CHANGE':
				this.state.socket?.setToken(data.new_token);
				Dispatcher.dispatch({
					type: 'AUTH_SESSION_CHANGE',
					authSessionIdHash: data.new_auth_session_id_hash,
					token: data.new_token,
				});
				break;
			case 'USER_UPDATE':
				Dispatcher.dispatch({type: eventType, user: data});
				break;
			case 'USER_SETTINGS_UPDATE':
				Dispatcher.dispatch({type: eventType, userSettings: data});
				break;
			case 'USER_NOTE_UPDATE':
				Dispatcher.dispatch({type: eventType, userId: data.id, note: data.note});
				break;
			case 'RECENT_MENTION_DELETE':
				Dispatcher.dispatch({type: eventType, messageId: data.message_id});
				break;
			case 'SAVED_MESSAGE_CREATE':
				Dispatcher.dispatch({type: eventType, message: data});
				break;
			case 'SAVED_MESSAGE_DELETE':
				Dispatcher.dispatch({type: eventType, messageId: data.message_id});
				break;
			case 'PRESENCE_UPDATE':
				Dispatcher.dispatch({type: eventType, presence: data});
				break;
			case 'GUILD_CREATE':
			case 'GUILD_UPDATE':
				Dispatcher.dispatch({type: eventType, guild: data});
				break;
			case 'GUILD_DELETE':
				Dispatcher.dispatch({
					type: eventType,
					guildId: data.id,
					unavailable: data.unavailable,
				});
				break;
			case 'GUILD_MEMBER_ADD':
			case 'GUILD_MEMBER_UPDATE':
				Dispatcher.dispatch({
					type: eventType,
					guildId: data.guild_id,
					member: data,
				});
				break;
			case 'GUILD_MEMBER_REMOVE':
				Dispatcher.dispatch({
					type: eventType,
					guildId: data.guild_id,
					userId: data.user.id,
				});
				break;
			case 'GUILD_ROLE_CREATE':
			case 'GUILD_ROLE_UPDATE':
				Dispatcher.dispatch({
					type: eventType,
					guildId: data.guild_id,
					role: data.role,
				});
				break;
			case 'GUILD_ROLE_DELETE':
				Dispatcher.dispatch({
					type: eventType,
					guildId: data.guild_id,
					roleId: data.role_id,
				});
				break;
			case 'GUILD_EMOJIS_UPDATE':
				Dispatcher.dispatch({
					type: eventType,
					guildId: data.guild_id,
					emojis: data.emojis,
				});
				break;
			case 'GUILD_BAN_ADD':
			case 'GUILD_BAN_REMOVE':
				Dispatcher.dispatch({
					type: eventType,
					guildId: data.guild_id,
					userId: data.user.id,
				});
				break;
			case 'CHANNEL_CREATE':
			case 'CHANNEL_UPDATE':
			case 'CHANNEL_DELETE':
				Dispatcher.dispatch({type: eventType, channel: data});
				break;
			case 'CHANNEL_PINS_UPDATE':
			case 'CHANNEL_PINS_ACK':
				Dispatcher.dispatch({
					type: eventType,
					channelId: data.channel_id,
					lastPinAt: data.last_pin_at,
				});
				break;
			case 'MESSAGE_CREATE':
			case 'MESSAGE_UPDATE':
				Dispatcher.dispatch({type: eventType, message: data});
				break;
			case 'MESSAGE_DELETE':
				Dispatcher.dispatch({
					type: eventType,
					channelId: data.channel_id,
					messageId: data.id,
				});
				break;
			case 'MESSAGE_DELETE_BULK':
				Dispatcher.dispatch({
					type: eventType,
					channelId: data.channel_id,
					messageIds: data.ids,
				});
				break;
			case 'MESSAGE_REACTION_ADD':
			case 'MESSAGE_REACTION_REMOVE':
				Dispatcher.dispatch({
					type: eventType,
					channelId: data.channel_id,
					messageId: data.message_id,
					userId: data.user_id,
					emoji: data.emoji,
				});
				break;
			case 'MESSAGE_REACTION_REMOVE_ALL':
			case 'MESSAGE_REACTION_REMOVE_EMOJI':
				Dispatcher.dispatch({
					type: eventType,
					channelId: data.channel_id,
					messageId: data.message_id,
					emoji: data.emoji,
				});
				break;
			case 'MESSAGE_ACK':
				Dispatcher.dispatch({
					type: eventType,
					channelId: data.channel_id,
					messageId: data.message_id,
					mentionCount: data.mention_count,
					manual: data.manual,
				});
				break;
			case 'TYPING_START':
				Dispatcher.dispatch({
					type: eventType,
					channelId: data.channel_id,
					userId: data.user_id,
				});
				break;
			default:
				break;
		}
	}
}

export default new ConnectionStore();
