import {type ChannelType, ChannelTypes} from '~/Constants';
import {i18n} from '~/i18n';
import {FileIcon} from '~/icons/FileIcon';
import {OpenInNewIcon} from '~/icons/OpenInNewIcon';
import {TagIcon} from '~/icons/TagIcon';

export const getIcon = (type: ChannelType) => {
	switch (type) {
		case ChannelTypes.GUILD_TEXT:
			return TagIcon;
		case ChannelTypes.GUILD_DOCUMENT:
			return FileIcon;
		case ChannelTypes.GUILD_LINK:
			return OpenInNewIcon;
		default:
			return TagIcon;
	}
};

export const getName = (type: ChannelType) => {
	switch (type) {
		case ChannelTypes.GUILD_TEXT:
			return i18n.Messages.TEXT_CHANNEL;
		case ChannelTypes.GUILD_DOCUMENT:
			return i18n.Messages.DOCUMENT_CHANNEL;
		case ChannelTypes.GUILD_LINK:
			return i18n.Messages.LINK_CHANNEL;
		default:
			return i18n.Messages.TEXT_CHANNEL;
	}
};
