import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import {LruCache} from '~/lib/LruCache';
import type {Channel} from '~/records/ChannelRecord';
import type {Guild} from '~/records/GuildRecord';
import type {UserPartial} from '~/records/UserRecord';

export type Invite = Readonly<{
	code: string;
	guild: Readonly<Guild>;
	channel: Readonly<Channel>;
	inviter?: Readonly<UserPartial>;
	member_count: number;
	presence_count: number;
	expires_at: number | null;
	uses?: number;
	max_uses?: number;
	max_age?: number;
}>;

type State = Readonly<{
	invites: LruCache<string, Invite>;
}>;

const CACHE_SIZE = 100;

const createInitialState = (): State => ({
	invites: new LruCache<string, Invite>(CACHE_SIZE),
});

class InviteStore extends Store<State> {
	constructor() {
		super(createInitialState());
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'INVITE_CREATE':
				return this.handleInviteCreate(action);
			default:
				return false;
		}
	}

	getInvite(code: string): Invite | null {
		return this.state.invites.get(code) ?? null;
	}

	private handleInviteCreate({invite}: {invite: Invite}): boolean {
		this.setState((prevState) => {
			const newInvites = new LruCache<string, Invite>(CACHE_SIZE);

			for (const [key, value] of prevState.invites.entries()) {
				newInvites.set(key, value);
			}

			newInvites.set(invite.code, invite);

			return {invites: newInvites};
		});

		return true;
	}
}

export default new InviteStore();
