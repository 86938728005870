import * as AvatarUtils from '~/utils/AvatarUtils';

export type GuildEmoji = Readonly<{
	id: string;
	name: string;
	animated: boolean;
}>;

export class GuildEmojiRecord {
	readonly id: string;
	readonly guildId: string;
	readonly name: string;
	readonly uniqueName: string;
	readonly allNamesString: string;
	readonly url: string;
	readonly animated: boolean;

	constructor(guildId: string, data: GuildEmoji) {
		this.id = data.id;
		this.guildId = guildId;
		this.name = data.name;
		this.uniqueName = data.name;
		this.allNamesString = `:${data.name}:`;
		this.url = AvatarUtils.getEmojiURL({
			id: data.id,
			animated: data.animated,
		});
		this.animated = data.animated;
	}

	withUpdates(updates: Partial<GuildEmoji>): GuildEmojiRecord {
		return new GuildEmojiRecord(this.guildId, {
			id: updates.id ?? this.id,
			name: updates.name ?? this.name,
			animated: updates.animated ?? this.animated,
		});
	}

	equals(other: GuildEmojiRecord): boolean {
		return (
			this.id === other.id &&
			this.guildId === other.guildId &&
			this.name === other.name &&
			this.animated === other.animated
		);
	}

	toJSON(): GuildEmoji {
		return {
			id: this.id,
			name: this.name,
			animated: this.animated,
		};
	}

	static create(guildId: string, data: GuildEmoji): GuildEmojiRecord {
		return new GuildEmojiRecord(guildId, data);
	}
}
