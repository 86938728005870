import {i18n} from '~/i18n';
import * as SnowflakeUtils from '~/utils/SnowflakeUtils';

const getGuildJoinMessages = () => [
	i18n.Messages.JOIN_MESSAGE_1,
	i18n.Messages.JOIN_MESSAGE_2,
	i18n.Messages.JOIN_MESSAGE_3,
	i18n.Messages.JOIN_MESSAGE_4,
	i18n.Messages.JOIN_MESSAGE_5,
	i18n.Messages.JOIN_MESSAGE_6,
	i18n.Messages.JOIN_MESSAGE_7,
	i18n.Messages.JOIN_MESSAGE_8,
	i18n.Messages.JOIN_MESSAGE_9,
	i18n.Messages.JOIN_MESSAGE_10,
	i18n.Messages.JOIN_MESSAGE_11,
	i18n.Messages.JOIN_MESSAGE_12,
	i18n.Messages.JOIN_MESSAGE_13,
	i18n.Messages.JOIN_MESSAGE_14,
	i18n.Messages.JOIN_MESSAGE_15,
	i18n.Messages.JOIN_MESSAGE_16,
	i18n.Messages.JOIN_MESSAGE_17,
	i18n.Messages.JOIN_MESSAGE_18,
	i18n.Messages.JOIN_MESSAGE_19,
	i18n.Messages.JOIN_MESSAGE_20,
];

export const SystemMessageUtils = {
	getGuildJoinMessage(messageId: string): string {
		const messageList = getGuildJoinMessages();
		const messageIndex = SnowflakeUtils.extractTimestamp(messageId) % messageList.length;
		return messageList[messageIndex];
	},
};
