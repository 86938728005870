import clsx from 'clsx';
import highlight from 'highlight.js';
import * as TextCopyActionCreators from '~/actions/TextCopyActionCreators';
import * as Modal from '~/components/modals/Modal';
import {CopyIcon} from '~/icons/CopyIcon';
import markupStyles from '~/styles/Markup.module.css';

const debugModalStyles = {
	container: 'flex flex-col h-full overflow-hidden',
	scrollContainer: 'flex-1 overflow-auto',
	codeWrapper: 'relative h-full w-full',
	pre: 'h-full w-full m-0',
	codeContainer: 'relative h-full w-full',
	copyButton: 'absolute top-2 right-2 z-10',
} as const;

export const DebugModal = ({title, data}: {title: string; data: Record<string, any>}) => {
	const highlighted = highlight.highlight(JSON.stringify(data, null, 2), {
		language: 'json',
		ignoreIllegals: true,
	});

	const codeContent = (
		<code
			className={clsx(markupStyles.hljs, 'json', 'block w-full')}
			// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
			dangerouslySetInnerHTML={{__html: highlighted.value}}
		/>
	);

	return (
		<Modal.Root label={title} size="large">
			<Modal.Header title={title} />

			<Modal.Content className="flex-1 min-h-0 p-0">
				<div className={debugModalStyles.container}>
					<div className={debugModalStyles.scrollContainer}>
						<div className={debugModalStyles.codeWrapper}>
							<div className={clsx(markupStyles.markup, 'h-full')}>
								<pre className={debugModalStyles.pre} style={{maxWidth: '100%'}}>
									<div className={debugModalStyles.codeContainer}>
										<div className={debugModalStyles.copyButton}>
											<button
												type="button"
												className="p-2 rounded-md bg-brand-primary"
												onClick={() => TextCopyActionCreators.copy(JSON.stringify(data, null, 2))}
											>
												<CopyIcon className="h-4 w-4" />
											</button>
										</div>
										{codeContent}
									</div>
								</pre>
							</div>
						</div>
					</div>
				</div>
			</Modal.Content>
		</Modal.Root>
	);
};
