const HEX_REGEX = /^#?([a-fA-F0-9]{1,2})([a-fA-F0-9]{1,2})([a-fA-F0-9]{1,2})$/;

export const pad2 = (s: string) => (s.length === 1 ? `0${s}` : s);

export const hex2int = (hex: string) => {
	if (!hex) {
		return;
	}

	if (hex.length === 4) {
		// biome-ignore lint/style/noParameterAssign: <explanation>
		hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
	}

	return Number.parseInt(hex.slice(1), 16);
};

export const int2hex = (colorInt: number) => {
	const r = (colorInt >> 16) & 0xff;
	const g = (colorInt >> 8) & 0xff;
	const b = colorInt & 0xff;
	return `#${pad2(r.toString(16))}${pad2(g.toString(16))}${pad2(b.toString(16))}`;
};

export const hex2rgb = (hex: string, alpha = 1) => {
	const match = hex.match(HEX_REGEX);
	if (match) {
		const rgb = match.slice(1).map((value) => {
			if (value.length === 1) {
				// biome-ignore lint/style/noParameterAssign: <explanation>
				value += value;
			}
			return Number.parseInt(value, 16);
		});

		return `rgba(${rgb.join(', ')}, ${alpha})`;
	}
	return null;
};

export const rgb2hex = (rgb: string) => {
	const match = rgb.match(/\d{1,3}/g);
	if (match && match.length >= 3) {
		const [r, g, b, a] = match.map(Number);
		if (a == null || a === 0) {
			return 'transparent';
		}
		return `#${pad2(r.toString(16))}${pad2(g.toString(16))}${pad2(b.toString(16))}`;
	}
	return null;
};

export const int2rgba = (colorInt: number, alpha?: number) => {
	if (alpha == null) {
		// biome-ignore lint/style/noParameterAssign: <explanation>
		alpha = ((colorInt >> 24) & 0xff) / 255;
	}

	const r = (colorInt >> 16) & 0xff;
	const g = (colorInt >> 8) & 0xff;
	const b = colorInt & 0xff;

	return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const int2rgb = (colorInt: number) => {
	if (colorInt === 0) {
		return 'rgb(219, 222, 225)';
	}
	const r = (colorInt >> 16) & 0xff;
	const g = (colorInt >> 8) & 0xff;
	const b = colorInt & 0xff;
	return `rgb(${r}, ${g}, ${b})`;
};

export const getDarkness = (color: number) => {
	const r = (color >> 16) & 0xff;
	const g = (color >> 8) & 0xff;
	const b = color & 0xff;
	return 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
};

export const isValidHex = (hex: string) =>
	(hex.length === 4 || hex.length === 7) && hex[0] === '#' && HEX_REGEX.test(hex);
