import * as ReactionActionCreators from '~/actions/ReactionActionCreators';
import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import {type UserPartial, UserRecord} from '~/records/UserRecord';
import UserStore from '~/stores/UserStore';
import {type ReactionEmoji, getReactionKey} from '~/utils/ReactionUtils';

type ReactionUsers = Readonly<Record<string, UserRecord>>;

type Reaction = Readonly<{
	users: ReactionUsers;
	fetched: boolean;
}>;

type ReactionMap = Readonly<Record<string, Reaction>>;

type State = Readonly<{
	reactions: ReactionMap;
}>;

const initialState: State = {
	reactions: {},
};

const createEmptyReaction = (): Reaction => ({
	users: {},
	fetched: false,
});

class MessageReactionsStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen();
			case 'MESSAGE_REACTION_ADD':
			case 'MESSAGE_REACTION_REMOVE':
				return this.handleReaction(action);
			case 'MESSAGE_REACTION_REMOVE_ALL':
				return this.handleRemoveAllReactions(action);
			case 'MESSAGE_REACTION_REMOVE_EMOJI':
				return this.handleRemoveEmojiReactions(action);
			case 'MESSAGE_REACTION_ADD_USERS':
				return this.handleAddUserReactions(action);
			default:
				return false;
		}
	}

	getReactions(channelId: string, messageId: string, emoji: ReactionEmoji): ReadonlyArray<UserRecord> {
		const reactionKey = getReactionKey(messageId, emoji);
		const reaction = this.state.reactions[reactionKey] ?? this.ensureReaction(messageId, emoji);

		if (!reaction.fetched) {
			ReactionActionCreators.getReactions(channelId, messageId, emoji);
			this.setState((prevState) => ({
				reactions: {
					...prevState.reactions,
					[reactionKey]: {
						...reaction,
						fetched: true,
					},
				},
			}));
		}

		return Object.values(reaction.users);
	}

	private ensureReaction(messageId: string, emoji: ReactionEmoji): Reaction {
		const key = getReactionKey(messageId, emoji);
		const existing = this.state.reactions[key];

		if (!existing) {
			const newReaction = createEmptyReaction();
			this.setState((prevState) => ({
				reactions: {
					...prevState.reactions,
					[key]: newReaction,
				},
			}));
			return newReaction;
		}

		return existing;
	}

	private handleConnectionOpen(): boolean {
		this.setState(initialState);
		return true;
	}

	private handleReaction(action: Action & {messageId: string; userId: string; emoji: ReactionEmoji}): boolean {
		this.setState((prevState) => {
			const key = getReactionKey(action.messageId, action.emoji);
			const existingReaction = prevState.reactions[key] ?? createEmptyReaction();

			if (action.type === 'MESSAGE_REACTION_ADD') {
				const user = UserStore.getUser(action.userId);
				if (!user) return prevState;

				return {
					reactions: {
						...prevState.reactions,
						[key]: {
							...existingReaction,
							users: {
								...existingReaction.users,
								[action.userId]: user,
							},
						},
					},
				};
			}

			const {[action.userId]: _, ...remainingUsers} = existingReaction.users;
			return {
				reactions: {
					...prevState.reactions,
					[key]: {
						...existingReaction,
						users: remainingUsers,
					},
				},
			};
		});
		return true;
	}

	private handleRemoveAllReactions(action: {messageId: string}): boolean {
		this.setState((prevState) => ({
			reactions: Object.fromEntries(
				Object.entries(prevState.reactions).filter(([key]) => !key.startsWith(action.messageId)),
			),
		}));
		return true;
	}

	private handleRemoveEmojiReactions(action: {messageId: string; emoji: ReactionEmoji}): boolean {
		const key = getReactionKey(action.messageId, action.emoji);
		this.setState((prevState) => ({
			reactions: {
				...prevState.reactions,
				[key]: createEmptyReaction(),
			},
		}));
		return true;
	}

	private handleAddUserReactions(action: {
		messageId: string;
		users: ReadonlyArray<UserPartial>;
		emoji: ReactionEmoji;
	}): boolean {
		this.setState((prevState) => {
			const key = getReactionKey(action.messageId, action.emoji);
			const existingReaction = prevState.reactions[key] ?? createEmptyReaction();

			UserStore.cacheUsers(action.users.slice());
			const updatedUsers = action.users.reduce<ReactionUsers>(
				(acc, user) => ({
					// biome-ignore lint/performance/noAccumulatingSpread: <explanation>
					...acc,
					[user.id]: new UserRecord(user),
				}),
				existingReaction.users,
			);

			return {
				reactions: {
					...prevState.reactions,
					[key]: {
						...existingReaction,
						users: updatedUsers,
					},
				},
			};
		});
		return true;
	}
}

export default new MessageReactionsStore();
