import {type UserPartial, UserRecord} from '~/records/UserRecord';
import UserStore from '~/stores/UserStore';

export type BetaCode = Readonly<{
	code: string;
	created_at: number;
	redeemed_at: number | null;
	redeemer: UserPartial | null;
}>;

export class BetaCodeRecord {
	readonly code: string;
	readonly createdAt: number;
	readonly redeemedAt: number | null;
	readonly redeemer: UserRecord | null;

	constructor(data: BetaCode) {
		this.code = data.code;
		this.createdAt = data.created_at;
		this.redeemedAt = data.redeemed_at;
		this.redeemer = data.redeemer ? new UserRecord(data.redeemer) : null;

		if (this.redeemer != null) {
			UserStore.cacheUsers([this.redeemer]);
		}
	}

	toJSON(): BetaCode {
		return {
			code: this.code,
			created_at: this.createdAt,
			redeemed_at: this.redeemedAt,
			redeemer: this.redeemer ? this.redeemer.toJSON() : null,
		};
	}

	equals(other: BetaCodeRecord): boolean {
		return JSON.stringify(this) === JSON.stringify(other);
	}
}
