import clsx from 'clsx';
import useFocusLock from 'focus-layers';
import {motion} from 'motion/react';
import React from 'react';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import styles from '~/components/modals/Modal.module.css';
import {Scroller} from '~/components/uikit/Scroller';
import {i18n} from '~/i18n';
import {ClearIcon} from '~/icons/ClearIcon';

export type ModalProps = {
	children: React.ReactNode;
	className?: string;
	label: string;
	size?: 'default' | 'small' | 'large';
	initialFocusRef?: React.RefObject<any>;
};

export const Root = React.forwardRef<HTMLDivElement, ModalProps>(
	({children, className, label, size = 'default', initialFocusRef, ...props}, ref) => {
		const containerRef = React.useRef<HTMLDivElement>(null);
		const backdropRef = React.useRef<HTMLDivElement>(null);

		useFocusLock(containerRef);

		React.useEffect(() => {
			if (initialFocusRef?.current) {
				initialFocusRef.current.focus();
			}
		}, [initialFocusRef]);

		return (
			<>
				<motion.div
					className={styles.backdrop}
					animate={{opacity: 0.85}}
					exit={{opacity: 0}}
					initial={{opacity: 0.1}}
					ref={backdropRef}
					role="presentation"
					onClick={ModalActionCreators.pop}
				/>
				<div className={styles.layer}>
					<div
						aria-label={label}
						aria-modal={true}
						className={styles.focusLock}
						ref={containerRef}
						role="dialog"
						tabIndex={-1}
					>
						<motion.div
							className={className ? clsx('pointer-events-auto', className) : clsx(styles.root, styles[size])}
							animate={{opacity: 1, scale: 1}}
							exit={{opacity: 0, scale: 0.5, transition: {duration: 0.2}}}
							initial={{opacity: 0, scale: 0.3}}
							ref={ref}
							{...props}
						>
							{children}
						</motion.div>
					</div>
				</div>
			</>
		);
	},
);

Root.displayName = 'ModalRoot';

type HeaderProps = {
	children?: React.ReactNode;
	icon?: React.ReactNode;
	title: React.ReactNode;
	variant?: 'default' | 'dark';
};

export const Header = React.forwardRef<HTMLDivElement, HeaderProps>(
	({children, icon, title, variant = 'default', ...props}, ref) => (
		<div className={clsx(styles.layout, styles.header, styles[variant])} ref={ref} {...props}>
			<div className={styles.headerInner}>
				<div className={styles.headerText}>
					{icon}
					{typeof title === 'string' ? <h3>{title}</h3> : title}
				</div>
				<button type="button" aria-label={i18n.Messages.CLOSE} onClick={ModalActionCreators.pop}>
					<ClearIcon width={24} height={24} />
				</button>
			</div>
			{children}
		</div>
	),
);

Header.displayName = 'ModalHeader';

type ContentProps = {
	children: React.ReactNode;
	className?: string;
};

export const Content = React.forwardRef<HTMLDivElement, ContentProps>(({children, className, ...props}, ref) => (
	<Scroller className={clsx(styles.content, className)} ref={ref} {...props}>
		{children}
	</Scroller>
));

Content.displayName = 'ModalContent';

type FooterProps = {
	children: React.ReactNode;
	className?: string;
};

export const Footer = React.forwardRef<HTMLDivElement, FooterProps>(({children, className, ...props}, ref) => (
	<div className={clsx(styles.layout, styles.footer, className)} ref={ref} {...props}>
		{children}
	</div>
));

Footer.displayName = 'ModalFooter';
