import {DateTime} from 'luxon';

export const getRelativeDateString = (timestamp: number) => {
	const dt = DateTime.fromMillis(timestamp);
	const now = DateTime.now();

	if (dt.hasSame(now, 'day')) {
		return `Today at ${dt.toLocaleString(DateTime.TIME_SIMPLE)}`;
	}
	if (dt.hasSame(now.minus({days: 1}), 'day')) {
		return `Yesterday at ${dt.toLocaleString(DateTime.TIME_SIMPLE)}`;
	}

	return dt.toLocaleString({
		month: 'numeric',
		day: 'numeric',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	});
};

export const getFormattedDateTime = (timestamp: number) =>
	DateTime.fromMillis(timestamp).toLocaleString(DateTime.DATETIME_SHORT);

export const getFormattedShortDate = (timestamp: number) =>
	DateTime.fromMillis(timestamp).toLocaleString(DateTime.DATE_MED);

export const getFormattedDateWithFullMonth = (timestamp: number) =>
	DateTime.fromMillis(timestamp).toLocaleString({
		month: 'long',
		day: 'numeric',
		year: 'numeric',
	});

export const getFormattedTime = (timestamp: number) =>
	DateTime.fromMillis(timestamp).toLocaleString(DateTime.TIME_SIMPLE);

export const getFormattedDateTimeWithSeconds = (timestamp: number) => {
	const dt = DateTime.fromMillis(timestamp);
	return `${dt.toLocaleString({
		weekday: 'long',
		month: 'long',
		day: 'numeric',
		year: 'numeric',
	})} ${dt.toLocaleString(DateTime.TIME_WITH_SECONDS)}`;
};

export const getShortRelativeDateString = (timestamp: number) =>
	DateTime.fromMillis(timestamp).toRelative({locale: 'en', style: 'short'});
