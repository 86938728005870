import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import {type User, type UserPrivate, UserRecord} from '~/records/UserRecord';
import AuthenticationStore from '~/stores/AuthenticationStore';

type State = Readonly<{
	users: Readonly<Record<string, UserRecord>>;
}>;

const initialState: State = {
	users: {},
};

class UserStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen(action.user);
			case 'USER_UPDATE':
				return this.handleUserUpdate(action.user);
			case 'GUILD_MEMBER_ADD':
			case 'GUILD_MEMBER_UPDATE':
				return this.handleUserUpdate(action.member.user);
			default:
				return false;
		}
	}

	getUser(userId: string): UserRecord | undefined {
		return this.state.users[userId];
	}

	getCurrentUser(): UserRecord | undefined {
		const currentUserId = AuthenticationStore.getId();
		return currentUserId ? this.state.users[currentUserId] : undefined;
	}

	useUser(userId: string): UserRecord | undefined {
		const {users} = this.useStore();
		return users[userId];
	}

	useCurrentUser(): UserRecord | null {
		const {users} = this.useStore();
		const currentUserId = AuthenticationStore.getId();
		return currentUserId ? (users[currentUserId] ?? null) : null;
	}

	private updateUsers(prevState: State, newUsers: ReadonlyArray<User | UserPrivate>): Record<string, UserRecord> {
		const users = {...prevState.users};
		for (const user of newUsers) {
			const existingUser = users[user.id];
			if (existingUser) {
				users[user.id] = existingUser.withUpdates(user);
			} else {
				users[user.id] = new UserRecord(user);
			}
		}
		return users;
	}

	private handleConnectionOpen(currentUser: UserPrivate): boolean {
		this.setState({
			...initialState,
			users: {
				[currentUser.id]: new UserRecord(currentUser),
			},
		});
		return true;
	}

	private handleUserUpdate(user: User): boolean {
		this.setState((prevState) => {
			const existingUser = prevState.users[user.id];
			return {
				users: {
					...prevState.users,
					[user.id]: existingUser ? existingUser.withUpdates(user) : new UserRecord(user),
				},
			};
		});
		return true;
	}

	public cacheUsers(users: Array<User>): void {
		this.setState((prevState) => ({
			users: this.updateUsers(prevState, users),
		}));
	}
}

export default new UserStore();
