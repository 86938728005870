import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';

type State = Readonly<{
	editingMessageIds: Readonly<Record<string, string>>;
}>;

const initialState: State = {
	editingMessageIds: {},
};

class MessageEditStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'MESSAGE_EDIT_START':
				return this.handleMessageEditStart(action);
			case 'MESSAGE_EDIT_STOP':
				return this.handleMessageEditStop(action);
			default:
				return false;
		}
	}

	useIsEditing(channelId: string, messageId: string): boolean {
		const {editingMessageIds} = this.useStore();
		return editingMessageIds[channelId] === messageId;
	}

	useEditingMessageId(channelId: string): string | null {
		const {editingMessageIds} = this.useStore();
		return editingMessageIds[channelId] ?? null;
	}

	private handleMessageEditStart({
		channelId,
		messageId,
	}: Readonly<{
		channelId: string;
		messageId: string;
	}>): boolean {
		this.setState((prevState) => ({
			editingMessageIds: {
				...prevState.editingMessageIds,
				[channelId]: messageId,
			},
		}));
		return true;
	}

	private handleMessageEditStop({
		channelId,
	}: Readonly<{
		channelId: string;
	}>): boolean {
		this.setState((prevState) => {
			const {[channelId]: _, ...remainingEdits} = prevState.editingMessageIds;
			return {
				editingMessageIds: remainingEdits,
			};
		});
		return true;
	}
}

export default new MessageEditStore();
