import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import * as HttpClient from '~/lib/HttpClient';
import {type BetaCode, BetaCodeRecord} from '~/records/BetaCodeRecord';

export const fetch = async () => {
	Dispatcher.dispatch({type: 'BETA_CODES_FETCH_PENDING'});
	try {
		const {body} = await HttpClient.get<Array<BetaCode>>({url: Endpoints.USER_BETA_CODES});
		Dispatcher.dispatch({
			type: 'BETA_CODES_FETCH_SUCCESS',
			betaCodes: body,
		});
	} catch (error) {
		Dispatcher.dispatch({type: 'BETA_CODES_FETCH_ERROR', error});
		throw error;
	}
};

export const create = async () => {
	Dispatcher.dispatch({type: 'BETA_CODE_CREATE_PENDING'});
	try {
		const {body} = await HttpClient.post<BetaCode>({url: Endpoints.USER_BETA_CODES});
		Dispatcher.dispatch({
			type: 'BETA_CODE_CREATE_SUCCESS',
			betaCode: new BetaCodeRecord(body),
		});
	} catch (error) {
		Dispatcher.dispatch({type: 'BETA_CODE_CREATE_ERROR', error});
		throw error;
	}
};

export const remove = async (code: string) => {
	Dispatcher.dispatch({type: 'BETA_CODE_DELETE_PENDING', code});
	try {
		await HttpClient.del({url: Endpoints.USER_BETA_CODE(code)});
		Dispatcher.dispatch({type: 'BETA_CODE_DELETE_SUCCESS', code});
	} catch (error) {
		Dispatcher.dispatch({type: 'BETA_CODE_DELETE_ERROR', code, error});
		throw error;
	}
};
