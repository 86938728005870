import {type StatusType, StatusTypes} from '~/Constants';
import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import IdleStore from '~/stores/IdleStore';
import UserSettingsStore from '~/stores/UserSettingsStore';

type State = Readonly<{
	status: StatusType;
	since: number;
}>;

type Presence = Readonly<{
	status: StatusType;
	since: number;
}>;

const initialState: State = {
	status: StatusTypes.ONLINE,
	since: 0,
};

class LocalPresenceStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'SESSION_START':
			case 'CONNECTION_OPEN':
			case 'CONNECTION_CLOSED':
			case 'USER_SETTINGS_UPDATE':
			case 'IDLE':
				return this.handleUpdate();
			default:
				return false;
		}
	}

	getStatus(): StatusType {
		return this.state.status;
	}

	getPresence(): Presence {
		return {
			status: this.state.status,
			since: this.state.since,
		};
	}

	private handleUpdate(): boolean {
		const since = IdleStore.getIdleSince();
		const userSettings = UserSettingsStore.getState();

		const status = userSettings.status === StatusTypes.ONLINE && since > 0 ? StatusTypes.IDLE : userSettings.status;

		this.setState({
			status,
			since,
		});

		return true;
	}
}

export default new LocalPresenceStore();
