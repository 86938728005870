import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import * as HttpClient from '~/lib/HttpClient';
import type {FavoriteGif, UserSettings} from '~/stores/UserSettingsStore';
import UserSettingsStore from '~/stores/UserSettingsStore';

export const update = async (settings: Partial<UserSettings>): Promise<void> => {
	const previousSettings = UserSettingsStore.getState();

	const newSettings = {
		...previousSettings,
		...settings,
		...(settings.favorite_gifs ? {favorite_gifs: [...settings.favorite_gifs]} : {}),
	};

	Dispatcher.dispatch({
		type: 'USER_SETTINGS_UPDATE',
		userSettings: newSettings,
	});

	try {
		await HttpClient.patch({url: Endpoints.USER_SETTINGS, body: settings});
	} catch (error) {
		Dispatcher.dispatch({
			type: 'USER_SETTINGS_UPDATE',
			userSettings: previousSettings,
		});
		throw error;
	}
};

export const addFavoriteGif = async (gif: Readonly<FavoriteGif>): Promise<void> => {
	const previousSettings = UserSettingsStore.getState();
	const existingUrls = new Set(previousSettings.favorite_gifs.map((g) => g.url));

	if (!existingUrls.has(gif.url)) {
		const updatedFavoriteGifs = [gif, ...previousSettings.favorite_gifs];

		await update({favorite_gifs: updatedFavoriteGifs});
	}
};

export const removeFavoriteGif = async (url: string): Promise<void> => {
	const previousSettings = UserSettingsStore.getState();
	const existingUrls = new Set(previousSettings.favorite_gifs.map((g) => g.url));

	if (existingUrls.has(url)) {
		const updatedFavoriteGifs = previousSettings.favorite_gifs.filter((gif) => gif.url !== url);
		await update({favorite_gifs: updatedFavoriteGifs});
	}
};

export const reorderFavoriteGifs = async (fromIndex: number, toIndex: number): Promise<void> => {
	const previousSettings = UserSettingsStore.getState();
	const {favorite_gifs} = previousSettings;

	if (fromIndex < 0 || fromIndex >= favorite_gifs.length || toIndex < 0 || toIndex >= favorite_gifs.length) {
		return;
	}

	const updatedFavoriteGifs = [...favorite_gifs];
	const [movedGif] = updatedFavoriteGifs.splice(fromIndex, 1);
	updatedFavoriteGifs.splice(toIndex, 0, movedGif);

	await update({favorite_gifs: updatedFavoriteGifs});
};
