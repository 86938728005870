import type {ModalRender} from '~/actions/ModalActionCreators';
import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';

type Modal = Readonly<{
	modal: ModalRender;
	props?: Readonly<Record<string, unknown>>;
	key: string;
}>;

type State = Readonly<{
	modals: ReadonlyArray<Modal>;
}>;

const initialState: State = {
	modals: [],
};

class ModalStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'MODAL_PUSH':
				return this.handleModalPush(action);
			case 'MODAL_UPDATE':
				return this.handleModalUpdate(action);
			case 'MODAL_POP':
				return this.handleModalPop(action);
			case 'MODAL_POP_ALL':
				return this.handleModalPopAll();
			default:
				return false;
		}
	}

	getModal(): Modal | undefined {
		return this.state.modals.at(-1);
	}

	hasModalOpen(): boolean {
		return this.state.modals.length > 0;
	}

	isModalOpen(modalType: ModalRender): boolean {
		return this.state.modals.some((modal) => modal.modal === modalType);
	}

	private handleModalPush({
		modal,
		props,
		key,
	}: {
		modal: ModalRender;
		props?: Record<string, unknown>;
		key: string | number;
	}): boolean {
		this.setState((prevState) => ({
			modals: [
				...prevState.modals,
				{
					modal,
					props,
					key: key.toString(),
				},
			],
		}));
		return true;
	}

	private handleModalUpdate({
		key,
		props,
		partial,
	}: {
		key: string | number;
		props: Record<string, unknown>;
		partial: boolean;
	}): boolean {
		this.setState((prevState) => {
			const modalIndex = prevState.modals.findIndex((modal) => modal.key === key.toString());
			if (modalIndex === -1) {
				return prevState;
			}

			const existingModal = prevState.modals[modalIndex];
			const updatedModal = {
				...existingModal,
				props: partial ? {...existingModal.props, ...props} : props,
			};

			return {
				modals: [...prevState.modals.slice(0, modalIndex), updatedModal, ...prevState.modals.slice(modalIndex + 1)],
			};
		});
		return true;
	}

	private handleModalPop({key}: {key?: string | number} = {}): boolean {
		this.setState((prevState) => {
			if (key) {
				return {
					modals: prevState.modals.filter((modal) => modal.key !== key.toString()),
				};
			}

			return {
				modals: prevState.modals.slice(0, -1),
			};
		});
		return true;
	}

	private handleModalPopAll(): boolean {
		this.setState(() => initialState);
		return true;
	}
}

export default new ModalStore();
