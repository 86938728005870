import clsx from 'clsx';
import {type FC, useCallback, useEffect, useMemo, useState} from 'react';
import {thumbHashToDataURL} from 'thumbhash';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import {ImagePreviewModal} from '~/components/channel/embeds/media/ImagePreviewModal';
import DeveloperOptionsStore from '~/stores/DeveloperOptionsStore';
import {createCalculator} from '~/utils/DimensionUtils';
import * as ImageCacheUtils from '~/utils/ImageCacheUtils';

const IMAGE_CONFIG = {
	MAX_WIDTH: 400,
} as const;

const imageCalculator = createCalculator({
	maxWidth: IMAGE_CONFIG.MAX_WIDTH,
	responsive: true,
});

type ImageLoadingState = {
	loaded: boolean;
	error: boolean;
	thumbHashURL?: string;
};

type ImagePreviewHandlerProps = {
	src: string;
	originalSrc: string;
	naturalWidth: number;
	naturalHeight: number;
	handlePress?: (event: React.MouseEvent | React.KeyboardEvent) => void;
	children: React.ReactNode;
};

type EmbedImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
	src: string;
	originalSrc: string;
	naturalWidth: number;
	naturalHeight: number;
	width: number;
	height: number;
	placeholder?: string;
	constrain?: boolean;
	isInline?: boolean;
	handlePress?: (event: React.MouseEvent | React.KeyboardEvent) => void;
};

const useImageLoading = (src: string, placeholder?: string): ImageLoadingState => {
	const [loadingState, setLoadingState] = useState<Omit<ImageLoadingState, 'thumbHashURL'>>({
		loaded: ImageCacheUtils.hasImage(src),
		error: false,
	});

	const thumbHashUrl = useMemo(
		() => (placeholder ? thumbHashToDataURL(Uint8Array.from(atob(placeholder), (c) => c.charCodeAt(0))) : undefined),
		[placeholder],
	);

	useEffect(() => {
		if (DeveloperOptionsStore.getForceRenderPlaceholders()) {
			return;
		}

		ImageCacheUtils.loadImage(
			src,
			() => setLoadingState({loaded: true, error: false}),
			() => setLoadingState({loaded: false, error: true}),
		);
	}, [src]);

	return {...loadingState, thumbHashURL: thumbHashUrl};
};

const ImagePreviewHandler: FC<ImagePreviewHandlerProps> = ({
	src,
	originalSrc,
	naturalWidth,
	naturalHeight,
	handlePress,
	children,
}) => {
	const openImagePreview = useCallback(
		(event: React.MouseEvent | React.KeyboardEvent) => {
			if (event.type === 'click' && (event as React.MouseEvent).button !== 0) {
				return;
			}

			if (handlePress) {
				event.preventDefault();
				event.stopPropagation();
				handlePress(event);
				return;
			}

			event.preventDefault();
			event.stopPropagation();
			ModalActionCreators.push(() => (
				<ImagePreviewModal
					src={src}
					originalSrc={originalSrc}
					naturalWidth={naturalWidth}
					naturalHeight={naturalHeight}
					type="image"
				/>
			));
		},
		[src, originalSrc, naturalWidth, naturalHeight, handlePress],
	);

	return (
		<>
			{/* biome-ignore lint/a11y/useAnchorContent: <explanation> */}
			<a
				tabIndex={-1}
				aria-hidden={true}
				className="absolute inset-0 z-10 no-underline"
				href={originalSrc}
				data-safe-src={src}
				onClick={openImagePreview}
				draggable={false}
			/>
			<div
				className="h-full w-full"
				tabIndex={0}
				aria-label="Image"
				role="button"
				onClick={openImagePreview}
				onKeyDown={(event) => event.key === 'Enter' && openImagePreview(event)}
			>
				{children}
			</div>
		</>
	);
};

export const EmbedImage: FC<EmbedImageProps> = ({
	src,
	originalSrc,
	naturalWidth,
	naturalHeight,
	width,
	height,
	placeholder,
	constrain,
	className,
	isInline,
	handlePress,
}) => {
	const {loaded, error, thumbHashURL} = useImageLoading(src, placeholder);

	const {style: containerStyle} = imageCalculator.calculate(
		{width, height},
		{
			preserve: constrain,
			responsive: !isInline,
			aspectRatio: true,
		},
	);

	const shouldRenderPlaceholder = error || !loaded;

	const imageStyle = useMemo(
		() => ({
			...containerStyle,
			aspectRatio: `${width}/${height}`,
		}),
		[containerStyle, width, height],
	);

	return (
		<div className="flex flex-auto flex-col">
			<div className={clsx('flex h-full w-full flex-auto flex-row', isInline && 'justify-end')}>
				<div
					className={clsx(
						'relative block select-text overflow-hidden rounded',
						'transition-all duration-200 ease-out',
						'cursor-pointer select-text',
					)}
					style={containerStyle}
				>
					<ImagePreviewHandler
						src={src}
						originalSrc={originalSrc}
						naturalWidth={naturalWidth}
						naturalHeight={naturalHeight}
						handlePress={handlePress}
					>
						<div className="relative h-full w-full" style={imageStyle}>
							{shouldRenderPlaceholder && thumbHashURL && (
								<div className="absolute inset-0">
									<img src={thumbHashURL} className="h-full w-full object-cover" alt="" loading="lazy" />
								</div>
							)}
							<img
								alt=""
								src={src}
								className={clsx(
									'absolute inset-0',
									'block object-cover',
									'min-h-full min-w-full max-w-full',
									'transition-opacity duration-200',
									shouldRenderPlaceholder ? 'opacity-0' : 'opacity-100',
									className,
								)}
								loading="lazy"
							/>
						</div>
					</ImagePreviewHandler>
				</div>
			</div>
		</div>
	);
};
