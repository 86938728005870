import {ME} from '~/Constants';
import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import type {ProfileRecord} from '~/records/ProfileRecord';
import AuthenticationStore from '~/stores/AuthenticationStore';

type ProfilesByGuildId = Readonly<Record<string, ProfileRecord>>;

type State = Readonly<{
	profiles: Readonly<Record<string, ProfilesByGuildId>>;
	profileTimeouts: Readonly<Record<string, NodeJS.Timeout>>;
}>;

const PROFILE_TIMEOUT_MS = 60_000;

class UserProfileStore extends Store<State> {
	constructor() {
		super({
			profiles: {},
			profileTimeouts: {},
		});
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen();
			case 'USER_PROFILE_CREATE':
				return this.handleProfileCreate(action);
			case 'USER_PROFILES_CLEAR':
				return this.handleProfilesClear();
			default:
				return false;
		}
	}

	getProfile(userId: string, guildId?: string): ProfileRecord | null {
		return this.state.profiles[userId]?.[guildId ?? ME] ?? null;
	}

	private handleConnectionOpen(): boolean {
		Object.values(this.state.profileTimeouts).forEach(clearTimeout);

		this.setState({
			profiles: {},
			profileTimeouts: {},
		});
		return true;
	}

	private handleProfileCreate(action: Action & {profile: ProfileRecord}): boolean {
		const {profile} = action;
		if (!profile?.userId) {
			console.warn('Attempted to set invalid profile:', profile);
			return false;
		}

		const guildId = profile.guildId ?? ME;

		this.setState((prevState) => ({
			...prevState,
			profiles: {
				...prevState.profiles,
				[profile.userId]: {
					...(prevState.profiles[profile.userId] ?? {}),
					[guildId]: profile,
				},
			},
		}));

		this.setProfileTimeout(profile.userId, guildId);
		return true;
	}

	private handleProfilesClear(): boolean {
		const currentUserId = AuthenticationStore.getId();
		if (!currentUserId) {
			console.warn('Attempted to clear profiles without valid user ID');
			return false;
		}

		this.setState((prevState) => {
			const currentUserTimeouts = Object.entries(prevState.profileTimeouts).filter(([key]) =>
				key.startsWith(`${currentUserId}:`),
			);

			for (const [_, timeout] of currentUserTimeouts) {
				clearTimeout(timeout);
			}

			const updatedTimeouts = Object.fromEntries(
				Object.entries(prevState.profileTimeouts).filter(([key]) => !key.startsWith(`${currentUserId}:`)),
			);

			const {[currentUserId]: _, ...remainingProfiles} = prevState.profiles;

			return {
				profiles: remainingProfiles,
				profileTimeouts: updatedTimeouts,
			};
		});

		return true;
	}

	private createTimeoutKey(userId: string, guildId: string): string {
		return `${userId}:${guildId}`;
	}

	private clearProfileTimeout(userId: string, guildId: string): void {
		const timeoutKey = this.createTimeoutKey(userId, guildId);
		const existingTimeout = this.state.profileTimeouts[timeoutKey];

		if (existingTimeout) {
			clearTimeout(existingTimeout);
			this.setState((prevState) => {
				const {[timeoutKey]: _, ...remainingTimeouts} = prevState.profileTimeouts;
				return {
					...prevState,
					profileTimeouts: remainingTimeouts,
				};
			});
		}
	}

	private setProfileTimeout(userId: string, guildId: string): void {
		const timeoutKey = this.createTimeoutKey(userId, guildId);
		this.clearProfileTimeout(userId, guildId);

		const timeout = setTimeout(() => {
			this.setState((prevState) => {
				const userProfiles = prevState.profiles[userId];
				if (!userProfiles) {
					return this.removeTimeout(prevState, timeoutKey);
				}

				const {[guildId]: _, ...remainingGuildProfiles} = userProfiles;

				if (Object.keys(remainingGuildProfiles).length === 0) {
					const {[userId]: __, ...remainingProfiles} = prevState.profiles;
					return this.removeTimeout(prevState, timeoutKey, remainingProfiles);
				}

				return this.removeTimeout(prevState, timeoutKey, {
					...prevState.profiles,
					[userId]: remainingGuildProfiles,
				});
			});
		}, PROFILE_TIMEOUT_MS);

		this.setState((prevState) => ({
			...prevState,
			profileTimeouts: {
				...prevState.profileTimeouts,
				[timeoutKey]: timeout,
			},
		}));
	}

	private removeTimeout(state: State, timeoutKey: string, updatedProfiles: State['profiles'] = state.profiles): State {
		const {[timeoutKey]: _, ...remainingTimeouts} = state.profileTimeouts;

		return {
			profiles: updatedProfiles,
			profileTimeouts: remainingTimeouts,
		};
	}
}

export default new UserProfileStore();
