import {type StatusType, StatusTypes} from '~/Constants';
import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import type {GuildReadyData} from '~/records/GuildRecord';
import type {UserPartial, UserPrivate} from '~/records/UserRecord';
import AuthenticationStore from '~/stores/AuthenticationStore';
import GuildStore from '~/stores/GuildStore';
import LocalPresenceStore from '~/stores/LocalPresenceStore';

export type Presence = Readonly<{
	guild_id: string;
	user: UserPartial;
	status: StatusType;
}>;

type PartialPresence = Readonly<{
	status: StatusType;
	timestamp: number;
}>;

type PresenceMap = Readonly<Record<string, PartialPresence>>;
type GuildPresenceMap = Readonly<Record<string, PresenceMap>>;

type State = Readonly<{
	presencesForGuilds: GuildPresenceMap;
	statuses: Readonly<Record<string, StatusType>>;
}>;

const initialState: State = {
	presencesForGuilds: {},
	statuses: {},
};

class PresenceStore extends Store<State> {
	constructor() {
		super(initialState);
		this.syncWith([LocalPresenceStore], () => this.syncLocalPresence());
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen(action);
			case 'PRESENCE_UPDATE':
				return this.handlePresenceUpdate(action.presence);
			case 'GUILD_CREATE':
				return this.handleGuildCreate(action.guild);
			case 'GUILD_DELETE':
				return this.handleGuildDelete(action);
			case 'GUILD_MEMBER_ADD':
				return this.handleGuildMemberAdd(action.guildId, action.member.user.id);
			case 'GUILD_MEMBER_REMOVE':
				return this.handleGuildMemberRemove(action.guildId, action.userId);
			case 'GUILD_MEMBER_UPDATE':
				return this.handleGuildMemberUpdate(action.guildId, action.member.user.id);
			default:
				return false;
		}
	}

	getStatus(userId: string): StatusType {
		return this.state.statuses[userId] ?? StatusTypes.OFFLINE;
	}

	getPresenceCount(guildId: string): number {
		const localPresence = LocalPresenceStore.getStatus() !== StatusTypes.OFFLINE ? 1 : 0;
		const remotePresences = Object.values(this.state.presencesForGuilds).filter(
			(presences) => presences[guildId]?.status !== StatusTypes.OFFLINE,
		).length;

		return localPresence + remotePresences;
	}

	useUserStatus(userId: string, defaultStatus: StatusType = StatusTypes.OFFLINE): StatusType {
		const {statuses} = this.useStore();
		return statuses[userId] ?? defaultStatus;
	}

	private handleGuildMemberAdd(guildId: string, userId: string): boolean {
		if (userId === AuthenticationStore.getId()) {
			return false;
		}

		this.setState((prevState) => {
			const currentUserPresences = prevState.presencesForGuilds[userId] ?? {};

			return {
				...prevState,
				presencesForGuilds: {
					...prevState.presencesForGuilds,
					[userId]: {
						...currentUserPresences,
						[guildId]: {
							status: StatusTypes.OFFLINE,
							timestamp: Date.now(),
						},
					},
				},
			};
		});

		this.flattenPresence(userId);
		return true;
	}

	private handleGuildMemberRemove(guildId: string, userId: string): boolean {
		if (userId === AuthenticationStore.getId()) {
			return false;
		}

		this.setState((prevState) => {
			const currentUserPresences = prevState.presencesForGuilds[userId];
			if (!currentUserPresences) {
				return prevState;
			}

			const {[guildId]: _, ...remainingPresences} = currentUserPresences;

			if (Object.keys(remainingPresences).length === 0) {
				const {[userId]: __, ...remainingUserPresences} = prevState.presencesForGuilds;
				return {
					...prevState,
					presencesForGuilds: remainingUserPresences,
				};
			}

			return {
				...prevState,
				presencesForGuilds: {
					...prevState.presencesForGuilds,
					[userId]: remainingPresences,
				},
			};
		});

		this.mergePresence(userId);
		return true;
	}

	private handleGuildMemberUpdate(guildId: string, userId: string): boolean {
		if (userId === AuthenticationStore.getId()) {
			return false;
		}

		const guild = GuildStore.getGuild(guildId);
		if (!guild) {
			return false;
		}

		this.setState((prevState) => {
			const currentUserPresences = prevState.presencesForGuilds[userId];
			if (!currentUserPresences?.[guildId]) {
				return prevState;
			}

			return {
				...prevState,
				presencesForGuilds: {
					...prevState.presencesForGuilds,
					[userId]: {
						...currentUserPresences,
						[guildId]: {
							...currentUserPresences[guildId],
							timestamp: Date.now(),
						},
					},
				},
			};
		});

		this.flattenPresence(userId);
		return true;
	}

	private syncLocalPresence(): void {
		const userId = AuthenticationStore.getId();
		if (!userId) {
			return;
		}
		const localStatus = LocalPresenceStore.getStatus();

		this.setState((prevState) => ({
			...prevState,
			statuses: {
				...prevState.statuses,
				[userId]: localStatus,
			},
		}));
	}

	private handleConnectionOpen({user, guilds}: {user: UserPrivate; guilds: ReadonlyArray<GuildReadyData>}): boolean {
		this.setState(() => ({
			presencesForGuilds: {},
			statuses: {[user.id]: LocalPresenceStore.getStatus()},
		}));

		for (const guild of guilds.filter((guild) => !guild.unavailable)) {
			this.handleGuildCreate(guild);
		}

		return true;
	}

	private handleGuildCreate(guild: GuildReadyData): boolean {
		if (guild.unavailable) {
			return false;
		}

		this.setState((prevState) => ({
			...prevState,
			presencesForGuilds: Object.fromEntries(
				Object.entries(prevState.presencesForGuilds).filter(([id]) => id !== guild.id),
			),
		}));

		for (const presence of guild.presences) {
			this.handlePresenceUpdate({...presence, guild_id: guild.id});
		}

		return true;
	}

	private handleGuildDelete({guildId}: {guildId: string}): boolean {
		this.setState((prevState) => {
			const updatedPresences = Object.fromEntries(
				Object.entries(prevState.presencesForGuilds)
					.map(([userId, guilds]) => [
						userId,
						Object.fromEntries(Object.entries(guilds).filter(([id]) => id !== guildId)),
					])
					.filter(([_, guilds]) => Object.keys(guilds).length > 0),
			);

			return {
				...prevState,
				presencesForGuilds: updatedPresences,
			};
		});

		for (const userId of Object.keys(this.state.presencesForGuilds)) {
			this.mergePresence(userId);
		}

		return true;
	}

	private handlePresenceUpdate(presence: Presence): boolean {
		const {guild_id: guildId, user, status} = presence;

		if (user.id === AuthenticationStore.getId()) {
			return false;
		}

		this.setState((prevState) => {
			const currentUserPresences = prevState.presencesForGuilds[user.id] ?? {};

			if (status === StatusTypes.OFFLINE && Object.keys(currentUserPresences).length === 0) {
				return prevState;
			}

			const updatedPresences = {
				...prevState.presencesForGuilds,
				[user.id]: {
					...currentUserPresences,
					[guildId]: {status, timestamp: Date.now()},
				},
			};

			return {
				...prevState,
				presencesForGuilds: updatedPresences,
			};
		});

		this.flattenPresence(user.id);
		return true;
	}

	private flattenPresence(userId: string): void {
		this.setState((prevState) => {
			const presencesForUser = prevState.presencesForGuilds[userId];
			if (!presencesForUser) {
				return prevState;
			}

			const presences = Object.values(presencesForUser);
			if (presences.length === 0) {
				return prevState;
			}

			const allOffline = presences.every((p) => p.status === StatusTypes.OFFLINE);
			if (allOffline) {
				const {[userId]: _, ...remainingPresences} = prevState.presencesForGuilds;
				const {[userId]: __, ...remainingStatuses} = prevState.statuses;

				return {
					presencesForGuilds: remainingPresences,
					statuses: remainingStatuses,
				};
			}

			const latestPresence = presences.reduce((a, b) => (a.timestamp > b.timestamp ? a : b));

			return {
				...prevState,
				statuses: {
					...prevState.statuses,
					[userId]: latestPresence.status,
				},
			};
		});
	}

	private mergePresence(userId: string): void {
		this.setState((prevState) => {
			const presencesForUser = prevState.presencesForGuilds[userId];
			if (!presencesForUser) {
				return prevState;
			}

			const latestPresence = Object.values(presencesForUser).reduce((a, b) => (a.timestamp > b.timestamp ? a : b));

			if (latestPresence.status === StatusTypes.OFFLINE) {
				const {[userId]: _, ...remainingStatuses} = prevState.statuses;
				return {
					...prevState,
					statuses: remainingStatuses,
				};
			}

			return {
				...prevState,
				statuses: {
					...prevState.statuses,
					[userId]: latestPresence.status,
				},
			};
		});
	}
}

export default new PresenceStore();
