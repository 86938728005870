import {Endpoints} from '~/Endpoints';
import * as HttpClient from '~/lib/HttpClient';
import type {GuildMember} from '~/records/GuildMemberRecord';

export const update = async (guildId: string, userId: string, params: Partial<GuildMember>) => {
	await HttpClient.patch({url: Endpoints.GUILD_MEMBER(guildId, userId), body: params});
};

export const addRole = async (guildId: string, userId: string, roleId: string) => {
	await HttpClient.put({url: Endpoints.GUILD_MEMBER_ROLE(guildId, userId, roleId)});
};

export const removeRole = async (guildId: string, userId: string, roleId: string) => {
	await HttpClient.del({url: Endpoints.GUILD_MEMBER_ROLE(guildId, userId, roleId)});
};

export const updateMe = async (guildId: string, params: Partial<GuildMember>) => {
	await HttpClient.patch({url: Endpoints.GUILD_MEMBER(guildId), body: params});
};
