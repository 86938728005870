import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import * as HttpClient from '~/lib/HttpClient';
import ChannelStore from '~/stores/ChannelStore';
import GuildStore from '~/stores/GuildStore';
import ReadStateStore from '~/stores/ReadStateStore';
import * as ReadStateUtils from '~/utils/ReadStateUtils';

type AckBulkReadState = {
	channel_id: string;
	message_id: string;
};

type AckMessageParams = {
	channelId: string;
	messageId: string;
	mentionCount?: number;
	manual?: boolean;
};

const pendingCommits: Map<string, NodeJS.Timeout> = new Map();

const scheduleCommit = (channelId: string, messageId: string, mentionCount: number, manual?: boolean) => {
	if (pendingCommits.has(channelId)) {
		clearTimeout(pendingCommits.get(channelId)!);
	}

	pendingCommits.set(
		channelId,
		setTimeout(async () => {
			pendingCommits.delete(channelId);
			const readState = ReadStateStore.getReadState(channelId);
			if (!readState || readState.message_id !== messageId) {
				return;
			}
			await HttpClient.post({
				url: Endpoints.CHANNEL_MESSAGE_ACK(channelId, readState.message_id),
				body: {manual, mention_count: mentionCount},
			});
		}, 3000),
	);
};

export const ack = async ({channelId, messageId, mentionCount = 0, manual}: AckMessageParams) => {
	Dispatcher.dispatch({
		type: 'MESSAGE_ACK',
		channelId,
		messageId,
		mentionCount,
		manual,
		optimistic: true,
	});

	const readState = ReadStateStore.getReadState(channelId);
	if (readState && !readState.optimistic && readState.message_id === messageId) {
		return;
	}

	scheduleCommit(channelId, messageId, mentionCount, manual);
};

export const ackBulk = async (readStates: Array<AckBulkReadState>) => {
	for (const readState of readStates) {
		Dispatcher.dispatch({
			type: 'MESSAGE_ACK',
			channelId: readState.channel_id,
			messageId: readState.message_id,
			mentionCount: 0,
			optimistic: true,
		});
	}

	await HttpClient.post({url: Endpoints.READ_STATES_ACK_BULK, body: {read_states: readStates}});
};

export const ackGuild = async (guildId: string) => {
	const guild = GuildStore.getGuild(guildId);
	if (!guild) {
		return;
	}
	const readStates: Array<AckBulkReadState> = [];
	for (const channel of ChannelStore.getGuildChannels(guildId)) {
		const readState = ReadStateStore.getReadState(channel.id);
		const hasUnreadMessages = ReadStateUtils.hasUnreadMessages(channel, readState);
		if (!hasUnreadMessages) {
			continue;
		}
		readStates.push({channel_id: channel.id, message_id: channel.lastMessageId as string});
	}
	if (readStates.length > 0) {
		await ackBulk(readStates);
	}
};

export const deleteReadState = async (channelId: string) => {
	await HttpClient.del({url: Endpoints.CHANNEL_MESSAGES_ACK(channelId)});
};
