import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import type {GuildReadyData} from '~/records/GuildRecord';

type State = Readonly<{
	unavailableGuilds: ReadonlySet<string>;
}>;

const initialState: State = {
	unavailableGuilds: new Set(),
};

class GuildAvailabilityStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen(action);
			case 'GUILD_CREATE':
			case 'GUILD_UPDATE':
				return this.handleGuildAvailability(action.guild.id, false);
			case 'GUILD_DELETE':
				return this.handleGuildAvailability(action.guildId, action.unavailable);
			default:
				return false;
		}
	}

	get totalUnavailableGuilds(): number {
		return this.state.unavailableGuilds.size;
	}

	isGuildUnavailable(guildId: string): boolean {
		return this.state.unavailableGuilds.has(guildId);
	}

	useUnavailableGuilds(): ReadonlySet<string> {
		const {unavailableGuilds} = this.useStore();
		return unavailableGuilds;
	}

	private handleConnectionOpen({guilds}: {guilds: ReadonlyArray<GuildReadyData>}): boolean {
		const unavailableGuildIds = guilds.filter((guild) => guild.unavailable).map((guild) => guild.id);

		this.setState({
			unavailableGuilds: new Set(unavailableGuildIds),
		});

		return true;
	}

	private handleGuildAvailability(guildId: string, unavailable = false): boolean {
		this.setState((prevState) => {
			const currentlyUnavailable = prevState.unavailableGuilds.has(guildId);
			if (currentlyUnavailable === unavailable) {
				return prevState;
			}

			const newUnavailableGuilds = new Set(prevState.unavailableGuilds);
			if (unavailable) {
				newUnavailableGuilds.add(guildId);
			} else {
				newUnavailableGuilds.delete(guildId);
			}

			return {
				unavailableGuilds: newUnavailableGuilds,
			};
		});

		return true;
	}
}

export default new GuildAvailabilityStore();
