import {clsx} from 'clsx';
import React from 'react';
import {thumbHashToDataURL} from 'thumbhash';
import {useHover} from '~/hooks/useHover';
import {useMergeRefs} from '~/hooks/useMergeRefs';
import {useOptimalTextSize} from '~/hooks/useOptimalTextSize';
import * as AvatarUtils from '~/utils/AvatarUtils';
import * as ImageCacheUtils from '~/utils/ImageCacheUtils';
import * as StringUtils from '~/utils/StringUtils';

export const GuildIcon = ({
	id,
	name,
	icon,
	className,
}: {
	id: string;
	name: string;
	icon: string | null;
	className?: string;
}) => {
	const initials = StringUtils.getInitialsFromName(name);
	const [fontSize, containerRef] = useOptimalTextSize(initials);
	const [hoverRef, isHovering] = useHover();

	const iconUrl = AvatarUtils.getGuildIconURL({id, icon});
	const hoverIconUrl = AvatarUtils.getGuildIconURL({id, icon}, true);
	const parsedAvatar = icon ? AvatarUtils.parseAvatar(icon) : null;

	const [isStaticLoaded, setIsStaticLoaded] = React.useState(ImageCacheUtils.hasImage(iconUrl));
	const [isAnimatedLoaded, setIsAnimatedLoaded] = React.useState(ImageCacheUtils.hasImage(hoverIconUrl));
	const [shouldPlayAnimated, setShouldPlayAnimated] = React.useState(false);

	React.useEffect(() => {
		ImageCacheUtils.loadImage(iconUrl, () => setIsStaticLoaded(true));
		if (isHovering) {
			ImageCacheUtils.loadImage(hoverIconUrl, () => setIsAnimatedLoaded(true));
		}
	}, [iconUrl, hoverIconUrl, isHovering]);

	React.useEffect(() => {
		setShouldPlayAnimated(isHovering && isAnimatedLoaded);
	}, [isHovering, isAnimatedLoaded]);

	const mergedRef = useMergeRefs([containerRef, hoverRef]);

	return (
		<div
			aria-label={name}
			className={clsx(
				className,
				'flex flex-shrink-0 items-center justify-center rounded-full bg-center bg-cover font-medium text-text-tertiary overflow-hidden',
				!icon && 'bg-background-navbar-surface',
			)}
			key={id}
			ref={mergedRef}
			style={{
				backgroundImage: isStaticLoaded
					? `url(${shouldPlayAnimated && isAnimatedLoaded ? hoverIconUrl : iconUrl})`
					: parsedAvatar
						? `url(${thumbHashToDataURL(Uint8Array.from(atob(parsedAvatar.placeholder), (c) => c.charCodeAt(0)))})`
						: undefined,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				transition: 'background-image 0.3s ease-in-out',
			}}
		>
			{!icon && (
				<span className="whitespace-nowrap" style={{fontSize: `${fontSize}px`, lineHeight: 1}}>
					{initials}
				</span>
			)}
		</div>
	);
};
