import React from 'react';
import * as PopoutActionCreators from '~/actions/PopoutActionCreators';
import type {PopoutKey, PopoutPosition} from '~/components/uikit/Popout';
import type {TooltipPosition} from '~/components/uikit/Tooltip';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import {useMergeRefs} from '~/hooks/useMergeRefs';
import type {ComponentActionType} from '~/lib/ComponentDispatch';
import {ComponentDispatch} from '~/lib/ComponentDispatch';
import PopoutStore from '~/stores/PopoutStore';

let currentId = 1;

export type PopoutProps = {
	children?: React.ReactNode;
	render?: (props: {popoutKey: PopoutKey; onClose: () => void}) => React.ReactNode;
	position?: PopoutPosition;
	dependsOn?: string | number;
	uniqueId?: string | number;
	tooltip?: string | (() => React.ReactNode);
	tooltipPosition?: TooltipPosition;
	tooltipAlign?: 'center' | 'top' | 'bottom' | 'left' | 'right';
	zIndexBoost?: number;
	shouldAutoUpdate?: boolean;
	offsetMainAxis?: number;
	offsetCrossAxis?: number;
	animationType?: 'smooth' | 'none';
	containerClass?: string;
	preventInvert?: boolean;
	hoverDelay?: number;
	toggleClose?: boolean;
	subscribeTo?: ComponentActionType;
	onOpen?: () => void;
	onClose?: () => void;
	onCloseRequest?: (event?: Event) => boolean;
	returnFocusRef?: React.RefObject<HTMLElement>;
	closeOnChildrenUnmount?: boolean;
};

export const openPopout = (target: HTMLElement, props: Partial<PopoutProps>, key: string | number, clickPos = 0) => {
	PopoutActionCreators.open({
		key: key || currentId++,
		dependsOn: props.dependsOn,
		position: props.position!,
		render: props.render as any,
		target,
		zIndexBoost: props.zIndexBoost,
		shouldAutoUpdate: props.shouldAutoUpdate,
		offsetMainAxis: props.offsetMainAxis,
		offsetCrossAxis: props.offsetCrossAxis,
		animationType: props.animationType,
		clickPos,
		containerClass: props.containerClass,
		preventInvert: props.preventInvert,
		onOpen: props.onOpen,
		onClose: props.onClose,
		onCloseRequest: props.onCloseRequest,
		returnFocusRef: props.returnFocusRef,
	});
};

export const Popout = React.forwardRef<HTMLElement, PopoutProps>((props, ref) => {
	const [state, setState] = React.useState({
		id: props.uniqueId || currentId++,
		isOpen: false,
		lastAction: null as 'open' | 'close' | null,
		lastValidChildren: null as React.ReactNode,
	});

	const targetRef = React.useRef<HTMLElement | null>(null);
	const mergedRef = useMergeRefs([ref, targetRef]);
	const [isHovering, setIsHovering] = React.useState(false);
	const hoverTimerRef = React.useRef<NodeJS.Timeout | null>(null);
	const closeTimerRef = React.useRef<NodeJS.Timeout | null>(null);

	React.useEffect(() => {
		if (props.children) {
			setState((prev) => ({...prev, lastValidChildren: props.children}));
		}
	}, [props.children]);

	const clearTimers = React.useCallback(() => {
		if (hoverTimerRef.current) {
			clearTimeout(hoverTimerRef.current);
			hoverTimerRef.current = null;
		}
		if (closeTimerRef.current) {
			clearTimeout(closeTimerRef.current);
			closeTimerRef.current = null;
		}
	}, []);

	React.useEffect(() => {
		const handleStoreChange = () => {
			const isOpenInStore = PopoutStore.isOpen(state.id);
			setState((prev) => ({
				...prev,
				isOpen: isOpenInStore,
				lastAction: null,
			}));
		};

		PopoutStore.addChangeListener(handleStoreChange);
		return () => PopoutStore.removeChangeListener(handleStoreChange);
	}, [state.id]);

	React.useEffect(() => {
		return () => {
			clearTimers();
			if (state.isOpen) {
				PopoutActionCreators.close(state.id);
			}
		};
	}, [state.id, state.isOpen, clearTimers]);

	const close = React.useCallback(
		(event?: Event) => {
			if (props.onCloseRequest && !props.onCloseRequest(event)) {
				return;
			}

			if (state.lastAction !== 'close') {
				setState((prev) => ({...prev, lastAction: 'close'}));
				PopoutActionCreators.close(state.id);
				props.onClose?.();
			}

			if (props.returnFocusRef?.current) {
				props.returnFocusRef.current.focus();
			}
		},
		[state.id, state.lastAction, props],
	);

	const open = React.useCallback(
		(clickPos?: number) => {
			if (!targetRef.current) return;

			if (state.lastAction !== 'open') {
				setState((prev) => ({...prev, lastAction: 'open'}));
				openPopout(targetRef.current, props, state.id, clickPos);
				props.onOpen?.();
			}
		},
		[state.id, state.lastAction, props],
	);

	const toggle = React.useCallback(
		(clickPos: number) => {
			if (PopoutStore.isOpen(state.id)) {
				close();
			} else {
				open(clickPos);
			}
		},
		[state.id, open, close],
	);

	const handleHover = React.useCallback(
		(isEntering: boolean) => {
			if (props.hoverDelay == null) return;

			clearTimers();
			setIsHovering(isEntering);

			if (isEntering) {
				hoverTimerRef.current = setTimeout(() => {
					open();
				}, props.hoverDelay);
			} else {
				closeTimerRef.current = setTimeout(() => {
					if (!isHovering) {
						close();
					}
				}, 300);
			}
		},
		[props.hoverDelay, isHovering, open, close, clearTimers],
	);

	React.useEffect(() => {
		if (props.subscribeTo) {
			ComponentDispatch.subscribe(props.subscribeTo, toggle);
			return () => ComponentDispatch.unsubscribe(props.subscribeTo!, toggle);
		}
	}, [props.subscribeTo, toggle]);

	if (!props.children) {
		return state.isOpen && props.render ? props.render({popoutKey: state.id, onClose: close}) : null;
	}

	const childToRender = props.children || (!props.closeOnChildrenUnmount ? state.lastValidChildren : null);
	if (!childToRender) {
		if (state.isOpen) {
			close();
		}
		return null;
	}

	const child = React.Children.only(childToRender) as React.ReactElement;
	const {onClick, onMouseEnter, onMouseLeave} = child.props;

	const enhancedChild = React.cloneElement(child, {
		'aria-describedby': state.id,
		'aria-expanded': state.isOpen,
		'aria-controls': state.isOpen ? state.id : undefined,
		'aria-haspopup': true,
		'aria-label': props.tooltip,
		onClick: (event: React.MouseEvent) => {
			const clickPos = event.pageX - event.currentTarget.getBoundingClientRect().left;
			event.preventDefault();
			event.stopPropagation();
			toggle(clickPos);
			onClick?.(event);
		},
		onMouseEnter: (event: React.MouseEvent) => {
			handleHover(true);
			onMouseEnter?.(event);
		},
		onMouseLeave: (event: React.MouseEvent) => {
			handleHover(false);
			onMouseLeave?.(event);
		},
		ref: mergedRef,
	});

	return props.tooltip ? (
		<Tooltip text={props.tooltip} position={props.tooltipPosition} align={props.tooltipAlign}>
			{enhancedChild}
		</Tooltip>
	) : (
		enhancedChild
	);
});

Popout.displayName = 'Popout';
