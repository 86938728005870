import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import * as HttpClient from '~/lib/HttpClient';
import type {UserPrivate} from '~/records/UserRecord';

export const update = async (
	user: Partial<UserPrivate> & {
		avatar?: string | null;
		new_password?: string;
		password?: string;
	},
): Promise<UserPrivate & {token?: string}> => {
	const {body} = await HttpClient.patch<UserPrivate>({url: Endpoints.USER_ME, body: user});
	Dispatcher.dispatch({type: 'USER_PROFILES_CLEAR'});
	return body;
};

export const requestDomainChallenge = async (domain: string): Promise<{challenge: string}> => {
	const {body} = await HttpClient.post<{challenge: string}>({
		url: Endpoints.USER_DOMAIN_CHALLENGE,
		body: {domain},
	});
	return body;
};

export const verifyDomain = async (domain: string, password: string): Promise<UserPrivate & {token?: string}> => {
	const {body} = await HttpClient.post<UserPrivate & {token?: string}>({
		url: Endpoints.USER_DOMAIN_VERIFY,
		body: {domain, password},
	});
	Dispatcher.dispatch({type: 'USER_PROFILES_CLEAR'});
	return body;
};

export type UserAvatarDecoration = {
	id: string;
	name: string;
	asset: string;
};

export const getUserAvatarDecorations = async (): Promise<Array<UserAvatarDecoration>> => {
	const {body} = await HttpClient.get<Array<UserAvatarDecoration>>({url: Endpoints.USER_AVATAR_DECORATIONS});
	return body;
};

export const equipUserAvatarDecoration = async (avatarDecorationId: string): Promise<void> => {
	await HttpClient.post<void>({url: Endpoints.USER_AVATAR_DECORATION(avatarDecorationId)});
};

export const unequipUserAvatarDecoration = async (): Promise<void> => {
	await HttpClient.del<void>({url: Endpoints.USER_AVATAR_DECORATIONS});
};
