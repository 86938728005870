import React from 'react';

export type UseOptimalTextSizeOptions = {
	maxSize?: number;
	minSize?: number;
	padding?: number;
};

export const useOptimalTextSize = (text: string, options: UseOptimalTextSizeOptions = {}) => {
	const {maxSize = 18, minSize = 10, padding = 0.1} = options;

	const containerRef = React.useRef<HTMLDivElement>(null);
	const [fontSize, setFontSize] = React.useState(maxSize);

	React.useEffect(() => {
		const calculateFontSize = () => {
			const container = containerRef.current;
			if (!container) return;

			const containerWidth = container.offsetWidth;
			const containerHeight = container.offsetHeight;

			const tempDiv = document.createElement('div');
			tempDiv.style.visibility = 'hidden';
			tempDiv.style.position = 'absolute';
			tempDiv.style.whiteSpace = 'nowrap';
			tempDiv.style.fontFamily = window.getComputedStyle(container).fontFamily;
			document.body.appendChild(tempDiv);

			let testSize = maxSize;
			const paddingSpace = 1 - padding * 2;

			while (testSize > minSize) {
				tempDiv.style.fontSize = `${testSize}px`;
				tempDiv.textContent = text;

				const textWidth = tempDiv.offsetWidth;
				const textHeight = tempDiv.offsetHeight;

				if (textWidth <= containerWidth * paddingSpace && textHeight <= containerHeight * paddingSpace) {
					break;
				}

				testSize = Math.max(testSize - 1, minSize);
			}

			document.body.removeChild(tempDiv);
			setFontSize(testSize);
		};

		calculateFontSize();

		window.addEventListener('resize', calculateFontSize);
		return () => window.removeEventListener('resize', calculateFontSize);
	}, [text, maxSize, minSize, padding]);

	return [fontSize, containerRef] as const;
};
