import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import * as HttpClient from '~/lib/HttpClient';
import type {Message} from '~/records/MessageRecord';

export const fetch = async (channelId: string) => {
	Dispatcher.dispatch({type: 'CHANNEL_PINS_FETCH_PENDING', channelId});
	try {
		const {body} = await HttpClient.get<Array<{message: Message}>>({url: Endpoints.CHANNEL_PINS(channelId)});
		Dispatcher.dispatch({
			type: 'CHANNEL_PINS_FETCH_SUCCESS',
			channelId,
			messages: body.map(({message}) => message),
		});
	} catch (error) {
		Dispatcher.dispatch({type: 'CHANNEL_PINS_FETCH_ERROR', channelId, error});
		throw error;
	}
};

export const pin = async (channelId: string, messageId: string): Promise<void> => {
	await HttpClient.put({url: Endpoints.CHANNEL_PIN(channelId, messageId)});
};

export const unpin = async (channelId: string, messageId: string): Promise<void> => {
	await HttpClient.del({url: Endpoints.CHANNEL_PIN(channelId, messageId)});
};
