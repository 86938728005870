import clsx from 'clsx';
import React from 'react';
import {thumbHashToDataURL} from 'thumbhash';
import {type StatusType, StatusTypeToLabel, StatusTypes} from '~/Constants';
import {Typing} from '~/components/channel/Typing';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import {useHover} from '~/hooks/useHover';
import {useMergeRefs} from '~/hooks/useMergeRefs';
import type {UserRecord} from '~/records/UserRecord';
import styles from '~/styles/Avatar.module.css';
import * as AvatarUtils from '~/utils/AvatarUtils';
import * as ImageCacheUtils from '~/utils/ImageCacheUtils';

const PADDING_MAP: Record<number, number> = {16: 4, 24: 6, 32: 8, 40: 8, 48: 10, 80: 12, 120: 16};
const STATUS_SIZE_MAP: Record<number, number> = {16: 6, 24: 8, 32: 10, 40: 10, 48: 12, 80: 16, 120: 24};
const STATUS_POSITION_MAP: Record<number, number> = {16: 10, 24: 16, 32: 22, 40: 28, 48: 36, 80: 60, 120: 88};

const DECORATION_CONFIG: Record<
	number,
	{
		size: number;
		viewBox: number;
		margin: number;
	}
> = {
	32: {
		size: 38.4,
		viewBox: 46.4,
		margin: 8,
	},
	80: {
		size: 96,
		viewBox: 108,
		margin: 12,
	},
	120: {
		size: 144,
		viewBox: 162,
		margin: 16,
	},
};

type AvatarProps = {
	user: UserRecord;
	size: 16 | 24 | 32 | 40 | 48 | 80 | 120;
	status?: StatusType;
	forceAnimate?: boolean;
	isTyping?: boolean;
	showOffline?: boolean;
	className?: string;
	isClickable?: boolean;
	decorationIcon?: string;
};

export const Avatar = React.forwardRef<HTMLDivElement, AvatarProps>(
	(
		{
			user,
			size,
			status,
			forceAnimate = false,
			isTyping = false,
			showOffline = true,
			className,
			isClickable = false,
			decorationIcon,
			...props
		},
		ref,
	) => {
		const avatarUrl = React.useMemo(() => AvatarUtils.getUserAvatarURL(user, false), [user]);
		const hoverAvatarUrl = React.useMemo(() => AvatarUtils.getUserAvatarURL(user, true), [user]);
		const parsedAvatar = React.useMemo(() => (user.avatar ? AvatarUtils.parseAvatar(user.avatar) : null), [user]);

		const decorationUrl = React.useMemo(() => {
			if (decorationIcon) {
				return AvatarUtils.getUserAvatarDecorationURL({icon: decorationIcon});
			}
			if (user.avatar_decoration) {
				return AvatarUtils.getUserAvatarDecorationURL({icon: user.avatar_decoration});
			}
			return null;
		}, [decorationIcon, user.avatar_decoration]);

		const statusLabel = status != null ? StatusTypeToLabel[status] : null;
		const padding = PADDING_MAP[size] || 0;
		const renderableStatus =
			status != null ? (status === StatusTypes.INVISIBLE ? StatusTypes.OFFLINE : status) : StatusTypes.OFFLINE;
		const shouldShowStatus = status != null && (showOffline || renderableStatus !== StatusTypes.OFFLINE);

		const [hoverRef, isHovering] = useHover();
		const [isStaticLoaded, setIsStaticLoaded] = React.useState(ImageCacheUtils.hasImage(avatarUrl));
		const [isAnimatedLoaded, setIsAnimatedLoaded] = React.useState(ImageCacheUtils.hasImage(hoverAvatarUrl));
		const [isDecorationLoaded, setIsDecorationLoaded] = React.useState(
			decorationUrl ? ImageCacheUtils.hasImage(decorationUrl) : false,
		);
		const [shouldPlayAnimated, setShouldPlayAnimated] = React.useState(false);

		React.useEffect(() => {
			ImageCacheUtils.loadImage(avatarUrl, () => setIsStaticLoaded(true));
			if (isHovering || forceAnimate) {
				ImageCacheUtils.loadImage(hoverAvatarUrl, () => setIsAnimatedLoaded(true));
			}
			if (decorationUrl) {
				ImageCacheUtils.loadImage(decorationUrl, () => setIsDecorationLoaded(true));
			}
		}, [avatarUrl, hoverAvatarUrl, decorationUrl, isHovering, forceAnimate]);

		React.useEffect(() => {
			setShouldPlayAnimated((isHovering || forceAnimate) && isAnimatedLoaded);
		}, [isHovering, forceAnimate, isAnimatedLoaded]);

		const decorationConfig = DECORATION_CONFIG[size];

		return (
			<div
				ref={useMergeRefs([ref, hoverRef])}
				className={clsx(isClickable && styles.clickable, className)}
				role={isClickable ? 'button' : 'img'}
				aria-label={statusLabel ? `${user.handle}, ${statusLabel}` : user.handle}
				style={{width: size, height: size}}
				aria-hidden={false}
				tabIndex={isClickable ? 0 : undefined}
				{...props}
			>
				<svg
					width={size + padding}
					height={size + padding}
					viewBox={`0 0 ${size + padding} ${size + padding}`}
					className="pointer-events-none absolute block w-auto contain-paint"
					aria-hidden={true}
				>
					{shouldShowStatus && isTyping && (
						<mask id={`svg-mask-typing-${size}-${renderableStatus}`}>
							<circle cx={size / 2} cy={size / 2} r={size / 2} fill="#fff" />
							<rect color="#000" width={size - 1} height={size / 2} x={11.5} y={19} rx={size / 4} ry={size / 4} />
						</mask>
					)}

					<foreignObject
						x={0}
						y={0}
						width={size}
						height={size}
						mask={
							shouldShowStatus
								? isTyping
									? `url(#svg-mask-typing-${size}-${renderableStatus})`
									: `url(#svg-mask-avatar-status-round-${size})`
								: 'url(#svg-mask-avatar-default)'
						}
					>
						<div className={clsx('grid h-full w-full', styles.overlay)}>
							<div
								className="pointer-events-none block h-full w-full bg-center bg-cover object-cover"
								style={{
									gridArea: '1 / 1',
									backgroundImage: isStaticLoaded
										? `url(${shouldPlayAnimated && isAnimatedLoaded ? hoverAvatarUrl : avatarUrl})`
										: parsedAvatar
											? `url(${thumbHashToDataURL(Uint8Array.from(atob(parsedAvatar.placeholder), (c) => c.charCodeAt(0)))})`
											: undefined,
								}}
							/>
						</div>
					</foreignObject>

					{shouldShowStatus &&
						(isTyping ? (
							<svg x={14.5} y={17} width={25} height={15} viewBox="0 0 25 15">
								<mask id=":rqm:">
									<rect x={0} y={5} width={25} height={10} rx={5} ry={5} fill="white" />
									<rect x={12.5} y={10} width={0} height={0} rx={0} ry={0} fill="black" />
									<polygon
										points="-2.16506,-2.5 2.16506,0 -2.16506,2.5"
										fill="black"
										transform="scale(0) translate(13.125 10)"
										style={{transformOrigin: '13.125px 10px'}}
									/>
									<circle fill="black" cx={12.5} cy={10} r={0} />
								</mask>
								<rect fill={`var(--status-${renderableStatus})`} width={25} height={15} mask="url(#:rqm:)" />
								<Typing />
							</svg>
						) : (
							<Tooltip text={StatusTypeToLabel[status]}>
								<foreignObject
									x={STATUS_POSITION_MAP[size]}
									y={STATUS_POSITION_MAP[size]}
									width={STATUS_SIZE_MAP[size]}
									height={STATUS_SIZE_MAP[size]}
								>
									<div style={{width: '100%', height: '100%'}} className="pointer-events-auto">
										<svg width={STATUS_SIZE_MAP[size]} height={STATUS_SIZE_MAP[size]}>
											<rect
												width={STATUS_SIZE_MAP[size]}
												height={STATUS_SIZE_MAP[size]}
												fill={`var(--status-${renderableStatus})`}
												mask={`url(#svg-mask-status-${renderableStatus})`}
											/>
										</svg>
									</div>
								</foreignObject>
							</Tooltip>
						))}
				</svg>

				{decorationUrl && decorationConfig && (
					<svg
						width={decorationConfig.viewBox}
						height={decorationConfig.size}
						viewBox={`0 0 ${decorationConfig.viewBox} ${decorationConfig.size}`}
						className="pointer-events-none absolute contain-paint"
						style={{
							top: -decorationConfig.margin,
							left: -decorationConfig.margin,
						}}
						aria-hidden={true}
					>
						<foreignObject
							x={0}
							y={0}
							width={decorationConfig.size}
							height={decorationConfig.size}
							mask={`url(#svg-mask-avatar-decoration-status-round-${size})`}
						>
							<div className="grid h-full w-full">
								{isDecorationLoaded && (
									<img
										className="h-full w-full object-cover pointer-events-none"
										src={decorationUrl}
										alt=" "
										aria-hidden={true}
									/>
								)}
							</div>
						</foreignObject>
					</svg>
				)}
			</div>
		);
	},
);

Avatar.displayName = 'Avatar';
