import type {TooltipItemProps} from '~/components/uikit/Tooltip';
import Dispatcher from '~/flux/Dispatcher';

export const show = (id: string, tooltip: TooltipItemProps) => {
	Dispatcher.dispatch({type: 'TOOLTIP_SHOW', id, tooltip});
};

export const hide = (id: string) => {
	Dispatcher.dispatch({type: 'TOOLTIP_HIDE', id});
};
