import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';

type State = Readonly<{
	notes: Readonly<Record<string, string>>;
}>;

const initialState: State = {
	notes: {},
};

class UserNoteStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen(action);
			case 'USER_NOTE_UPDATE':
				return this.handleUserNoteUpdate(action);
			default:
				return false;
		}
	}

	getUserNote(userId: string): string {
		return this.state.notes[userId] ?? '';
	}

	useUserNote(userId: string): string {
		const {notes} = this.useStore();
		return notes[userId] ?? '';
	}

	private handleConnectionOpen({notes}: {notes: Readonly<Record<string, string>>}): boolean {
		this.setState(() => ({
			notes: {...notes},
		}));
		return true;
	}

	private handleUserNoteUpdate({userId, note}: {userId: string; note: string}): boolean {
		this.setState((prevState) => {
			if (!note) {
				const {[userId]: _, ...remainingNotes} = prevState.notes;
				return {notes: remainingNotes};
			}

			if (prevState.notes[userId] === note) {
				return prevState;
			}

			return {
				notes: {
					...prevState.notes,
					[userId]: note,
				},
			};
		});
		return true;
	}

	clearNote(userId: string): boolean {
		return this.handleUserNoteUpdate({userId, note: ''});
	}

	hasNote(userId: string): boolean {
		return userId in this.state.notes && this.state.notes[userId].length > 0;
	}
}

export default new UserNoteStore();
