import type {UserType} from '~/Constants';
import * as SnowflakeUtils from '~/utils/SnowflakeUtils';

export type BackupCode = Readonly<{
	code: string;
	consumed: boolean;
}>;

export type UserProfile = Readonly<{
	bio: string | null;
	location: string | null;
}>;

export type UserPartial = Readonly<{
	id: string;
	username: string;
	discriminator: string;
	nickname: string | null;
	pronouns: string | null;
	avatar: string | null;
	avatar_decoration: string | null;
	type: UserType;
	flags: number;
}>;

export type UserPrivate = Readonly<
	UserPartial &
		UserProfile & {
			email: string;
			timezone: string | null;
			mfa_enabled: boolean;
		}
>;

export type User = Readonly<UserPartial & Partial<UserPrivate>>;

export class UserRecord {
	readonly id: string;
	readonly username: string;
	readonly discriminator: string;
	readonly nickname: string | null;
	readonly pronouns: string | null;
	readonly avatar: string | null;
	readonly avatar_decoration: string | null;
	readonly type: UserType;
	readonly flags: number;
	readonly email?: string;
	readonly bio?: string | null;
	readonly timezone?: string | null;
	readonly location?: string | null;
	readonly mfaEnabled?: boolean;

	constructor(user: User) {
		this.id = user.id;
		this.username = user.username;
		this.discriminator = user.discriminator;
		this.nickname = user.nickname;
		this.pronouns = user.pronouns;
		this.avatar = user.avatar;
		this.avatar_decoration = user.avatar_decoration;
		this.type = user.type;
		this.flags = user.flags;

		if ('email' in user) this.email = user.email;
		if ('bio' in user) this.bio = user.bio;
		if ('timezone' in user) this.timezone = user.timezone;
		if ('location' in user) this.location = user.location;
		if ('mfa_enabled' in user) this.mfaEnabled = user.mfa_enabled;
	}

	withUpdates(updates: Partial<User>): UserRecord {
		const baseFields: UserPartial = {
			id: updates.id ?? this.id,
			username: updates.username ?? this.username,
			discriminator: updates.discriminator ?? this.discriminator,
			nickname: updates.nickname ?? this.nickname,
			pronouns: updates.pronouns ?? this.pronouns,
			avatar: updates.avatar ?? this.avatar,
			avatar_decoration: updates.avatar_decoration ?? this.avatar_decoration,
			type: updates.type ?? this.type,
			flags: updates.flags ?? this.flags,
		};

		const privateFields: Partial<UserPrivate> = {
			...(this.email !== undefined || updates.email !== undefined ? {email: updates.email ?? this.email} : {}),
			...(this.bio !== undefined || updates.bio !== undefined ? {bio: updates.bio ?? this.bio} : {}),
			...(this.timezone !== undefined || updates.timezone !== undefined
				? {timezone: updates.timezone ?? this.timezone}
				: {}),
			...(this.location !== undefined || updates.location !== undefined
				? {location: updates.location ?? this.location}
				: {}),
			...(this.mfaEnabled !== undefined || updates.mfa_enabled !== undefined
				? {mfa_enabled: updates.mfa_enabled ?? this.mfaEnabled}
				: {}),
		};

		return new UserRecord({
			...baseFields,
			...privateFields,
		});
	}

	get displayName(): string {
		return this.nickname ?? this.username;
	}

	get handle(): string {
		if (this.discriminator === '0') {
			return `@${this.username}`;
		}
		return `${this.username}#${this.discriminator}`;
	}

	get createdAt(): number {
		return SnowflakeUtils.extractTimestamp(this.id);
	}

	equals(other: UserRecord): boolean {
		return (
			this.id === other.id &&
			this.username === other.username &&
			this.discriminator === other.discriminator &&
			this.nickname === other.nickname &&
			this.pronouns === other.pronouns &&
			this.avatar === other.avatar &&
			this.avatar_decoration === other.avatar_decoration &&
			this.type === other.type &&
			this.flags === other.flags &&
			this.email === other.email &&
			this.bio === other.bio &&
			this.timezone === other.timezone &&
			this.location === other.location &&
			this.mfaEnabled === other.mfaEnabled
		);
	}

	toJSON(): User {
		const baseFields: UserPartial = {
			id: this.id,
			username: this.username,
			discriminator: this.discriminator,
			nickname: this.nickname,
			pronouns: this.pronouns,
			avatar: this.avatar,
			avatar_decoration: this.avatar_decoration,
			type: this.type,
			flags: this.flags,
		};

		const privateFields: Partial<UserPrivate> = {
			...(this.email !== undefined ? {email: this.email} : {}),
			...(this.bio !== undefined ? {bio: this.bio} : {}),
			...(this.timezone !== undefined ? {timezone: this.timezone} : {}),
			...(this.location !== undefined ? {location: this.location} : {}),
			...(this.mfaEnabled !== undefined ? {mfa_enabled: this.mfaEnabled} : {}),
		};

		return {
			...baseFields,
			...privateFields,
		};
	}
}
