import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import type {Channel} from '~/records/ChannelRecord';
import {type Message, type MessagePartial, MessageRecord} from '~/records/MessageRecord';
import AuthenticationStore from '~/stores/AuthenticationStore';
import type {ReactionEmoji} from '~/utils/ReactionUtils';

type State = Readonly<{
	savedMessages: ReadonlyArray<MessageRecord>;
	fetched: boolean;
}>;

const initialState: State = {
	savedMessages: [],
	fetched: false,
};

class SavedMessagesStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'SAVED_MESSAGES_FETCH_SUCCESS':
				return this.handleSavedMessagesFetchSuccess(action);
			case 'SAVED_MESSAGES_FETCH_ERROR':
				return this.handleSavedMessagesFetchError();
			case 'CHANNEL_DELETE':
				return this.handleChannelDelete(action);
			case 'MESSAGE_UPDATE':
				return this.handleMessageUpdate(action);
			case 'MESSAGE_DELETE':
				return this.handleMessageDelete(action);
			case 'SAVED_MESSAGE_CREATE':
				return this.handleMessageCreate(action);
			case 'SAVED_MESSAGE_DELETE':
				return this.handleMessageDelete(action);
			case 'MESSAGE_REACTION_ADD':
				return this.handleMessageReactionAdd(action);
			case 'MESSAGE_REACTION_REMOVE':
				return this.handleMessageReactionRemove(action);
			case 'MESSAGE_REACTION_REMOVE_ALL':
				return this.handleMessageReactionRemoveAll(action);
			case 'MESSAGE_REACTION_REMOVE_EMOJI':
				return this.handleMessageReactionRemoveEmoji(action);
			default:
				return false;
		}
	}

	useSavedMessage(messageId: string): boolean {
		const {savedMessages} = this.useStore();
		return savedMessages.some((message) => message.id === messageId);
	}

	private handleSavedMessagesFetchSuccess({messages}: {messages: ReadonlyArray<Message>}): boolean {
		this.setState({
			savedMessages: messages.map((message) => new MessageRecord(message)),
			fetched: true,
		});
		return true;
	}

	private handleSavedMessagesFetchError(): boolean {
		this.setState(initialState);
		return true;
	}

	private handleChannelDelete({channel}: {channel: Channel}): boolean {
		this.setState((prevState) => ({
			...prevState,
			savedMessages: prevState.savedMessages.filter((message) => message.channelId !== channel.id),
		}));
		return true;
	}

	private handleMessageUpdate({message}: {message: Message | MessagePartial}): boolean {
		this.setState((prevState) => {
			const index = prevState.savedMessages.findIndex((m) => m.id === message.id);
			if (index === -1) return prevState;

			return {
				...prevState,
				savedMessages: Object.freeze([
					...prevState.savedMessages.slice(0, index),
					prevState.savedMessages[index].withUpdates(message),
					...prevState.savedMessages.slice(index + 1),
				]),
			};
		});
		return true;
	}

	private handleMessageDelete({messageId}: {messageId: string}): boolean {
		this.setState((prevState) => ({
			...prevState,
			savedMessages: prevState.savedMessages.filter((message) => message.id !== messageId),
		}));
		return true;
	}

	private handleMessageCreate({message}: {message: Message}): boolean {
		this.setState((prevState) => ({
			...prevState,
			savedMessages: Object.freeze([new MessageRecord(message), ...prevState.savedMessages]),
		}));
		return true;
	}

	private updateMessageWithReaction(messageId: string, updater: (message: MessageRecord) => MessageRecord): boolean {
		this.setState((prevState) => {
			const index = prevState.savedMessages.findIndex((m) => m.id === messageId);
			if (index === -1) return prevState;

			return {
				...prevState,
				savedMessages: Object.freeze([
					...prevState.savedMessages.slice(0, index),
					updater(prevState.savedMessages[index]),
					...prevState.savedMessages.slice(index + 1),
				]),
			};
		});
		return true;
	}

	private handleMessageReactionAdd({
		messageId,
		userId,
		emoji,
	}: {
		messageId: string;
		userId: string;
		emoji: ReactionEmoji;
	}): boolean {
		return this.updateMessageWithReaction(messageId, (message) =>
			message.withReaction(emoji, true, userId === AuthenticationStore.getId()),
		);
	}

	private handleMessageReactionRemove({
		messageId,
		userId,
		emoji,
	}: {
		messageId: string;
		userId: string;
		emoji: ReactionEmoji;
	}): boolean {
		return this.updateMessageWithReaction(messageId, (message) =>
			message.withReaction(emoji, false, userId === AuthenticationStore.getId()),
		);
	}

	private handleMessageReactionRemoveAll({messageId}: {messageId: string}): boolean {
		return this.updateMessageWithReaction(messageId, (message) => message.withUpdates({reactions: []}));
	}

	private handleMessageReactionRemoveEmoji({
		messageId,
		emoji,
	}: {
		messageId: string;
		emoji: ReactionEmoji;
	}): boolean {
		return this.updateMessageWithReaction(messageId, (message) => message.withoutReactionEmoji(emoji));
	}
}

export default new SavedMessagesStore();
