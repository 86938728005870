import {getTimeZones} from '@vvo/tzdb';

export const getTimezoneOptions = () =>
	getTimeZones().map((timezone) => ({
		label: timezone.currentTimeFormat,
		value: timezone.name,
	}));

export const getBrowserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getTimeAndOffset = (givenOffsetMinutes: number) => {
	const currentDate = new Date();
	const systemOffsetMinutes = -currentDate.getTimezoneOffset();
	const differenceMinutes = givenOffsetMinutes - systemOffsetMinutes;
	const differenceHours = differenceMinutes / 60;

	let offsetDescription: string;
	if (differenceMinutes === 0) {
		offsetDescription = 'same time';
	} else if (differenceMinutes > 0) {
		offsetDescription = `${Math.abs(differenceHours)}h ahead`;
	} else {
		offsetDescription = `${Math.abs(differenceHours)}h behind`;
	}

	if (differenceMinutes % 60 !== 0) {
		const absoluteDifferenceMinutes = Math.abs(differenceMinutes % 60);
		offsetDescription =
			differenceHours >= 0
				? `${Math.floor(differenceHours)}h ${absoluteDifferenceMinutes}m ahead`
				: `${Math.floor(Math.abs(differenceHours))}h ${absoluteDifferenceMinutes}m behind`;
	}

	let hours = currentDate.getUTCHours() + Math.floor(givenOffsetMinutes / 60);
	let minutes = currentDate.getUTCMinutes() + (givenOffsetMinutes % 60);
	if (minutes >= 60) {
		hours++;
		minutes -= 60;
	}

	const formattedHours = (hours + 24) % 24;
	const amOrPm = formattedHours >= 12 ? 'PM' : 'AM';
	const displayHours = formattedHours % 12 || 12;
	const timeString = `${displayHours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;

	return {
		time: timeString,
		offset: offsetDescription,
	};
};
