import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import {type BetaCode, BetaCodeRecord} from '~/records/BetaCodeRecord';

type FetchStatus = 'idle' | 'pending' | 'success' | 'error';

type State = Readonly<{
	betaCodes: ReadonlyArray<BetaCodeRecord>;
	fetchStatus: FetchStatus;
	isCreateError: boolean;
	isDeleteError: boolean;
}>;

const initialState: State = {
	betaCodes: [],
	fetchStatus: 'idle',
	isCreateError: false,
	isDeleteError: false,
} as const;

class BetaCodeStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'BETA_CODES_FETCH_PENDING':
				return this.handleBetaCodesFetchPending();
			case 'BETA_CODES_FETCH_SUCCESS':
				return this.handleBetaCodesFetchSuccess(action);
			case 'BETA_CODES_FETCH_ERROR':
				return this.handleBetaCodesFetchError();

			case 'BETA_CODE_CREATE_PENDING':
				return this.handleBetaCodeCreatePending();
			case 'BETA_CODE_CREATE_SUCCESS':
				return this.handleBetaCodeCreateSuccess(action);
			case 'BETA_CODE_CREATE_ERROR':
				return this.handleBetaCodeCreateError();

			case 'BETA_CODE_DELETE_PENDING':
				return this.handleBetaCodeDeletePending();
			case 'BETA_CODE_DELETE_SUCCESS':
				return this.handleBetaCodeDeleteSuccess(action);
			case 'BETA_CODE_DELETE_ERROR':
				return this.handleBetaCodeDeleteError();

			default:
				return false;
		}
	}

	getBetaCodes(): ReadonlyArray<BetaCodeRecord> {
		return this.state.betaCodes;
	}

	getFetchStatus(): FetchStatus {
		return this.state.fetchStatus;
	}

	hasCreateError(): boolean {
		return this.state.isCreateError;
	}

	hasDeleteError(): boolean {
		return this.state.isDeleteError;
	}

	useBetaCodes(): ReadonlyArray<BetaCodeRecord> {
		return this.useStore().betaCodes;
	}

	useFetchStatus(): FetchStatus {
		return this.useStore().fetchStatus;
	}

	useCreateError(): boolean {
		return this.useStore().isCreateError;
	}

	useDeleteError(): boolean {
		return this.useStore().isDeleteError;
	}

	private handleBetaCodesFetchPending(): boolean {
		this.setState((prevState) => ({
			...prevState,
			fetchStatus: 'pending',
		}));
		return true;
	}

	private handleBetaCodesFetchSuccess({betaCodes}: {betaCodes: ReadonlyArray<BetaCode>}): boolean {
		this.setState((prevState) => ({
			...prevState,
			betaCodes: betaCodes.map((betaCode) => new BetaCodeRecord(betaCode)),
			fetchStatus: 'success',
		}));
		return true;
	}

	private handleBetaCodesFetchError(): boolean {
		this.setState((prevState) => ({
			...prevState,
			fetchStatus: 'error',
		}));
		return true;
	}

	private handleBetaCodeCreatePending(): boolean {
		this.setState((prevState) => ({
			...prevState,
			isCreateError: false,
		}));
		return true;
	}

	private handleBetaCodeCreateSuccess({betaCode}: {betaCode: BetaCodeRecord}): boolean {
		this.setState((prevState) => ({
			...prevState,
			betaCodes: [...prevState.betaCodes, betaCode],
		}));
		return true;
	}

	private handleBetaCodeCreateError(): boolean {
		this.setState((prevState) => ({
			...prevState,
			isCreateError: true,
		}));
		return true;
	}

	private handleBetaCodeDeletePending(): boolean {
		this.setState((prevState) => ({
			...prevState,
			isDeleteError: false,
		}));
		return true;
	}

	private handleBetaCodeDeleteSuccess({code}: {code: string}): boolean {
		this.setState((prevState) => ({
			...prevState,
			betaCodes: prevState.betaCodes.filter((betaCode) => betaCode.code !== code),
		}));
		return true;
	}

	private handleBetaCodeDeleteError(): boolean {
		this.setState((prevState) => ({
			...prevState,
			isDeleteError: true,
		}));
		return true;
	}
}

export default new BetaCodeStore();
