import {decode as decodeCbor, encode as encodeCbor} from 'cbor2';
import EventEmitter from 'eventemitter3';
import type {GatewayOpCode, StatusType} from '~/Constants';
import {GatewayCloseCodes, GatewayOpCodes} from '~/Constants';
import {ExponentialBackoff} from '~/lib/ExponentialBackoff';
import {Logger} from '~/lib/Logger';

export enum GatewayTimeouts {
	HeartbeatAck = 15000,
	ResumeWindow = 180000,
	RateLimit = 30000,
	MinReconnect = 1000,
	MaxReconnect = 60000,
	Hello = 20000,
}

export enum GatewayState {
	Offline = 'OFFLINE',
	Connecting = 'CONNECTING',
	WaitingForHello = 'WAITING_FOR_HELLO',
	Identifying = 'IDENTIFYING',
	Resuming = 'RESUMING',
	Online = 'ONLINE',
	Reconnecting = 'RECONNECTING',
}

export enum GatewayAction {
	Start = 'START',
	Stop = 'STOP',
	SocketOpen = 'SOCKET_OPEN',
	SocketClose = 'SOCKET_CLOSE',
	SocketError = 'SOCKET_ERROR',
	HelloReceived = 'HELLO_RECEIVED',
	Ready = 'READY',
	Resumed = 'RESUMED',
	InvalidSession = 'INVALID_SESSION',
	HeartbeatTimeout = 'HEARTBEAT_TIMEOUT',
	Retry = 'RETRY',
	MaxRetries = 'MAX_RETRIES',
	Reset = 'RESET',
}

export type CodecType = 'json' | 'cbor';

type Codec = {
	encode(data: unknown): string | Uint8Array;
	decode(data: string | ArrayBuffer | Blob): unknown;
};

class JsonCodec implements Codec {
	encode(data: unknown): string | Uint8Array {
		return JSON.stringify(data);
	}

	decode(data: string | ArrayBuffer | Blob): unknown {
		if (data instanceof Blob) {
			throw new Error('JSON codec cannot handle Blob data');
		}
		if (data instanceof ArrayBuffer) {
			return JSON.parse(new TextDecoder().decode(data));
		}
		return JSON.parse(data);
	}
}

class CborCodec implements Codec {
	encode(data: unknown): Uint8Array {
		return encodeCbor(data);
	}

	decode(data: string | ArrayBuffer | Blob): unknown {
		if (data instanceof Blob) {
			throw new Error('CBOR codec cannot handle Blob data');
		}
		if (typeof data === 'string') {
			const encoder = new TextEncoder();
			// biome-ignore lint/style/noParameterAssign: <explanation>
			data = encoder.encode(data).buffer;
		}
		const uint8Array = new Uint8Array(data);
		return decodeCbor(uint8Array);
	}
}

const CODECS: Record<CodecType, Codec> = {
	json: new JsonCodec(),
	cbor: new CborCodec(),
};

export type GatewayTransition = {
	newState: GatewayState;
	action?: () => void;
};

export type GatewayPayload = {
	op: GatewayOpCode;
	d?: unknown;
	s?: number;
	t?: string;
};

export type GatewaySocketProperties = {
	os: string;
	browser: string;
	device: string;
	locale: string;
	user_agent: string;
	browser_version: string;
	os_version: string;
	ts: string;
};

export type GatewaySocketOptions = {
	token: string;
	apiVersion: number;
	properties: GatewaySocketProperties;
	codec?: CodecType;
};

export type GatewaySocketEvents = {
	connecting: () => void;
	connected: () => void;
	ready: (data: unknown) => void;
	resumed: () => void;
	disconnect: (event: {code: number; reason: string; wasClean: boolean}) => void;
	error: (error: Error | Event | CloseEvent) => void;
	message: (payload: GatewayPayload) => void;
	dispatch: (type: string, data: unknown) => void;
	stateChange: (newState: GatewayState, oldState: GatewayState) => void;
	heartbeat: (sequence: number) => void;
	heartbeatAck: () => void;
	networkStatusChange: (online: boolean) => void;
};

type StateHandler = {
	handle(action: GatewayAction, data?: unknown): GatewayTransition;
};

class OfflineHandler implements StateHandler {
	handle(action: GatewayAction): GatewayTransition {
		if (action === GatewayAction.Start) {
			return {newState: GatewayState.Connecting};
		}
		return {newState: GatewayState.Offline};
	}
}

class ConnectingHandler implements StateHandler {
	constructor(private readonly handleConnectionFailure: () => void) {}

	handle(action: GatewayAction): GatewayTransition {
		switch (action) {
			case GatewayAction.SocketOpen:
				return {newState: GatewayState.WaitingForHello};
			case GatewayAction.SocketError:
			case GatewayAction.SocketClose:
				return {
					newState: GatewayState.Reconnecting,
					action: () => this.handleConnectionFailure(),
				};
			default:
				return {newState: GatewayState.Connecting};
		}
	}
}

class WaitingForHelloHandler implements StateHandler {
	constructor(private readonly canResume: () => boolean) {}

	handle(action: GatewayAction): GatewayTransition {
		switch (action) {
			case GatewayAction.HelloReceived:
				return {
					newState: this.canResume() ? GatewayState.Resuming : GatewayState.Identifying,
				};
			case GatewayAction.SocketClose:
			case GatewayAction.SocketError:
				return {newState: GatewayState.Reconnecting};
			default:
				return {newState: GatewayState.WaitingForHello};
		}
	}
}

class IdentifyingHandler implements StateHandler {
	handle(action: GatewayAction, data?: unknown): GatewayTransition {
		switch (action) {
			case GatewayAction.Ready:
				return {newState: GatewayState.Online};
			case GatewayAction.InvalidSession:
				return {newState: GatewayState.Identifying};
			case GatewayAction.SocketClose: {
				const event = data as CloseEvent;
				if (event.code === GatewayCloseCodes.AUTHENTICATION_FAILED) {
					return {newState: GatewayState.Offline};
				}
				return {newState: GatewayState.Reconnecting};
			}
			default:
				return {newState: GatewayState.Identifying};
		}
	}
}

class ResumingHandler implements StateHandler {
	handle(action: GatewayAction): GatewayTransition {
		switch (action) {
			case GatewayAction.Resumed:
				return {newState: GatewayState.Online};
			case GatewayAction.InvalidSession:
				return {newState: GatewayState.Identifying};
			case GatewayAction.SocketClose:
			case GatewayAction.SocketError:
				return {newState: GatewayState.Reconnecting};
			default:
				return {newState: GatewayState.Resuming};
		}
	}
}

class OnlineHandler implements StateHandler {
	constructor(
		private readonly canResume: () => boolean,
		private readonly resetAndIdentify: () => void,
	) {}

	handle(action: GatewayAction, data?: unknown): GatewayTransition {
		switch (action) {
			case GatewayAction.HeartbeatTimeout:
				return {newState: GatewayState.Reconnecting};
			case GatewayAction.SocketClose: {
				const event = data as CloseEvent;
				if (event.code === GatewayCloseCodes.AUTHENTICATION_FAILED) {
					return {newState: GatewayState.Offline};
				}

				if (!this.canResume()) {
					return {
						newState: GatewayState.Identifying,
						action: () => this.resetAndIdentify(),
					};
				}

				return {newState: GatewayState.Reconnecting};
			}
			case GatewayAction.Stop:
				if (data === true && this.canResume()) {
					return {newState: GatewayState.Reconnecting};
				}
				return {newState: GatewayState.Offline};
			case GatewayAction.Reset:
				return {newState: GatewayState.Offline};
			default:
				return {newState: GatewayState.Online};
		}
	}
}

class ReconnectingHandler implements StateHandler {
	constructor(private readonly resetAndRetry: () => void) {}

	handle(action: GatewayAction): GatewayTransition {
		switch (action) {
			case GatewayAction.Retry:
				return {newState: GatewayState.Connecting};
			case GatewayAction.MaxRetries:
				return {
					newState: GatewayState.Connecting,
					action: () => this.resetAndRetry(),
				};
			default:
				return {newState: GatewayState.Reconnecting};
		}
	}
}

class HeartbeatManager {
	private heartbeatInterval: number | null = null;
	private heartbeatTimer: number | null = null;
	private ackTimer: number | null = null;
	private waitingForAck = false;
	private lastHeartbeatAckTime: number | null = null;
	private lastHeartbeatTime: number | null = null;
	private readonly logger: Logger;

	constructor(
		private readonly send: (payload: GatewayPayload) => boolean,
		private readonly onTimeout: () => void,
		private readonly sequence: () => number,
		private readonly onHeartbeat: (sequence: number) => void,
	) {
		this.logger = new Logger('HeartbeatManager');
	}

	start(interval: number): void {
		this.stop();
		this.heartbeatInterval = interval;
		const jitter = Math.random() * interval;
		this.scheduleNextHeartbeat(jitter);
	}

	private scheduleNextHeartbeat(delay: number): void {
		if (!this.heartbeatInterval) return;

		this.heartbeatTimer = window.setTimeout(() => {
			this.sendHeartbeat();
			this.scheduleNextHeartbeat(this.heartbeatInterval!);
		}, delay);
	}

	private sendHeartbeat(): void {
		if (this.waitingForAck) {
			this.logger.warn('No heartbeat acknowledgment received');
			this.onTimeout();
			return;
		}

		const sequence = this.sequence();
		if (this.send({op: GatewayOpCodes.HEARTBEAT, d: sequence})) {
			this.waitingForAck = true;
			this.lastHeartbeatTime = Date.now();
			this.onHeartbeat(sequence);

			this.startAckTimeout();
			this.logger.debug(`Sent heartbeat [s=${sequence}]`);
		} else {
			this.logger.error('Failed to send heartbeat');
			this.onTimeout();
		}
	}

	private startAckTimeout(): void {
		this.ackTimer = window.setTimeout(() => {
			if (this.waitingForAck) {
				this.logger.warn('Heartbeat acknowledgment timeout');
				this.onTimeout();
			}
		}, GatewayTimeouts.HeartbeatAck);
	}

	handleAck(): void {
		this.waitingForAck = false;
		this.lastHeartbeatAckTime = Date.now();
		if (this.ackTimer != null) {
			clearTimeout(this.ackTimer);
			this.ackTimer = null;
		}
		this.logger.debug('Received heartbeat acknowledgment');
	}

	sendNow(): void {
		if (!this.waitingForAck) {
			this.sendHeartbeat();
		}
	}

	stop(): void {
		if (this.heartbeatTimer != null) {
			clearTimeout(this.heartbeatTimer);
			this.heartbeatTimer = null;
		}
		if (this.ackTimer != null) {
			clearTimeout(this.ackTimer);
			this.ackTimer = null;
		}
		this.waitingForAck = false;
		this.heartbeatInterval = null;
		this.lastHeartbeatTime = null;
		this.logger.debug('Heartbeat stopped');
	}

	getLastAckTime(): number | null {
		return this.lastHeartbeatAckTime;
	}

	getLastHeartbeatTime(): number | null {
		return this.lastHeartbeatTime;
	}
}

class SessionManager {
	private sessionId: string | null = null;
	private sequence = 0;
	private readonly backoff: ExponentialBackoff;
	private readonly logger: Logger;
	private lastReconnectTime = 0;

	constructor() {
		this.logger = new Logger('SessionManager');
		this.backoff = new ExponentialBackoff({
			minDelay: GatewayTimeouts.MinReconnect,
			maxDelay: GatewayTimeouts.MaxReconnect,
		});
	}

	handleDispatch(sequence: number | undefined): void {
		if (sequence !== undefined && sequence > this.sequence) {
			this.sequence = sequence;
		}
	}

	startNewSession(sessionId: string): void {
		this.sessionId = sessionId;
		this.backoff.reset();
		this.logger.info(`Started new session: ${sessionId}`);
	}

	reset(): void {
		const hadSession = Boolean(this.sessionId);
		this.sessionId = null;
		this.sequence = 0;
		this.backoff.reset();
		if (hadSession) {
			this.logger.info('Session reset');
		}
	}

	getSessionId(): string | null {
		return this.sessionId;
	}

	getSequence(): number {
		return this.sequence;
	}

	getNextReconnectDelay(): number {
		const now = Date.now();
		const timeSinceLastReconnect = now - this.lastReconnectTime;
		if (timeSinceLastReconnect < GatewayTimeouts.MinReconnect) {
			return GatewayTimeouts.MinReconnect;
		}

		this.lastReconnectTime = now;
		return this.backoff.next();
	}

	hasExceededMaxRetries(): boolean {
		return this.backoff.getCurrentAttempts() >= this.backoff.getMaxAttempts();
	}

	resetBackoff(): void {
		this.backoff.reset();
	}
}

class ConnectionManager {
	private websocket: WebSocket | null = null;
	private readonly logger: Logger;
	private readonly codec: Codec;

	constructor(
		private readonly onMessage: (data: GatewayPayload) => void,
		private readonly onClose: (event: CloseEvent) => void,
		private readonly onError: (event: Event) => void,
		private readonly onOpen: () => void,
		private readonly codecType: CodecType,
	) {
		this.logger = new Logger('ConnectionManager');
		this.codec = CODECS[codecType];
	}

	connect(url: string): void {
		this.logger.debug('Starting connection to:', url);
		this.cleanup();

		try {
			this.logger.debug('Creating WebSocket...');
			this.websocket = new WebSocket(url);

			if (this.codecType === 'cbor') {
				this.logger.debug('Setting binary type for CBOR codec');
				this.websocket.binaryType = 'arraybuffer';
			}

			this.logger.debug('Current WebSocket readyState:', this.websocket.readyState);
			this.logger.debug('Adding event listeners...');

			this.websocket.addEventListener('open', this.onOpen);
			this.websocket.addEventListener('message', this.handleMessage);
			this.websocket.addEventListener('close', this.handleClose);
			this.websocket.addEventListener('error', this.handleError);

			this.logger.debug('WebSocket setup complete');
		} catch (error) {
			this.logger.error('Failed to create WebSocket:', error);
			this.onError(new Event('error'));
		}
	}

	private handleMessage = (event: MessageEvent): void => {
		try {
			const data = this.codec.decode(event.data) as GatewayPayload;
			this.onMessage(data);
		} catch (error) {
			this.logger.error('Failed to decode message:', error);
			this.disconnect(GatewayCloseCodes.DECODE_ERROR, 'Message decode error');
		}
	};

	private handleClose = (event: CloseEvent): void => {
		this.cleanup();
		this.onClose(event);
	};

	private handleError = (event: Event): void => {
		this.logger.error('WebSocket error:', event);
		this.onError(event);
	};

	send(data: GatewayPayload): boolean {
		if (!this.isConnected()) {
			this.logger.warn('Attempted to send message while disconnected');
			return false;
		}

		try {
			const payload = this.codec.encode(data);
			this.websocket!.send(payload);
			this.logger.debug('~>', data);
			return true;
		} catch (error) {
			this.logger.error('Failed to send message:', error);
			return false;
		}
	}

	disconnect(code = 1000, reason = 'Client disconnecting'): void {
		if (this.websocket?.readyState === WebSocket.OPEN) {
			try {
				this.websocket.close(code, reason);
				this.logger.debug(`Disconnecting [${code}] ${reason}`);
			} catch (error) {
				this.logger.error('Error closing WebSocket:', error);
			}
		}
		this.cleanup();
	}

	cleanup(): void {
		if (this.websocket) {
			this.websocket.removeEventListener('open', this.onOpen);
			this.websocket.removeEventListener('message', this.handleMessage);
			this.websocket.removeEventListener('close', this.handleClose);
			this.websocket.removeEventListener('error', this.handleError);
			this.websocket = null;
		}
	}

	isConnected(): boolean {
		return this.websocket?.readyState === WebSocket.OPEN;
	}
}

export class GatewaySocket extends EventEmitter<GatewaySocketEvents> {
	private readonly logger: Logger;
	private readonly session: SessionManager;
	private readonly connection: ConnectionManager;
	private readonly heartbeat: HeartbeatManager;
	private state = GatewayState.Offline;
	private isReconnecting = false;
	private readonly stateHandlers: Map<GatewayState, StateHandler>;
	private helloTimeout: number | null = null;
	private nextReconnectIsImmediate = false;
	private readonly codecType: CodecType;

	constructor(
		private readonly url: string,
		private readonly options: GatewaySocketOptions,
	) {
		super();
		this.logger = new Logger('Gateway');
		this.codecType = options.codec || 'json';
		this.session = new SessionManager();
		this.connection = new ConnectionManager(
			this.handleMessage,
			this.handleClose,
			this.handleError,
			this.handleOpen,
			this.codecType,
		);
		this.heartbeat = new HeartbeatManager(
			(payload) => this.connection.send(payload),
			() => this.dispatch(GatewayAction.HeartbeatTimeout),
			() => this.session.getSequence(),
			(sequence) => this.emit('heartbeat', sequence),
		);

		this.stateHandlers = new Map([
			[GatewayState.Offline, new OfflineHandler()],
			[GatewayState.Connecting, new ConnectingHandler(() => this.handleConnectionFailure())],
			[GatewayState.WaitingForHello, new WaitingForHelloHandler(() => this.canResume())],
			[GatewayState.Identifying, new IdentifyingHandler()],
			[GatewayState.Resuming, new ResumingHandler()],
			[
				GatewayState.Online,
				new OnlineHandler(
					() => this.canResume(),
					() => this.resetAndIdentify(),
				),
			],
			[GatewayState.Reconnecting, new ReconnectingHandler(() => this.resetAndRetry())],
		]);
	}

	private handleConnectionFailure(): void {
		this.logger.warn('Connection attempt failed, scheduling retry');
		this.scheduleReconnect();
	}

	private resetAndRetry(): void {
		this.logger.info('Max retries reached, resetting backoff and continuing');
		this.session.resetBackoff();
		this.dispatch(GatewayAction.Start);
	}

	private resetAndIdentify(): void {
		this.logger.info('Resetting session and starting fresh identification');
		this.performReset();
		this.connect();
	}

	private canResume(): boolean {
		const now = Date.now();
		const sessionId = this.session.getSessionId();
		const lastHeartbeatAck = this.heartbeat.getLastAckTime();
		const lastHeartbeat = this.heartbeat.getLastHeartbeatTime();

		if (!sessionId) {
			this.logger.debug('Cannot resume: no session ID');
			return false;
		}

		if (lastHeartbeatAck != null) {
			const canResume = now - lastHeartbeatAck <= GatewayTimeouts.ResumeWindow;
			if (!canResume) {
				this.logger.debug('Cannot resume: last heartbeat ack too old');
			}
			return canResume;
		}

		if (lastHeartbeat != null) {
			const canResume = now - lastHeartbeat <= GatewayTimeouts.ResumeWindow;
			if (!canResume) {
				this.logger.debug('Cannot resume: last heartbeat too old');
			}
			return canResume;
		}

		return true;
	}

	private dispatch(action: GatewayAction, data?: unknown): void {
		const handler = this.stateHandlers.get(this.state);
		if (!handler) {
			this.logger.error(`No handler for state: ${this.state}`);
			return;
		}

		const result = handler.handle(action, data);
		if (result.newState !== this.state) {
			const oldState = this.state;
			this.state = result.newState;
			this.logger.info(`State transition: ${oldState} -> ${this.state} (${action})`);
			this.emit('stateChange', this.state, oldState);

			if (result.action) {
				result.action();
			}

			this.handleStateTransition(result.newState, action);
		}
	}

	private handleStateTransition(newState: GatewayState, action: GatewayAction): void {
		switch (newState) {
			case GatewayState.Connecting:
				this.startConnection();
				break;
			case GatewayState.Identifying:
				this.identify();
				break;
			case GatewayState.Resuming:
				this.resume();
				break;
			case GatewayState.Reconnecting:
				this.scheduleReconnect();
				break;
			case GatewayState.Offline:
				if (action === GatewayAction.Reset) {
					this.performReset();
				}
				break;
		}
	}

	private startConnection(): void {
		const gatewayUrl = this.buildGatewayUrl();
		this.connection.connect(gatewayUrl);
		this.startHelloTimeout();
		this.emit('connecting');
	}

	private identify(): void {
		this.connection.send({
			op: GatewayOpCodes.IDENTIFY,
			d: {
				token: this.options.token,
				properties: this.options.properties,
			},
		});
	}

	private resume(): void {
		const sessionId = this.session.getSessionId();
		if (!sessionId) {
			this.logger.warn('Attempted to resume without session ID');
			this.dispatch(GatewayAction.InvalidSession);
			return;
		}

		this.connection.send({
			op: GatewayOpCodes.RESUME,
			d: {
				token: this.options.token,
				session_id: sessionId,
				seq: this.session.getSequence(),
			},
		});
	}

	private scheduleReconnect(): void {
		if (this.isReconnecting) return;

		this.isReconnecting = true;

		if (this.session.hasExceededMaxRetries()) {
			this.logger.warn('Max retries exceeded, resetting backoff');
			this.dispatch(GatewayAction.MaxRetries);
			return;
		}

		const delay = this.nextReconnectIsImmediate ? 0 : this.session.getNextReconnectDelay();
		this.nextReconnectIsImmediate = false;

		this.logger.info(`Scheduling reconnect in ${delay}ms`);

		setTimeout(() => {
			this.isReconnecting = false;
			if (!this.canResume()) {
				this.logger.info('Session not resumable, starting fresh connection');
				this.performReset();
			}
			this.dispatch(GatewayAction.Retry);
		}, delay);
	}

	private performReset(): void {
		this.clearHelloTimeout();
		this.heartbeat.stop();
		this.session.reset();
		this.connection.cleanup();
		this.logger.info('Gateway reset completed');
	}

	private handleOpen = (): void => {
		this.logger.info('WebSocket connected');
		this.dispatch(GatewayAction.SocketOpen);
		this.emit('connected');
	};

	private handleClose = (event: CloseEvent): void => {
		this.logger.warn(`WebSocket closed: [${event.code}] ${event.reason}`);
		this.clearHelloTimeout();

		if (event.code === GatewayCloseCodes.AUTHENTICATION_FAILED) {
			this.heartbeat.stop();
		}

		this.emit('disconnect', {
			code: event.code,
			reason: event.reason,
			wasClean: event.wasClean,
		});
		this.dispatch(GatewayAction.SocketClose, event);
	};

	private handleError = (event: Event): void => {
		this.logger.error('WebSocket error:', event);
		this.emit('error', event);
		this.dispatch(GatewayAction.SocketError, event);
	};

	private handleMessage = (payload: GatewayPayload): void => {
		this.logger.debug('<~', payload);

		if (this.state === GatewayState.Online && payload.op === GatewayOpCodes.DISPATCH) {
			this.session.handleDispatch(payload.s);
		}

		switch (payload.op) {
			case GatewayOpCodes.DISPATCH:
				this.handleDispatch(payload);
				break;
			case GatewayOpCodes.HEARTBEAT:
				this.logger.info('Server requested heartbeat');
				this.heartbeat.sendNow();
				break;
			case GatewayOpCodes.HEARTBEAT_ACK:
				this.heartbeat.handleAck();
				this.emit('heartbeatAck');
				break;
			case GatewayOpCodes.HELLO: {
				this.clearHelloTimeout();
				const helloData = payload.d as {hbi: number};
				this.heartbeat.start(helloData.hbi);
				this.dispatch(GatewayAction.HelloReceived);
				break;
			}
			case GatewayOpCodes.INVALID_SESSION:
				this.dispatch(GatewayAction.InvalidSession, payload.d as boolean);
				break;
			case GatewayOpCodes.RECONNECT:
				this.logger.info('Server requested reconnect');
				this.disconnect(4000, 'Server requested reconnect');
				break;
		}

		this.emit('message', payload);
	};

	private handleDispatch(payload: GatewayPayload): void {
		if (!payload.t) return;

		switch (payload.t) {
			case 'READY': {
				const sessionId = (payload.d as {session_id: string}).session_id;
				this.session.startNewSession(sessionId);
				this.session.resetBackoff();
				this.dispatch(GatewayAction.Ready);
				this.emit('ready', payload.d);
				break;
			}
			case 'RESUMED':
				this.session.resetBackoff();
				this.dispatch(GatewayAction.Resumed);
				this.emit('resumed');
				break;
		}

		this.emit('dispatch', payload.t, payload.d);
	}

	private startHelloTimeout(): void {
		this.clearHelloTimeout();
		this.helloTimeout = window.setTimeout(() => {
			this.logger.warn('Hello timeout - no HELLO received');
			this.disconnect(4000, 'Hello timeout');
		}, GatewayTimeouts.Hello);
	}

	private clearHelloTimeout(): void {
		if (this.helloTimeout != null) {
			clearTimeout(this.helloTimeout);
			this.helloTimeout = null;
		}
	}

	private buildGatewayUrl(): string {
		const gatewayUrl = new URL(this.url);
		gatewayUrl.searchParams.set('v', this.options.apiVersion.toString());
		gatewayUrl.searchParams.set('codec', this.codecType);
		return gatewayUrl.toString();
	}

	connect(): void {
		if (this.state === GatewayState.Offline) {
			this.dispatch(GatewayAction.Start);
		}
	}

	disconnect(code = 1000, reason = 'Client disconnecting', resumable = true): void {
		this.dispatch(GatewayAction.Stop, resumable);
		if (!resumable) {
			this.heartbeat.stop();
		}
		this.connection.disconnect(code, reason);
	}

	reset(reconnect = true): void {
		this.dispatch(GatewayAction.Reset);
		if (reconnect) {
			this.nextReconnectIsImmediate = true;
			this.dispatch(GatewayAction.Start);
		}
	}

	handleNetworkStatusChange(online: boolean): void {
		this.logger.info(`Network status changed - Online: ${online}`);
		this.emit('networkStatusChange', online);

		if (online) {
			if (this.state === GatewayState.Offline) {
				this.connect();
			}
		} else if (this.state === GatewayState.Online) {
			this.disconnect(1000, 'Network offline', true);
		}
	}

	updatePresence(status: StatusType): void {
		if (this.state === GatewayState.Online) {
			this.connection.send({
				op: GatewayOpCodes.PRESENCE_UPDATE,
				d: {status},
			});
		}
	}

	setToken(token: string): void {
		this.options.token = token;
	}

	getState(): GatewayState {
		return this.state;
	}

	getSessionId(): string | null {
		return this.session.getSessionId();
	}

	getSequence(): number {
		return this.session.getSequence();
	}

	isConnected(): boolean {
		return this.state === GatewayState.Online && this.connection.isConnected();
	}

	isConnecting(): boolean {
		return [
			GatewayState.Connecting,
			GatewayState.WaitingForHello,
			GatewayState.Identifying,
			GatewayState.Resuming,
		].includes(this.state);
	}
}
