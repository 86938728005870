import EventEmitter from 'eventemitter3';

export type ComponentActionType =
	| 'CHANNEL_PINS_OPEN'
	| 'EMOJI_PICKER_RERENDER'
	| 'EMOJI_SELECT'
	| 'GIF_SELECT'
	| 'MESSAGE_JUMP'
	| 'POPOUT_CLOSE'
	| 'SAVED_MESSAGES_OPEN'
	| 'SCROLL_TO_BOTTOM';

class Dispatch extends EventEmitter {
	private _savedDispatches: {[key: string]: Array<any>} = {};

	safeDispatch(type: ComponentActionType, args?: object) {
		if (!this.hasSubscribers(type)) {
			if (!this._savedDispatches[type]) {
				this._savedDispatches[type] = [];
			}
			this._savedDispatches[type].push(args);
			return;
		}
		this.dispatch(type, args);
	}

	dispatch(type: ComponentActionType, args?: object) {
		this.emit(type, args);
	}

	dispatchToLastSubscribed(type: ComponentActionType, args?: object) {
		const listeners = this.listeners(type);
		if (listeners.length > 0) {
			listeners[listeners.length - 1](args);
		}
	}

	dispatchToFirst(types: Array<ComponentActionType>, args?: object) {
		for (const type of types) {
			if (this.hasSubscribers(type)) {
				this.dispatch(type, args);
				break;
			}
		}
	}

	hasSubscribers(type: ComponentActionType) {
		return this.listenerCount(type) > 0;
	}

	private _checkSavedDispatches(type: ComponentActionType) {
		if (this._savedDispatches[type]) {
			for (const args of this._savedDispatches[type]) {
				this.dispatch(type, args);
			}
			delete this._savedDispatches[type];
		}
	}

	subscribe(type: ComponentActionType, callback: (...args: Array<any>) => void): () => void {
		if (this.listeners(type).includes(callback)) {
			console.warn('ComponentDispatch.subscribe: Attempting to add a duplicate listener', type);
			return () => {
				this.unsubscribe(type, callback);
			};
		}
		this.on(type, callback);
		this._checkSavedDispatches(type);
		return () => {
			this.unsubscribe(type, callback);
		};
	}

	subscribeOnce(type: ComponentActionType, callback: (...args: Array<any>) => void): () => void {
		this.once(type, callback);
		this._checkSavedDispatches(type);
		return () => {
			this.unsubscribe(type, callback);
		};
	}

	unsubscribe(type: ComponentActionType, callback: (...args: Array<any>) => void) {
		this.removeListener(type, callback);
	}

	reset() {
		this.removeAllListeners();
	}
}

export const ComponentDispatch = new Dispatch();
