import React from 'react';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import * as ToastActionCreators from '~/actions/ToastActionCreators';
import type {UserAvatarDecoration} from '~/actions/UserActionCreators';
import * as UserActionCreators from '~/actions/UserActionCreators';
import * as Modal from '~/components/modals/Modal';
import {Avatar} from '~/components/uikit/Avatar';
import {Button} from '~/components/uikit/Button/Button';
import {ScrollArea} from '~/components/uikit/ScrollArea';
import {i18n} from '~/i18n';
import PresenceStore from '~/stores/PresenceStore';
import UserStore from '~/stores/UserStore';
import * as AvatarUtils from '~/utils/AvatarUtils';

export const UserAvatarDecorationModal = () => {
	const [decorations, setDecorations] = React.useState<Array<UserAvatarDecoration>>([]);
	const [selectedDecorationId, setSelectedDecorationId] = React.useState<string | null>(null);
	const [isLoading, setIsLoading] = React.useState(true);
	const user = UserStore.useCurrentUser();
	const status = PresenceStore.useUserStatus(user?.id ?? '');

	React.useEffect(() => {
		const fetchDecorations = async () => {
			try {
				const availableDecorations = await UserActionCreators.getUserAvatarDecorations();
				setDecorations(availableDecorations);
			} catch (error) {
				console.error('Failed to fetch avatar decorations:', error);
				ToastActionCreators.createToast({type: 'error', children: 'Failed to fetch avatar decorations'});
			} finally {
				setIsLoading(false);
			}
		};

		fetchDecorations();
	}, []);

	const handleDecorationSelect = async (decorationId: string | null) => {
		try {
			if (decorationId === null) {
				await UserActionCreators.unequipUserAvatarDecoration();
				ToastActionCreators.createToast({type: 'success', children: 'Avatar Decoration removed'});
			} else {
				await UserActionCreators.equipUserAvatarDecoration(decorationId);
				ToastActionCreators.createToast({type: 'success', children: 'Avatar Decoration equipped'});
			}
			setSelectedDecorationId(decorationId);
		} catch (error) {
			console.error('Failed to update avatar decoration:', error);
			ToastActionCreators.createToast({type: 'error', children: 'Failed to update avatar decoration'});
		}
	};

	if (!user) return null;

	return (
		<Modal.Root label={i18n.Messages.CHOOSE_AVATAR_DECORATION} size="large">
			<Modal.Header title={i18n.Messages.CHOOSE_AVATAR_DECORATION} />
			<div className="flex h-[600px] flex-1 min-h-0">
				<div className="flex-[3_3_0%] min-h-0 border-r border-background-header-secondary">
					<ScrollArea className="h-full">
						<div className="p-4">
							{isLoading ? (
								<div className="flex h-full items-center justify-center">
									<span className="text-text-primary-muted">Loading avatar decorations...</span>
								</div>
							) : (
								<div className="grid grid-cols-4 gap-4">
									{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
									<div
										className={`flex h-24 w-24 cursor-pointer items-center justify-center rounded-lg border-2 p-2 transition-all hover:bg-background-modifier-hover ${
											selectedDecorationId === null ? 'border-brand-primary' : 'border-transparent'
										}`}
										onClick={() => handleDecorationSelect(null)}
									>
										<span className="text-center text-sm text-text-primary-muted">No Decoration</span>
									</div>
									{decorations.map((decoration) => (
										// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
										<div
											key={decoration.id}
											className={`flex h-24 w-24 cursor-pointer flex-col items-center justify-center rounded-lg border-2 p-2 transition-all hover:bg-background-modifier-hover ${
												selectedDecorationId === decoration.id ? 'border-brand-primary' : 'border-transparent'
											}`}
											onClick={() => handleDecorationSelect(decoration.id)}
										>
											<img
												src={AvatarUtils.getUserAvatarDecorationURL({icon: decoration.asset})}
												alt={decoration.name}
												className="h-16 w-16 object-contain"
											/>
											<span className="mt-1 text-center text-xs text-text-primary-muted">{decoration.name}</span>
										</div>
									))}
								</div>
							)}
						</div>
					</ScrollArea>
				</div>

				<div className="flex-1 flex flex-col items-center justify-center p-8">
					<div className="text-sm text-text-primary-muted mb-4">Preview</div>
					<div className="relative" style={{width: 120, height: 120}}>
						<Avatar
							user={user}
							size={120}
							status={status}
							forceAnimate={true}
							decorationIcon={
								selectedDecorationId
									? decorations.find((decoration) => decoration.id === selectedDecorationId)?.asset
									: undefined
							}
							className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
						/>
					</div>
				</div>
			</div>
			<Modal.Footer>
				<div className="flex justify-end gap-3">
					<Button onClick={ModalActionCreators.pop} variant="ghost">
						{i18n.Messages.CANCEL}
					</Button>
					<Button onClick={ModalActionCreators.pop} variant="brand">
						{i18n.Messages.DONE}
					</Button>
				</div>
			</Modal.Footer>
		</Modal.Root>
	);
};
