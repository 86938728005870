import defaultAvatar0 from '~/images/default_avatar_0.webp';
import defaultAvatar1 from '~/images/default_avatar_1.webp';
import defaultAvatar2 from '~/images/default_avatar_2.webp';
import defaultAvatar3 from '~/images/default_avatar_3.webp';
import defaultAvatar4 from '~/images/default_avatar_4.webp';
import defaultAvatar5 from '~/images/default_avatar_5.webp';
import type {UserRecord} from '~/records/UserRecord';
import DeveloperOptionsStore from '~/stores/DeveloperOptionsStore';

const DEFAULT_AVATARS = [
	defaultAvatar0,
	defaultAvatar1,
	defaultAvatar2,
	defaultAvatar3,
	defaultAvatar4,
	defaultAvatar5,
] as const;

type AvatarOptions = Pick<UserRecord, 'id' | 'avatar'>;

type IconOptions = {
	id: string;
	icon: string | null;
};

export const getAvatarURL = ({
	path,
	id,
	hash,
	size,
	format,
}: {
	path: string;
	id: string;
	hash: string;
	size?: number;
	format: string;
}) => {
	if (DeveloperOptionsStore.getForceRenderPlaceholders()) {
		return '';
	}

	let url = `${window.GLOBAL_ENV.MEDIA_PROXY_ENDPOINT}/${path}/${id}/${hash}`;
	url += `.${format}`;
	if (size) {
		url += `?size=${size}`;
	}

	return url;
};

export const parseAvatar = (avatar: string) => {
	const parts = avatar.split(':');
	const animated = parts[0] === 'a';
	const baseIndex = animated ? 1 : 0;
	return {
		animated,
		hash: parts[baseIndex],
		placeholder: parts[baseIndex + 1],
		placeholder_version: Number(parts[baseIndex + 2]),
	};
};

export const getUserAvatarURL = ({id, avatar}: AvatarOptions, animated = false) => {
	if (!avatar) {
		const index = Number(BigInt(id) % BigInt(DEFAULT_AVATARS.length));
		return DEFAULT_AVATARS[index];
	}

	const parsedAvatar = parseAvatar(avatar);
	const shouldAnimate = parsedAvatar.animated ? animated : false;

	return getAvatarURL({
		path: 'avatars',
		id,
		hash: parsedAvatar.hash,
		size: 160,
		format: shouldAnimate ? 'gif' : 'webp',
	});
};

export const getUserAvatarDecorationURL = ({icon, size = 240}: {icon: string; size?: 240}) => {
	return `${window.GLOBAL_ENV.MEDIA_PROXY_ENDPOINT}/avatar-decorations/${icon}.png?quality=high&format=webp&size=${size}`;
};

export const getGuildIconURL = ({id, icon}: IconOptions, animated = false) => {
	if (!icon) {
		return null;
	}

	const parsedAvatar = parseAvatar(icon);
	const shouldAnimate = parsedAvatar.animated ? animated : false;

	return getAvatarURL({
		path: 'icons',
		id,
		hash: parsedAvatar.hash,
		size: 160,
		format: shouldAnimate ? 'gif' : 'webp',
	});
};

export const getEmojiURL = ({id, animated}: {id: string; animated?: boolean}) => {
	if (DeveloperOptionsStore.getForceRenderPlaceholders()) {
		return '';
	}
	const url = `${window.GLOBAL_ENV.MEDIA_PROXY_ENDPOINT}/emojis/${id}`;
	return `${url}.${animated ? 'gif' : 'webp'}`;
};

export const loadImage = (file: File) =>
	new Promise<HTMLImageElement>((resolve, reject) => {
		const reader = new FileReader();
		const img = new Image();

		reader.addEventListener('load', (event) => {
			img.addEventListener('load', () => resolve(img));
			img.addEventListener('error', reject);
			img.src = event.target?.result as string;
		});

		reader.addEventListener('error', reject);
		reader.readAsDataURL(file);
	});

export const resizeImage = (img: HTMLImageElement, maxWidth: number, maxHeight: number) => {
	const canvas = document.createElement('canvas');
	let width = img.width;
	let height = img.height;

	if (width > height) {
		if (width > maxWidth) {
			height *= maxWidth / width;
			width = maxWidth;
		}
	} else if (height > maxHeight) {
		width *= maxHeight / height;
		height = maxHeight;
	}

	if (width > maxWidth) {
		height *= maxWidth / width;
		width = maxWidth;
	}

	canvas.width = width;
	canvas.height = height;
	const ctx = canvas.getContext('2d');
	ctx?.drawImage(img, 0, 0, width, height);

	return canvas;
};

export const fileToBase64 = async (file: File, maxWidth = 1024, maxHeight = 1024) => {
	const img = await loadImage(file);
	if (file.type === 'image/gif') {
		return img.src;
	}

	const canvas = resizeImage(img, maxWidth, maxHeight);
	return canvas.toDataURL(file.type);
};
