import {type StatusType, StatusTypes, type ThemeType, ThemeTypes} from '~/Constants';
import type {Action} from '~/flux/ActionTypes';
import {PersistedStore} from '~/flux/PersistedStore';

export type FavoriteGif = Readonly<{
	url: string;
	src: string;
	proxy_src: string;
	width: number;
	height: number;
}>;

export type GuildFolder = Readonly<{
	id: number | null;
	name: string | null;
	color: number | null;
	guild_ids: ReadonlyArray<string>;
}>;

export type CustomStatus = Readonly<{
	text: string | null;
	expires_at: number | null;
	emoji_id: string | null;
	emoji_name: string | null;
}>;

export type UserSettings = Readonly<{
	status: StatusType;
	theme: ThemeType;
	custom_css: string | null;
	guild_positions: ReadonlyArray<string>;
	favorite_gifs: ReadonlyArray<FavoriteGif>;
	locale: string;
	restricted_guilds: ReadonlyArray<string>;
	default_guilds_restricted: boolean;
	inline_attachment_media: boolean;
	inline_embed_media: boolean;
	gif_auto_play: boolean;
	render_embeds: boolean;
	render_reactions: boolean;
	animate_emoji: boolean;
	message_display_compact: boolean;
	friend_source_flags: number;
	guild_folders: ReadonlyArray<GuildFolder>;
	custom_status: CustomStatus | null;
}>;

const initialState: UserSettings = Object.freeze({
	status: StatusTypes.ONLINE,
	theme: ThemeTypes.DARK,
	custom_css: null,
	guild_positions: [],
	favorite_gifs: [],
	locale: 'en_US',
	restricted_guilds: [],
	default_guilds_restricted: false,
	inline_attachment_media: true,
	inline_embed_media: true,
	gif_auto_play: true,
	render_embeds: true,
	render_reactions: true,
	animate_emoji: true,
	message_display_compact: false,
	friend_source_flags: 0,
	guild_folders: [],
	custom_status: null,
}) as UserSettings;

const createGetter = <K extends keyof UserSettings>(key: K) =>
	function (this: UserSettingsStore): UserSettings[K] {
		return this.state[key];
	};

class UserSettingsStore extends PersistedStore<UserSettings> {
	constructor() {
		super(initialState, 'UserSettingsStore', 1, ['theme', 'custom_css']);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'CONNECTION_OPEN':
			case 'USER_SETTINGS_UPDATE':
				return this.handleUserSettingsUpdate(action);
			default:
				return false;
		}
	}

	getStatus = createGetter('status');
	getTheme = createGetter('theme');
	getCustomCSS = createGetter('custom_css');
	getGuildPositions = createGetter('guild_positions');
	getFavoriteGifs = createGetter('favorite_gifs');
	getLocale = createGetter('locale');
	getRestrictedGuilds = createGetter('restricted_guilds');
	getDefaultGuildsRestricted = createGetter('default_guilds_restricted');
	getInlineAttachmentMedia = createGetter('inline_attachment_media');
	getInlineEmbedMedia = createGetter('inline_embed_media');
	getGifAutoPlay = createGetter('gif_auto_play');
	getRenderEmbeds = createGetter('render_embeds');
	getRenderReactions = createGetter('render_reactions');
	getAnimateEmoji = createGetter('animate_emoji');
	getMessageDisplayCompact = createGetter('message_display_compact');
	getFriendSourceFlags = createGetter('friend_source_flags');
	getGuildFolders = createGetter('guild_folders');
	getCustomStatus = createGetter('custom_status');

	useSettings<K extends keyof UserSettings>(key: K): UserSettings[K] {
		const state = this.useStore();
		return state[key];
	}

	private handleUserSettingsUpdate({
		userSettings,
	}: {
		userSettings: Readonly<UserSettings>;
	}): boolean {
		const normalizedSettings: UserSettings = {
			...userSettings,
			guild_positions: [...userSettings.guild_positions],
			favorite_gifs: userSettings.favorite_gifs.map((gif) => ({...gif})),
			restricted_guilds: [...userSettings.restricted_guilds],
			guild_folders: userSettings.guild_folders.map((folder) => ({
				...folder,
				guild_ids: [...folder.guild_ids],
			})),
			custom_status: userSettings.custom_status ? {...userSettings.custom_status} : null,
		};

		this.setState(() => normalizedSettings);
		return true;
	}
}

export default new UserSettingsStore();
