import {parse_fluxer_markdown} from '~/wasm/fluxer_markdown_rs';

export type Node =
	| {type: 'Text'; content: string}
	| {type: 'BlockQuote'; content: Array<Node>}
	| {type: 'Strong'; content: Array<Node>}
	| {type: 'Emphasis'; content: Array<Node>}
	| {type: 'Underline'; content: Array<Node>}
	| {type: 'Strikethrough'; content: Array<Node>}
	| {type: 'Spoiler'; content: Array<Node>}
	| {type: 'Heading'; content: {level: number; children: Array<Node>}}
	| {type: 'List'; content: {ordered: boolean; items: Array<ListItem>}}
	| {type: 'CodeBlock'; content: {language: string | null; content: string}}
	| {type: 'InlineCode'; content: string}
	| {type: 'Link'; content: {text: Node | null; url: string; escaped: boolean}}
	| {type: 'Mention'; content: MentionType}
	| {type: 'Timestamp'; content: {timestamp: number; style: TimestampStyle}}
	| {type: 'Emoji'; content: EmojiType};

export type MentionType =
	| {kind: 'User'; data: string}
	| {kind: 'Channel'; data: string}
	| {kind: 'Role'; data: string}
	| {
			kind: 'Command';
			data: {
				name: string;
				subcommand_group: string | null;
				subcommand: string | null;
				id: string;
			};
	  }
	| {
			kind: 'GuildNavigation';
			data: {
				kind: GuildNavKind;
			};
	  }
	| {kind: 'Everyone'}
	| {kind: 'Here'};

export type GuildNavKind =
	| {type: 'Customize'}
	| {type: 'Browse'}
	| {type: 'Guide'}
	| {type: 'LinkedRoles'; data: string | null};

export type EmojiType =
	| {
			type: 'Standard';
			data: {
				raw: string;
				codepoints: string;
			};
	  }
	| {
			type: 'Custom';
			data: {
				name: string;
				id: string;
				animated: boolean;
			};
	  };

export type ListItem = {
	children: Array<Node>;
};

export enum TimestampStyle {
	ShortTime = 'ShortTime',
	LongTime = 'LongTime',
	ShortDate = 'ShortDate',
	LongDate = 'LongDate',
	ShortDateTime = 'ShortDateTime',
	LongDateTime = 'LongDateTime',
	RelativeTime = 'RelativeTime',
}

export const timestyleFromChar = (c: string): TimestampStyle | null => {
	switch (c) {
		case 't':
			return TimestampStyle.ShortTime;
		case 'T':
			return TimestampStyle.LongTime;
		case 'd':
			return TimestampStyle.ShortDate;
		case 'D':
			return TimestampStyle.LongDate;
		case 'f':
			return TimestampStyle.ShortDateTime;
		case 'F':
			return TimestampStyle.LongDateTime;
		case 'R':
			return TimestampStyle.RelativeTime;
		default:
			return null;
	}
};

export type ParseResult = {
	nodes: Array<Node>;
};

export enum ParserFlags {
	ALLOW_SPOILERS = 1 << 0,
	ALLOW_HEADINGS = 1 << 1,
	ALLOW_LISTS = 1 << 2,
	ALLOW_CODE_BLOCKS = 1 << 3,
	ALLOW_MASKED_LINKS = 1 << 4,
	ALLOW_COMMAND_MENTIONS = 1 << 5,
	ALLOW_GUILD_NAVIGATIONS = 1 << 6,
	ALLOW_USER_MENTIONS = 1 << 7,
	ALLOW_ROLE_MENTIONS = 1 << 8,
	ALLOW_CHANNEL_MENTIONS = 1 << 9,
	ALLOW_EVERYONE_MENTIONS = 1 << 10,
	ALLOW_HERE_MENTIONS = 1 << 11,
}

export const parseFluxerMarkdown = (input: string, flags: number): Array<Node> => {
	const result = parse_fluxer_markdown(input, flags);
	return result.nodes;
};
