import {type FC, useEffect, useRef} from 'react';
import * as Modal from '~/components/modals/Modal';
import {i18n} from '~/i18n';
import {createCalculator} from '~/utils/DimensionUtils';

const PREVIEW_CONFIG = {
	MAX_DIMENSION: 712,
	ASPECT_RATIO_MULTIPLIER: 1,
} as const;

const previewCalculator = createCalculator({
	maxWidth: PREVIEW_CONFIG.MAX_DIMENSION,
	maxHeight: PREVIEW_CONFIG.MAX_DIMENSION,
	responsive: true,
});

type MediaType = 'image' | 'gif' | 'gifv';

type MediaPreviewModalProps = {
	src: string;
	originalSrc: string;
	naturalWidth: number;
	naturalHeight: number;
	type: MediaType;
};

type VideoPlayerProps = {
	src: string;
	width: number;
	height: number;
	videoRef: React.RefObject<HTMLVideoElement>;
};

type ImageViewerProps = {
	src: string;
	width: number;
	height: number;
	alt?: string;
};

const VideoPlayer: FC<VideoPlayerProps> = ({src, width, height, videoRef}) => (
	<video
		ref={videoRef}
		className="h-full w-full object-contain"
		controls={false}
		playsInline={true}
		loop={true}
		muted={true}
		autoPlay={true}
		src={src}
		style={{width, height}}
		aria-label="Animated GIF Video"
	/>
);

const ImageViewer: FC<ImageViewerProps> = ({src, width, height, alt = ''}) => (
	<img alt={alt} src={src} style={{width, height}} className="h-full w-full object-contain" />
);

const OpenInBrowserLink: FC<{href: string}> = ({href}) => (
	<a
		className="text-sm text-text-primary-muted transition-colors duration-200 ease-in-out hover:text-text-primary hover:underline"
		href={href}
		rel="noreferrer noopener"
		target="_blank"
		role="button"
		tabIndex={0}
	>
		{i18n.Messages.OPEN_IN_BROWSER}
	</a>
);

export const ImagePreviewModal: FC<MediaPreviewModalProps> = ({
	src,
	originalSrc,
	naturalWidth,
	naturalHeight,
	type,
}) => {
	const videoRef = useRef<HTMLVideoElement>(null);

	const {dimensions: previewDimensions, style: containerStyle} = previewCalculator.calculate(
		{width: naturalWidth, height: naturalHeight},
		{
			forceScale: true,
			aspectRatio: true,
		},
	);

	useEffect(() => {
		if (type === 'gifv' && videoRef.current) {
			const playVideo = () => {
				const playPromise = videoRef.current?.play();
				if (playPromise) {
					playPromise.catch(() => {
						const handleClick = () => {
							videoRef.current?.play();
							document.removeEventListener('click', handleClick);
						};
						document.addEventListener('click', handleClick, {once: true});
					});
				}
			};

			playVideo();
			return () => {
				if (videoRef.current) {
					videoRef.current.pause();
				}
			};
		}
	}, [type]);

	const imageSrc = type === 'gif' ? `${src}?animated=true` : src;

	const contentStyle = {
		...containerStyle,
		aspectRatio: `${previewDimensions.width / previewDimensions.height} / ${PREVIEW_CONFIG.ASPECT_RATIO_MULTIPLIER}`,
	};

	return (
		<Modal.Root
			label={type === 'image' ? 'Image' : type === 'gifv' ? 'GIFV' : 'GIF'}
			className="flex items-center justify-center rounded-none bg-transparent"
		>
			<div className="relative">
				<div className="relative block select-text overflow-hidden" style={containerStyle}>
					<div className="h-full w-full" style={contentStyle}>
						{type === 'gifv' ? (
							<VideoPlayer
								src={src}
								width={previewDimensions.width}
								height={previewDimensions.height}
								videoRef={videoRef}
							/>
						) : (
							<ImageViewer src={imageSrc} width={previewDimensions.width} height={previewDimensions.height} />
						)}
					</div>
				</div>

				<div className="absolute top-full mt-[4px] flex w-full flex-row flex-wrap items-center justify-between gap-[4px]">
					<OpenInBrowserLink href={originalSrc} />
				</div>
			</div>
		</Modal.Root>
	);
};
