import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import {type GuildMember, GuildMemberRecord} from '~/records/GuildMemberRecord';
import type {GuildReadyData} from '~/records/GuildRecord';

type Members = Record<string, GuildMemberRecord>;

type State = {
	members: Record<string, Members>;
};

const initialState: State = {
	members: {},
};

class GuildMemberStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action) {
		switch (action.type) {
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen(action);
			case 'GUILD_CREATE':
				return this.handleGuildCreate(action.guild);
			case 'GUILD_DELETE':
				return this.handleGuildDelete(action);
			case 'GUILD_MEMBER_ADD':
			case 'GUILD_MEMBER_UPDATE':
				return this.handleMemberAdd(action);
			case 'GUILD_MEMBER_REMOVE':
				return this.handleMemberRemove(action);
			case 'GUILD_ROLE_DELETE':
				return this.handleGuildRoleDelete(action);
			default:
				return false;
		}
	}

	getMember(guildId: string, userId: string): GuildMemberRecord | null {
		return this.state.members[guildId]?.[userId] ?? null;
	}

	getMembers(guildId: string): Array<GuildMemberRecord> {
		return Object.values(this.state.members[guildId] ?? {});
	}

	getMemberCount(guildId: string): number {
		return Object.keys(this.state.members[guildId] ?? {}).length;
	}

	useMembers(guildId: string): Array<GuildMemberRecord> {
		const {members} = this.useStore();
		return Object.values(members[guildId] ?? {});
	}

	private handleConnectionOpen({guilds}: {guilds: Array<GuildReadyData>}) {
		this.setState(initialState);
		for (const guild of guilds) {
			this.handleGuildCreate(guild);
		}
	}

	private handleGuildCreate(guild: GuildReadyData) {
		if (guild.unavailable) {
			return;
		}

		this.setState((prevState) => ({
			members: {
				...prevState.members,
				[guild.id]: guild.members.reduce((acc, member) => {
					acc[member.user.id] = new GuildMemberRecord(guild.id, member);
					return acc;
				}, {} as Members),
			},
		}));
	}

	private handleGuildDelete({guildId}: {guildId: string}) {
		this.setState((prevState) => ({
			members: Object.fromEntries(Object.entries(prevState.members).filter(([id]) => id !== guildId)),
		}));
	}

	private handleMemberAdd({guildId, member}: {guildId: string; member: GuildMember}) {
		this.setState((prevState) => ({
			members: {
				...prevState.members,
				[guildId]: {
					...(prevState.members[guildId] ?? {}),
					[member.user.id]: new GuildMemberRecord(guildId, member),
				},
			},
		}));
	}

	private handleMemberRemove({guildId, userId}: {guildId: string; userId: string}) {
		this.setState((prevState) => {
			const existingMembers = prevState.members[guildId];
			if (!existingMembers) {
				return prevState;
			}

			const updatedGuildMembers = Object.fromEntries(Object.entries(existingMembers).filter(([id]) => id !== userId));

			return {
				members: {
					...prevState.members,
					...(Object.keys(updatedGuildMembers).length > 0 ? {[guildId]: updatedGuildMembers} : {}),
				},
			};
		});
	}

	private handleGuildRoleDelete({guildId, roleId}: {guildId: string; roleId: string}) {
		this.setState((prevState) => {
			const existingMembers = prevState.members[guildId];
			if (!existingMembers) {
				return prevState;
			}

			const updatedGuildMembers = Object.fromEntries(
				Object.entries(existingMembers).map(([memberId, member]) => {
					if (member.roles.has(roleId)) {
						const newRoles = new Set(member.roles);
						newRoles.delete(roleId);
						return [
							memberId,
							new GuildMemberRecord(guildId, {
								...member.toJSON(),
								roles: Array.from(newRoles),
							}),
						];
					}
					return [memberId, member];
				}),
			);

			return {
				members: {
					...prevState.members,
					[guildId]: updatedGuildMembers,
				},
			};
		});
	}
}

export default new GuildMemberStore();
