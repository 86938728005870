import {LruCache} from '~/lib/LruCache';

const imageUrlCache = new LruCache<string, boolean>(100);

export const loadImage = (src: string | null, onLoad: () => void, onError?: () => void) => {
	if (!src) {
		return;
	}
	if (imageUrlCache.has(src)) {
		onLoad();
		return;
	}

	const image = new Image();
	image.src = src;
	image.onload = () => {
		imageUrlCache.set(src, true);
		onLoad();
	};
	image.onerror = () => {
		onError?.();
	};
};

export const hasImage = (src: string | null) => (src ? imageUrlCache.has(src) : false);
