import {noop} from 'lodash';
import {ArrowLeftIcon, ArrowRight, Copy, Globe, Hash, Info} from 'lucide-react';
import {AnimatePresence, motion} from 'motion/react';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import * as TextCopyActionCreators from '~/actions/TextCopyActionCreators';
import * as ToastActionCreators from '~/actions/ToastActionCreators';
import * as UserActionCreators from '~/actions/UserActionCreators';
import {Form} from '~/components/form/Form';
import {Input} from '~/components/form/Input';
import * as Modal from '~/components/modals/Modal';
import {Button} from '~/components/uikit/Button/Button';
import {i18n} from '~/i18n';
import type {HttpResponse} from '~/lib/HttpClient';
import UserStore from '~/stores/UserStore';
import * as FormUtils from '~/utils/FormUtils';

type SetupMode = 'initial' | 'username' | 'domain';
type DomainSetupStep = 'input' | 'verification';

type FormInputs = {
	username: string;
	password: string;
	domain?: string;
};

const VALIDATION = {
	USERNAME: {
		MIN: 2,
		MAX: 32,
		PATTERN: /^[a-zA-Z0-9]+$/,
		DISALLOWED: new Set(['flux', 'flxr']),
	},
	PASSWORD: {
		MIN: 8,
		MAX: 128,
	},
	DOMAIN: {
		MIN: 2,
		MAX: 255,
		PATTERN: /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/,
	},
} as const;

const slideVariants = {
	enter: (direction: number) => ({
		x: direction > 0 ? 300 : -300,
		opacity: 0,
	}),
	center: {
		zIndex: 1,
		x: 0,
		opacity: 1,
	},
	exit: (direction: number) => ({
		zIndex: 0,
		x: direction < 0 ? 300 : -300,
		opacity: 0,
	}),
};

const validateUsername = (value: string): string | true => {
	if (!value) return 'Username is required';
	if (value.length < VALIDATION.USERNAME.MIN) return `Username must be at least ${VALIDATION.USERNAME.MIN} characters`;
	if (value.length > VALIDATION.USERNAME.MAX) return `Username must be at most ${VALIDATION.USERNAME.MAX} characters`;
	if (!VALIDATION.USERNAME.PATTERN.test(value)) return 'Username can only contain letters (a-z, A-Z) and digits (0-9)';
	if (VALIDATION.USERNAME.DISALLOWED.has(value.toLowerCase())) return 'Username contains disallowed words';
	return true;
};

const validatePassword = (value: string): string | true => {
	if (!value) return 'Password is required';
	if (value.length < VALIDATION.PASSWORD.MIN) return `Password must be at least ${VALIDATION.PASSWORD.MIN} characters`;
	if (value.length > VALIDATION.PASSWORD.MAX) return `Password must be at most ${VALIDATION.PASSWORD.MAX} characters`;
	return true;
};

const validateDomain = (value: string): string | true => {
	if (!value) return 'Domain is required';
	if (value.length < VALIDATION.DOMAIN.MIN) return `Domain must be at least ${VALIDATION.DOMAIN.MIN} characters`;
	if (value.length > VALIDATION.DOMAIN.MAX) return `Domain must be at most ${VALIDATION.DOMAIN.MAX} characters`;
	if (!VALIDATION.DOMAIN.PATTERN.test(value)) return 'Please enter a valid domain name';
	return true;
};

const DnsInputField = ({label, value, onCopy}: {label: string; value: string; onCopy: (value: string) => void}) => (
	<div className="flex flex-col gap-2">
		<span className="text-sm text-text-primary-muted">{label}</span>
		<div className="flex items-center gap-2">
			<div className="w-0 flex-1 truncate rounded-md border border-background-header-secondary bg-background-tertiary px-3 py-2 font-mono text-text-tertiary">
				{value}
			</div>
			<Button
				variant="ghost"
				small
				fitContent
				onClick={() => onCopy(value)}
				className="h-10 w-10"
				aria-label={`Copy ${label}`}
			>
				<Copy className="h-4 w-4" />
			</Button>
		</div>
	</div>
);

const DomainVerificationSection = ({
	domain,
	challenge,
	onCopy,
}: {
	domain: string;
	challenge: string;
	onCopy: (value: string) => void;
}) => (
	<div className="rounded-lg border border-background-header-secondary bg-background-tertiary p-6 shadow-sm">
		<div className="space-y-4">
			<div className="flex items-center gap-2">
				<Info className="h-5 w-5 text-text-primary" />
				<h4 className="font-medium text-text-primary">DNS Verification Required</h4>
			</div>

			<div className="space-y-4 rounded-lg border border-background-header-secondary bg-background-secondary p-4">
				<DnsInputField label="Name" value={`_fluxer.${domain}`} onCopy={onCopy} />
				<DnsInputField label="Type" value="TXT" onCopy={onCopy} />
				<DnsInputField label="Content" value={`dh=${challenge}`} onCopy={onCopy} />
			</div>

			<p className="text-sm text-text-primary-muted">It may take a few minutes for the DNS record to propagate.</p>
		</div>
	</div>
);

export const UsernameChangeModal = () => {
	const user = UserStore.getCurrentUser()!;
	const [setupMode, setSetupMode] = useState<SetupMode>('initial');
	const [domainStep, setDomainStep] = useState<DomainSetupStep>('input');
	const [challenge, setChallenge] = useState<string>('');
	const [isVerifying, setIsVerifying] = useState(false);
	const [direction, setDirection] = useState(0);

	const form = useForm<FormInputs>({
		defaultValues: {
			username: user.username,
			password: '',
			domain: '',
		},
		resolver: async (values) => {
			const errors: Record<string, {type: string; message: string}> = {};

			if (setupMode === 'username') {
				const usernameValidation = validateUsername(values.username);
				const passwordValidation = validatePassword(values.password);

				if (usernameValidation !== true) errors.username = {type: 'validation', message: usernameValidation};
				if (passwordValidation !== true) errors.password = {type: 'validation', message: passwordValidation};
			} else if (setupMode === 'domain') {
				const domainValidation = validateDomain(values.domain || '');
				if (domainValidation !== true) errors.domain = {type: 'validation', message: domainValidation};

				if (domainStep === 'verification') {
					const passwordValidation = validatePassword(values.password);
					if (passwordValidation !== true) errors.password = {type: 'validation', message: passwordValidation};
				}
			}

			return {
				values: Object.keys(errors).length === 0 ? values : {},
				errors,
			};
		},
	});

	const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const normalizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
		form.setValue('username', normalizedValue);
	};

	const handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const normalizedValue = value.toLowerCase().trim();
		form.setValue('domain', normalizedValue);
	};

	const handleModeChange = (mode: SetupMode, direction = 1) => {
		setDirection(direction);
		setSetupMode(mode);
		setDomainStep('input');
		setChallenge('');
		form.reset(
			mode === 'username'
				? {username: user.username, password: '', domain: ''}
				: {username: '', password: '', domain: ''},
		);
	};

	const requestDomainChallenge = async () => {
		const domain = form.getValues('domain');
		try {
			setIsVerifying(true);
			const response = await UserActionCreators.requestDomainChallenge(domain!);
			setChallenge(response.challenge);
			setDomainStep('verification');
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'domain');
		} finally {
			setIsVerifying(false);
		}
	};

	const verifyDomain = async () => {
		const domain = form.getValues('domain');
		const password = form.getValues('password');

		try {
			setIsVerifying(true);
			await UserActionCreators.verifyDomain(domain!, password);
			ModalActionCreators.pop();
			ToastActionCreators.createToast({type: 'success', children: i18n.Messages.USERNAME_CHANGED});
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'password');
		} finally {
			setIsVerifying(false);
		}
	};

	const handleSubmit = async (data: FormInputs) => {
		try {
			await UserActionCreators.update({
				username: data.username,
				password: data.password,
			});
			ModalActionCreators.pop();
			ToastActionCreators.createToast({type: 'success', children: i18n.Messages.USERNAME_CHANGED});
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'username');
		}
	};

	const renderInitialContent = () => (
		<motion.div
			key="initial"
			custom={direction}
			variants={slideVariants}
			initial="center"
			animate="center"
			exit="exit"
			transition={{
				x: {type: 'spring', stiffness: 300, damping: 30},
				opacity: {duration: 0.2},
			}}
			className="w-full overflow-auto"
		>
			<div className="space-y-4 p-6">
				<button
					type="button"
					onClick={() => handleModeChange('username')}
					className="group flex w-full items-center gap-4 rounded-lg border border-background-header-secondary p-4 transition-colors hover:bg-background-modifier-hover"
					aria-label="Change Username"
				>
					<div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-brand-primary/10">
						<Hash className="h-6 w-6 text-text-tertiary" />
					</div>
					<div className="flex-1 text-left">
						<h3 className="font-medium text-lg text-text-primary">FluxerTag</h3>
						<p className="text-sm text-text-primary-muted">Choose a Username#1234 tag</p>
					</div>
					<ArrowRight className="h-5 w-5 text-text-primary-muted transition-colors group-hover:text-text-primary" />
				</button>

				<button
					type="button"
					onClick={() => handleModeChange('domain')}
					className="group flex w-full items-center gap-4 rounded-lg border border-background-header-secondary p-4 transition-colors hover:bg-background-modifier-hover"
					aria-label="Use Custom Domain"
				>
					<div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-brand-primary/10">
						<Globe className="h-6 w-6 text-text-tertiary" />
					</div>
					<div className="flex-1 text-left">
						<h3 className="font-medium text-lg text-text-primary">Custom Domain</h3>
						<p className="text-sm text-text-primary-muted">Use your own domain name</p>
					</div>
					<ArrowRight className="h-5 w-5 text-text-primary-muted transition-colors group-hover:text-text-primary" />
				</button>
			</div>
		</motion.div>
	);

	const renderUsernameContent = () => (
		<motion.div
			key="username"
			custom={direction}
			variants={slideVariants}
			initial="enter"
			animate="center"
			exit="exit"
			transition={{
				x: {type: 'spring', stiffness: 300, damping: 30},
				opacity: {duration: 0.2},
			}}
			className="w-full overflow-auto"
		>
			<div className="space-y-6 p-6">
				<Input
					{...form.register('username')}
					autoComplete="username"
					autoFocus
					error={form.formState.errors.username?.message}
					label={i18n.Messages.USERNAME}
					footer={<p className="mt-1 text-sm text-text-primary-muted">{i18n.Messages.USERNAME_HELPER_TEXT}</p>}
					maxLength={VALIDATION.USERNAME.MAX}
					minLength={VALIDATION.USERNAME.MIN}
					name="username"
					placeholder={i18n.Messages.USERNAME_PLACEHOLDER}
					required
					type="text"
					onChange={handleUsernameChange}
				/>

				<Input
					{...form.register('password')}
					autoComplete="current-password"
					error={form.formState.errors.password?.message}
					label={i18n.Messages.PASSWORD}
					maxLength={VALIDATION.PASSWORD.MAX}
					minLength={VALIDATION.PASSWORD.MIN}
					placeholder="••••••••••••••••••••••••••••••"
					required
					type="password"
				/>
			</div>
		</motion.div>
	);

	const renderDomainContent = () => (
		<motion.div
			key="domain"
			custom={direction}
			variants={slideVariants}
			initial="enter"
			animate="center"
			exit="exit"
			transition={{
				x: {type: 'spring', stiffness: 300, damping: 30},
				opacity: {duration: 0.2},
			}}
			className="w-full overflow-auto"
		>
			<div className="space-y-6 p-6">
				<Input
					{...form.register('domain')}
					label="Domain Name"
					placeholder="yourdomain.com"
					required
					disabled={domainStep === 'verification'}
					error={form.formState.errors.domain?.message}
					maxLength={VALIDATION.DOMAIN.MAX}
					minLength={VALIDATION.DOMAIN.MIN}
					onChange={handleDomainChange}
				/>

				{domainStep === 'verification' && (
					<>
						<DomainVerificationSection
							domain={form.watch('domain')!}
							challenge={challenge}
							onCopy={TextCopyActionCreators.copy}
						/>
						<Input
							{...form.register('password')}
							autoComplete="current-password"
							error={form.formState.errors.password?.message}
							label={i18n.Messages.PASSWORD}
							maxLength={VALIDATION.PASSWORD.MAX}
							minLength={VALIDATION.PASSWORD.MIN}
							placeholder="••••••••••••••••••••••••••••••"
							required
							type="password"
						/>
					</>
				)}
			</div>
		</motion.div>
	);

	const renderContent = () => {
		switch (setupMode) {
			case 'initial':
				return renderInitialContent();
			case 'username':
				return renderUsernameContent();
			case 'domain':
				return renderDomainContent();
		}
	};

	return (
		<Modal.Root label={i18n.Messages.CHANGE_USERNAME_MODAL_TITLE} size="small">
			<Form form={form} onSubmit={setupMode === 'username' ? handleSubmit : noop}>
				<div className="flex h-full w-full flex-col bg-background-secondary">
					<div className="relative flex items-center gap-4 border-background-header-secondary border-b px-4 py-3">
						{setupMode !== 'initial' && (
							<Button
								variant="ghost"
								small
								fitContent
								className="absolute flex items-center"
								onClick={() => handleModeChange('initial', -1)}
								aria-label="Go back"
							>
								<ArrowLeftIcon className="h-4 w-4" />
								<span className="ml-1">Back</span>
							</Button>
						)}
						<h2 className="mx-auto font-semibold text-lg text-text-primary">
							{setupMode === 'initial' ? 'Choose Identity Type' : i18n.Messages.CHANGE_USERNAME_MODAL_TITLE}
						</h2>
					</div>

					<div className="min-h-0 flex-1">
						<div className="relative flex-1 overflow-hidden">
							<AnimatePresence initial={false} custom={direction} mode="wait">
								{renderContent()}
							</AnimatePresence>
						</div>
					</div>

					<div className="flex justify-end gap-3 border-background-header-secondary border-t p-4">
						<Button onClick={ModalActionCreators.pop} variant="ghost" aria-label="Cancel">
							{i18n.Messages.CANCEL}
						</Button>

						{setupMode !== 'initial' &&
							(setupMode === 'username' ? (
								<Button
									type="submit"
									submitting={form.formState.isSubmitting}
									variant="brand"
									aria-label="Submit Username Change"
								>
									{i18n.Messages.DONE}
								</Button>
							) : (
								<Button
									onClick={domainStep === 'input' ? requestDomainChallenge : verifyDomain}
									submitting={isVerifying}
									variant="brand"
									disabled={!form.watch('domain') || (domainStep === 'verification' && !form.watch('password'))}
									aria-label={domainStep === 'input' ? 'Continue to Verification' : 'Verify Domain'}
								>
									{domainStep === 'input' ? 'Continue' : 'Verify'}
								</Button>
							))}
					</div>
				</div>
			</Form>
		</Modal.Root>
	);
};
