export type AuthSession = Readonly<{
	id: string;
	approx_last_used_at: number;
	client_os: string;
	client_platform: string;
	client_location: string;
}>;

export class AuthSessionRecord {
	readonly id: string;
	readonly approxLastUsedAt: number;
	readonly clientOs: string;
	readonly clientPlatform: string;
	readonly clientLocation: string;

	constructor(data: AuthSession) {
		this.id = data.id;
		this.approxLastUsedAt = data.approx_last_used_at;
		this.clientOs = data.client_os;
		this.clientPlatform = data.client_platform;
		this.clientLocation = data.client_location;
	}

	toJSON(): AuthSession {
		return {
			id: this.id,
			approx_last_used_at: this.approxLastUsedAt,
			client_os: this.clientOs,
			client_platform: this.clientPlatform,
			client_location: this.clientLocation,
		};
	}

	equals(other: AuthSessionRecord): boolean {
		return JSON.stringify(this) === JSON.stringify(other);
	}
}
