import {type APIErrorCode, APIErrorCodes, ME} from '~/Constants';
import {Endpoints} from '~/Endpoints';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import {TooManyReactionsModal} from '~/components/alerts/TooManyReactionsModal';
import Dispatcher from '~/flux/Dispatcher';
import * as HttpClient from '~/lib/HttpClient';
import type {UserPartial} from '~/records/UserRecord';
import AuthenticationStore from '~/stores/AuthenticationStore';
import ConnectionStore from '~/stores/ConnectionStore';
import type {ReactionEmoji} from '~/utils/ReactionUtils';

const MAX_RETRIES = 3;

const checkReactionResponse = (res: any, retry: () => void) => {
	const {status, body} = res;
	if (status === 429) {
		setTimeout(retry, res.body.retry_after);
		return false;
	}
	if (status === 400) {
		switch (body?.code as APIErrorCode) {
			case APIErrorCodes.MAX_USERS_PER_MESSAGE_REACTION:
			case APIErrorCodes.MAX_REACTIONS_PER_MESSAGE:
				ModalActionCreators.push(() => <TooManyReactionsModal />);
				break;
		}
	}
	return true;
};

const optimisticDispatch = (
	type:
		| 'MESSAGE_REACTION_ADD'
		| 'MESSAGE_REACTION_REMOVE'
		| 'MESSAGE_REACTION_REMOVE_ALL'
		| 'MESSAGE_REACTION_REMOVE_EMOJI',
	channelId: string,
	messageId: string,
	emoji: ReactionEmoji,
	userId?: string,
) => {
	Dispatcher.dispatch({
		type,
		channelId,
		messageId,
		userId: userId || AuthenticationStore.getId(),
		emoji,
		optimistic: true,
	});
};

const makeUrl = ({
	channelId,
	messageId,
	emoji,
	userId,
}: {
	channelId: string;
	messageId: string;
	emoji: ReactionEmoji;
	userId?: string;
}): string => {
	const emojiCode = encodeURIComponent(emoji.id ? `${emoji.name}:${emoji.id}` : emoji.name);
	return userId
		? Endpoints.CHANNEL_MESSAGE_REACTION_QUERY(channelId, messageId, emojiCode, userId)
		: Endpoints.CHANNEL_MESSAGE_REACTION(channelId, messageId, emojiCode);
};

const retryWithExponentialBackoff = async (func: () => Promise<any>, attempts = 0): Promise<any> => {
	const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
	try {
		return await func();
	} catch (error) {
		if (attempts < MAX_RETRIES) {
			const backoffTime = 2 ** attempts * 1000;
			await delay(backoffTime);
			return retryWithExponentialBackoff(func, attempts + 1);
		}
		throw error;
	}
};

const performReactionAction = (
	type: 'MESSAGE_REACTION_ADD' | 'MESSAGE_REACTION_REMOVE',
	apiFunc: () => Promise<any>,
	channelId: string,
	messageId: string,
	emoji: ReactionEmoji,
	userId?: string,
) => {
	optimisticDispatch(type, channelId, messageId, emoji, userId);

	retryWithExponentialBackoff(apiFunc).catch((res) => {
		if (checkReactionResponse(res, () => performReactionAction(type, apiFunc, channelId, messageId, emoji, userId))) {
			optimisticDispatch(
				type === 'MESSAGE_REACTION_ADD' ? 'MESSAGE_REACTION_REMOVE' : 'MESSAGE_REACTION_ADD',
				channelId,
				messageId,
				emoji,
				userId,
			);
		}
	});
};

export const getReactions = async (
	channelId: string,
	messageId: string,
	emoji: ReactionEmoji,
): Promise<Array<UserPartial>> => {
	const response = await HttpClient.get<Array<UserPartial>>({url: makeUrl({channelId, messageId, emoji})});
	Dispatcher.dispatch({
		type: 'MESSAGE_REACTION_ADD_USERS',
		channelId,
		messageId,
		users: response.body,
		emoji,
	});
	return response.body;
};

export const addReaction = (channelId: string, messageId: string, emoji: ReactionEmoji) => {
	const apiFunc = () =>
		HttpClient.put({
			url: makeUrl({channelId, messageId, emoji, userId: ME}),
			query: {session_id: ConnectionStore.getSessionId()},
		});

	performReactionAction('MESSAGE_REACTION_ADD', apiFunc, channelId, messageId, emoji);
};

export const removeReaction = (channelId: string, messageId: string, emoji: ReactionEmoji, userId?: string) => {
	const apiFunc = () =>
		HttpClient.del({
			url: makeUrl({channelId, messageId, emoji, userId: userId || ME}),
			query: {session_id: ConnectionStore.getSessionId()},
		});

	performReactionAction('MESSAGE_REACTION_REMOVE', apiFunc, channelId, messageId, emoji, userId);
};

export const removeAllReactions = (channelId: string, messageId: string) => {
	const apiFunc = () => HttpClient.del(Endpoints.CHANNEL_MESSAGE_REACTIONS(channelId, messageId));

	retryWithExponentialBackoff(apiFunc).catch((res) => {
		checkReactionResponse(res, () => removeAllReactions(channelId, messageId));
	});
};
