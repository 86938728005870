import type {GuildMember, GuildMemberRecord} from '~/records/GuildMemberRecord';
import type {GuildRecord} from '~/records/GuildRecord';
import type {UserPartial, UserProfile} from '~/records/UserRecord';
import GuildMemberStore from '~/stores/GuildMemberStore';
import GuildStore from '~/stores/GuildStore';
import UserStore from '~/stores/UserStore';

export type Profile = Readonly<{
	user: UserPartial;
	user_profile: UserProfile;
	timezone_offset: number | null;
	guild_member?: GuildMember;
}>;

export class ProfileRecord {
	readonly userId: string;
	readonly guildId: string | null;
	readonly userProfile: Readonly<UserProfile>;
	readonly timezoneOffset: number | null;

	constructor(profile: Profile, guildId?: string) {
		this.userId = profile.user.id;
		this.guildId = guildId ?? null;
		this.userProfile = Object.freeze({...profile.user_profile});
		this.timezoneOffset = profile.timezone_offset;
	}

	withUpdates(updates: Partial<Profile>): ProfileRecord {
		return new ProfileRecord(
			{
				user: {...this.toJSON().user, ...(updates.user ?? {})},
				user_profile: updates.user_profile ?? this.userProfile,
				timezone_offset: updates.timezone_offset ?? this.timezoneOffset,
				guild_member: updates.guild_member,
			},
			this.guildId ?? undefined,
		);
	}

	withGuildId(guildId: string | null): ProfileRecord {
		return new ProfileRecord(this.toJSON(), guildId ?? undefined);
	}

	get guild(): GuildRecord | null {
		if (!this.guildId) return null;
		return GuildStore.getGuild(this.guildId) ?? null;
	}

	get guildMember(): GuildMemberRecord | null {
		if (!this.guildId) return null;
		return GuildMemberStore.getMember(this.guildId, this.userId) ?? null;
	}

	equals(other: ProfileRecord): boolean {
		return (
			this.userId === other.userId &&
			this.guildId === other.guildId &&
			JSON.stringify(this.userProfile) === JSON.stringify(other.userProfile) &&
			this.timezoneOffset === other.timezoneOffset
		);
	}

	toJSON(): Profile {
		return {
			user: UserStore.getUser(this.userId)!,
			user_profile: {...this.userProfile},
			timezone_offset: this.timezoneOffset,
		};
	}
}
