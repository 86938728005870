import type {ToastProps} from '~/components/uikit/Toast';
import Dispatcher from '~/flux/Dispatcher';

export const createToast = (data: ToastProps): string => {
	const id = crypto.randomUUID();
	Dispatcher.dispatch({type: 'TOAST_SET', id, data});
	return id;
};

export const updateToast = (id: string, data: ToastProps): void => {
	Dispatcher.dispatch({type: 'TOAST_SET', id, data});
};

export const destroyToast = (id: string): void => {
	Dispatcher.dispatch({type: 'TOAST_DELETE', id});
};
