const PLAINTEXT_SURROGATES = new Set(['™', '™️', '\xa9', '\xa9️', '\xae', '\xae️']);
const VS16_REGEX = /\uFE0F/g;
const ZWJ = String.fromCharCode(0x200d);

export const removeVS16s = (rawEmoji: string) =>
	rawEmoji.indexOf(ZWJ) < 0 ? rawEmoji.replace(VS16_REGEX, '') : rawEmoji;

export const toCodePoints = (unicodeSurrogates: string) => {
	const points: Array<string> = [];
	let char = 0;
	let previous = 0;
	let i = 0;
	while (i < unicodeSurrogates.length) {
		char = unicodeSurrogates.charCodeAt(i++);
		if (previous) {
			points.push((0x10000 + ((previous - 0xd800) << 10) + (char - 0xdc00)).toString(16));
			previous = 0;
		} else if (char > 0xd800 && char <= 0xdbff) {
			previous = char;
		} else {
			points.push(char.toString(16));
		}
	}
	return points;
};

export const fromCodePoint = (hexString: string): string => {
	const codePoint = Number.parseInt(hexString, 16);
	return String.fromCodePoint(codePoint);
};

export const getTwemojiURL = (codepoints: string): string => {
	return `${window.GLOBAL_ENV.STATIC_ENDPOINT}emoji/v1/svg/${codepoints}.svg`;
};

export const getURL = (unicodeSurrogates: string, plaintext = false): string => {
	if (plaintext && PLAINTEXT_SURROGATES.has(unicodeSurrogates)) {
		return '';
	}
	const codepoints = toCodePoints(removeVS16s(unicodeSurrogates)).join('-');
	return getTwemojiURL(codepoints);
};
