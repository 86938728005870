import {Maximize2, Minimize2, MonitorPlay, Pause, Play, Volume2, VolumeX} from 'lucide-react';
import {
	MediaControlBar,
	MediaController,
	MediaFullscreenButton,
	MediaMuteButton,
	MediaPipButton,
	MediaPlayButton,
	MediaPlaybackRateButton,
	MediaPosterImage,
	MediaTimeDisplay,
	MediaTimeRange,
	MediaVolumeRange,
} from 'media-chrome/react';
import {AnimatePresence, motion} from 'motion/react';
import {type FC, useCallback, useEffect, useRef, useState} from 'react';
import {thumbHashToDataURL} from 'thumbhash';
import {createCalculator} from '~/utils/DimensionUtils';
import * as ImageCacheUtils from '~/utils/ImageCacheUtils';

const VIDEO_CONFIG = {
	MAX_WIDTH: 400,
	PLAYBACK_RATES: [0.5, 1, 1.5, 2],
	AUTOHIDE_DELAY: 2,
} as const;

const videoCalculator = createCalculator({
	maxWidth: VIDEO_CONFIG.MAX_WIDTH,
	responsive: true,
});

type EmbedVideoProps = {
	src: string;
	width: number;
	height: number;
	placeholder?: string;
};

const mediaControllerStyles = {
	'--media-controls-transition':
		'transform 200ms cubic-bezier(0.4, 0, 0.2, 1), opacity 200ms cubic-bezier(0.4, 0, 0.2, 1)',
	'--media-control-background': 'var(--background-primary)',
	'--media-control-hover-background': 'var(--background-secondary)',
	'--media-primary-color': 'var(--text-primary)',
	'--media-secondary-color': 'var(--background-primary)',
	'--media-tooltip-z-index': 'var(--z-index-tooltip)',
} as const;

const timeRangeStyles = {
	'--media-range-track-background': 'var(--background-secondary)',
	'--media-range-bar-color': 'var(--brand-primary-light)',
	'--media-range-track-height': '4px',
	'--media-range-track-border-radius': '0px',
	'--media-range-track-outline': 'none',
	'--media-range-track-outline-offset': '0px',
	'--media-range-thumb-width': '0px',
	'--media-range-thumb-height': '0px',
	'--media-preview-time-background': 'var(--background-primary)',
	'--media-preview-time-text-shadow': 'none',
	'--media-preview-time-padding': '4px 8px',
	'--media-preview-time-border-radius': '4px',
	'--media-preview-time-font-size': '11px',
	'--media-preview-time-margin-bottom': '8px',
	'--media-range-padding': '0',
	'--media-range-padding-left': '0',
	'--media-range-padding-right': '0',
	'--media-time-range-hover-display': 'block',
	'--media-time-range-hover-height': '20px',
	'--media-time-range-hover-bottom': '0',
} as const;

const volumeRangeStyles = {
	'--media-range-track-background': 'var(--background-secondary)',
	'--media-range-bar-color': 'var(--text-primary)',
	'--media-range-track-height': '4px',
	'--media-range-thumb-height': '0px',
	'--media-range-thumb-width': '0px',
} as const;

const timeDisplayStyles = {
	'--media-time-display-padding': '0 8px',
} as const;

const controlBarStyles = {
	'--media-control-padding': '0px',
	'--media-control-height': '20px',
	'--media-font-size': '12px',
} as const;

const PlayButton: FC = () => (
	<button type="button" className="group/play">
		<div className="flex h-14 w-14 items-center justify-center rounded-full bg-black/75 transition-all duration-200 group-hover/play:scale-105 group-active/play:scale-95">
			<Play size={28} className="ml-1 text-white" />
		</div>
	</button>
);

const VideoOverlay: FC<{
	thumbHashURL?: string;
	posterLoaded: boolean;
	onInitialPlay: () => void;
	width: number;
	height: number;
}> = ({thumbHashURL, posterLoaded, onInitialPlay, width, height}) => {
	const aspectRatio = `${width} / ${height}`;

	return (
		<div
			className="absolute inset-0 flex cursor-pointer items-center justify-center"
			onClick={onInitialPlay}
			onKeyDown={(e) => e.key === 'Enter' && onInitialPlay()}
			role="button"
			tabIndex={0}
			style={{
				width: '100%',
				aspectRatio,
			}}
		>
			<AnimatePresence>
				{thumbHashURL && !posterLoaded && (
					<motion.img
						key="placeholder"
						initial={{opacity: 1}}
						exit={{opacity: 0}}
						transition={{duration: 0.3}}
						src={thumbHashURL}
						alt="Video thumbnail"
						className="absolute inset-0 h-full w-full object-cover"
						style={{
							width: '100%',
							aspectRatio,
						}}
					/>
				)}
			</AnimatePresence>

			<PlayButton />
		</div>
	);
};

const VideoControls: FC = () => (
	<div className="absolute right-0 bottom-0 left-0 translate-y-0 transition-all duration-200 ease-in-out group-hover:translate-y-0 group-[&:not(:hover):not([media-paused])]:translate-y-full">
		<div className="relative h-1 w-full" style={{zIndex: 'var(--z-index-elevated-1)'}}>
			<MediaTimeRange className="absolute inset-0 h-full w-full" style={timeRangeStyles} />
		</div>

		<div className="relative bg-background-primary" style={{zIndex: 'var(--z-index-elevated-2)'}}>
			<MediaControlBar className="flex h-12 w-full flex-row items-center px-3" style={controlBarStyles}>
				<div className="flex items-center space-x-2">
					<MediaPlayButton className="flex h-8 w-8 items-center justify-center text-text-primary transition-all hover:scale-105 hover:bg-background-secondary active:scale-95">
						<span slot="play" aria-hidden="true">
							<Play size={18} />
						</span>
						<span slot="pause" aria-hidden="true">
							<Pause size={18} />
						</span>
					</MediaPlayButton>

					<MediaMuteButton className="flex h-8 w-8 items-center justify-center text-text-primary transition-all hover:scale-105 hover:bg-background-secondary active:scale-95">
						<span slot="unmute" aria-hidden="true">
							<VolumeX size={18} />
						</span>
						<span slot="mute" aria-hidden="true">
							<Volume2 size={18} />
						</span>
					</MediaMuteButton>

					<MediaVolumeRange className="h-1 w-14" style={volumeRangeStyles} />

					<MediaTimeDisplay className="font-mono text-text-primary text-xs" showduration style={timeDisplayStyles} />
				</div>

				<div className="ml-auto flex items-center space-x-1">
					<MediaPlaybackRateButton
						className="flex h-8 w-8 items-center justify-center text-text-primary text-xs transition-all hover:scale-105 hover:bg-background-secondary active:scale-95"
						rates={VIDEO_CONFIG.PLAYBACK_RATES}
					/>

					<MediaPipButton className="flex h-8 w-8 items-center justify-center text-text-primary transition-all hover:scale-105 hover:bg-background-secondary active:scale-95">
						<MonitorPlay size={18} />
					</MediaPipButton>

					<MediaFullscreenButton className="flex h-8 w-8 items-center justify-center text-text-primary transition-all hover:scale-105 hover:bg-background-secondary active:scale-95">
						<span slot="enter" aria-hidden="true">
							<Maximize2 size={18} />
						</span>
						<span slot="exit" aria-hidden="true">
							<Minimize2 size={18} />
						</span>
					</MediaFullscreenButton>
				</div>
			</MediaControlBar>
		</div>
	</div>
);

const EmbedVideo: FC<EmbedVideoProps> = ({src, width, height, placeholder}) => {
	const [hasPlayedOnce, setHasPlayedOnce] = useState(false);
	const videoRef = useRef<HTMLVideoElement>(null);
	const posterSrc = `${src}?format=webp`;
	const [posterLoaded, setPosterLoaded] = useState(ImageCacheUtils.hasImage(posterSrc));

	const thumbHashUrl = placeholder
		? thumbHashToDataURL(Uint8Array.from(atob(placeholder), (c) => c.charCodeAt(0)))
		: undefined;

	const {style: containerStyle, dimensions} = useCallback(() => {
		return videoCalculator.calculate({width, height}, {responsive: true});
	}, [width, height])();

	const aspectRatio = `${dimensions.width} / ${dimensions.height}`;

	useEffect(() => {
		ImageCacheUtils.loadImage(
			posterSrc,
			() => setPosterLoaded(true),
			() => setPosterLoaded(false),
		);
	}, [posterSrc]);

	const handleInitialPlay = useCallback(() => {
		if (videoRef.current) {
			videoRef.current.play().catch(console.error);
			setHasPlayedOnce(true);
		}
	}, []);

	return (
		<div
			className="h-full w-full select-none"
			style={{
				...containerStyle,
				minHeight: `${dimensions.height}px`,
				aspectRatio,
			}}
		>
			<MediaController
				className="group relative h-full w-full overflow-hidden rounded-md bg-black"
				autohide={VIDEO_CONFIG.AUTOHIDE_DELAY}
				defaultsubtitles
				style={{
					...mediaControllerStyles,
					aspectRatio,
					width: '100%',
					minHeight: `${dimensions.height}px`,
				}}
			>
				{/* biome-ignore lint/a11y/useMediaCaption: <explanation> */}
				<video
					slot="media"
					src={src}
					className="h-full w-full object-contain"
					playsInline
					preload="metadata"
					ref={videoRef}
					style={{
						aspectRatio,
						width: '100%',
						minHeight: `${dimensions.height}px`,
					}}
				/>

				<MediaPosterImage
					slot="poster"
					src={posterSrc}
					className="h-full w-full object-cover"
					style={{
						aspectRatio,
						width: '100%',
						minHeight: `${dimensions.height}px`,
					}}
				/>

				{!hasPlayedOnce && (
					<VideoOverlay
						thumbHashURL={thumbHashUrl}
						posterLoaded={posterLoaded}
						onInitialPlay={handleInitialPlay}
						width={dimensions.width}
						height={dimensions.height}
					/>
				)}

				<VideoControls />
			</MediaController>
		</div>
	);
};

export default EmbedVideo;
