import React from 'react';
import * as UserProfileActionCreators from '~/actions/UserProfileActionCreators';
import {UserProfilePopout} from '~/components/popouts/UserProfilePopout';
import type {PopoutPosition} from '~/components/uikit/Popout';
import {Popout} from '~/components/uikit/Popout/Popout';
import type {UserRecord} from '~/records/UserRecord';

const HOVER_DELAY_MS = 250;

export const PreloadableUserPopout = ({
	user,
	isWebhook,
	guildId,
	children,
	position = 'right-start',
}: {
	user: UserRecord;
	isWebhook: boolean;
	guildId: string;
	children: React.ReactNode;
	position?: PopoutPosition;
}) => {
	const hoverTimeoutRef = React.useRef<number | null>(null);
	const preloadingRef = React.useRef(false);

	const preloadUserProfile = React.useCallback(async () => {
		if (preloadingRef.current) {
			return;
		}
		preloadingRef.current = true;

		try {
			await UserProfileActionCreators.fetch(user.id, guildId);
		} catch {}
	}, [user.id, guildId]);

	const handleMouseEnter = React.useCallback(() => {
		if (hoverTimeoutRef.current) {
			window.clearTimeout(hoverTimeoutRef.current);
		}

		hoverTimeoutRef.current = window.setTimeout(() => {
			preloadUserProfile();
		}, HOVER_DELAY_MS);
	}, [preloadUserProfile]);

	const handleMouseLeave = React.useCallback(() => {
		if (hoverTimeoutRef.current) {
			window.clearTimeout(hoverTimeoutRef.current);
			hoverTimeoutRef.current = null;
		}
	}, []);

	React.useEffect(() => {
		return () => {
			if (hoverTimeoutRef.current) {
				window.clearTimeout(hoverTimeoutRef.current);
			}
		};
	}, []);

	return (
		<Popout
			render={({popoutKey}) => (
				<UserProfilePopout popoutKey={popoutKey} user={user} isWebhook={isWebhook} guildId={guildId} />
			)}
			position={position}
		>
			{React.cloneElement(React.Children.only(children) as React.ReactElement, {
				onMouseEnter: handleMouseEnter,
				onMouseLeave: handleMouseLeave,
			})}
		</Popout>
	);
};
